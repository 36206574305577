var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, SmilesService } from '@shared';
var SmilesGiftsComponent = /** @class */ (function (_super) {
    __extends(SmilesGiftsComponent, _super);
    function SmilesGiftsComponent(baseServices, route, smilesService) {
        var _this = _super.call(this, baseServices) || this;
        _this.route = route;
        _this.smilesService = smilesService;
        return _this;
    }
    SmilesGiftsComponent.prototype.ngOnInit = function () {
        this.orderId = this.route.snapshot.params.id;
        this.getSmilesGifts();
    };
    SmilesGiftsComponent.prototype.getSmilesGifts = function () {
        var _this = this;
        this.loadingState.setLoading();
        this.smilesService.getSmilesOrderByOrderId(this.orderId)
            .pipe(finalize(function () {
            _this.loadingState.setLoaded();
        }))
            .subscribe(function (resp) {
            _this.smilesGifts = resp.gifts;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    return SmilesGiftsComponent;
}(AppBaseComponent));
export { SmilesGiftsComponent };
