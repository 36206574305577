import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ModalService } from '../modal.service';
import { IDialog } from './dialog';
import { DialogButton } from './dialog-button';
import { DialogType } from './dialog-type';
import { DialogComponent } from './dialog.component';

@Injectable()
export class DialogService {
  private readonly confirmString = 'OK';
  private readonly cancelString = 'Cancel';
  constructor(private readonly modalService: ModalService) {
  }

  public show(dialog: IDialog): Observable<any> {
    return this.modalService.create(DialogComponent, {
      type: dialog.type ? dialog.type : DialogType.Information,
      title: dialog.title,
      message: dialog.message,
      okCaption: dialog.ok === undefined ? this.confirmString : dialog.ok.caption,
      showOk: dialog.ok !== undefined,
      cancelCaption: dialog.cancel === undefined ? '' : dialog.cancel.caption,
      showCancel: dialog.cancel !== undefined,
      ok: dialog.ok === undefined ? undefined : dialog.ok.function,
      cancel: dialog.cancel === undefined ? undefined : dialog.cancel.function
    });
  }

  public showConfirmation(title: string, message: string, confirmCaption: string, confirmAction: Function): Observable<any> {
    const ok: DialogButton = { caption: confirmCaption, function: confirmAction };
    const cancel: DialogButton = { caption: this.cancelString };

    const dialog: IDialog = {
      type: DialogType.Confirmation,
      title,
      message,
      ok,
      cancel
    };

    return this.show(dialog);
  }

  public showError(title: string, message: string): Observable<any> {
    const ok: DialogButton = { caption: this.confirmString };

    const dialog: IDialog = {
      type: DialogType.Error,
      title,
      message,
      ok
    };

    return this.show(dialog);
  }

  public showInformation(title: string, message: string): Observable<any> {
    const ok: DialogButton = { caption: this.confirmString };

    const dialog: IDialog = {
      title,
      message,
      ok
    };

    return this.show(dialog);
  }

  public showWarning(title: string, message: string): Observable<any> {
    const ok: DialogButton = { caption: this.confirmString };

    const dialog: IDialog = {
      type: DialogType.Warning,
      title,
      message,
      ok
    };

    return this.show(dialog);
  }
}
