/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i5 from "./email-preview-sidenav.component";
import * as i6 from "../shared/app-base-component.service";
import * as i7 from "./services/email-preview-service";
var styles_EmailPreviewSideNavComponent = [];
var RenderType_EmailPreviewSideNavComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailPreviewSideNavComponent, data: {} });
export { RenderType_EmailPreviewSideNavComponent as RenderType_EmailPreviewSideNavComponent };
function View_EmailPreviewSideNavComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "a", [["class", "btn btn-link btn-icn"]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleExpanded(_v.parent.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "btn btn-link btn-icn"; var currVal_2 = _ck(_v, 2, 0, ((_co.activeItemId === _v.parent.parent.context.index) ? "active" : "collapsed")); _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.expanded === (_co.expandedString + _v.parent.parent.context.index)); _ck(_v, 0, 0, currVal_0); var currVal_3 = _v.parent.parent.context.$implicit.name; _ck(_v, 3, 0, currVal_3); }); }
function View_EmailPreviewSideNavComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "a", [["class", "btn btn-link"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpad(2, 1), i0.ɵdid(3, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(4, 2), (_l()(), i0.ɵted(5, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "btn btn-link"; var currVal_3 = _ck(_v, 2, 0, ((_co.activeItemId === _v.parent.parent.context.index) ? "active" : "collapsed")); _ck(_v, 1, 0, currVal_2, currVal_3); var currVal_4 = _ck(_v, 4, 0, _co.previewEmailPath, _v.parent.parent.context.$implicit.path); _ck(_v, 3, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3).target; var currVal_1 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_5 = _v.parent.parent.context.$implicit.name; _ck(_v, 5, 0, currVal_5); }); }
function View_EmailPreviewSideNavComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h5", [["class", "mb-0"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_4)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_5)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = (_v.parent.context.$implicit.child && _v.parent.context.$implicit.child.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = (!_v.parent.context.$implicit.child || !_v.parent.context.$implicit.child.length); _ck(_v, 4, 0, currVal_1); }, null); }
function View_EmailPreviewSideNavComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [["class", "nav-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "a", [["class", "nav-link"], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 671744, [[5, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(3, 3), i0.ɵdid(4, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, i0.ChangeDetectorRef], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 4, { links: 1 }), i0.ɵqud(603979776, 5, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 3, 0, _co.previewEmailPath, _v.parent.parent.parent.context.$implicit.path, _v.context.$implicit.path); _ck(_v, 2, 0, currVal_2); var currVal_3 = "active"; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).target; var currVal_1 = i0.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = _v.context.$implicit.name; _ck(_v, 7, 0, currVal_4); }); }
function View_EmailPreviewSideNavComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "ul", [["class", "nav flex-column"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_8)), i0.ɵdid(3, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.child; _ck(_v, 3, 0, currVal_0); }, null); }
function View_EmailPreviewSideNavComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 1, null, View_EmailPreviewSideNavComponent_7)), i0.ɵdid(1, 16384, [[3, 4]], 0, i3.NgbPanelContent, [i0.TemplateRef], null, null)], null, null); }
function View_EmailPreviewSideNavComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "ngb-panel", [["class", "card"]], null, null, null, null, null)), i0.ɵdid(1, 2113536, [[1, 4]], 2, i3.NgbPanel, [], { id: [0, "id"] }, null), i0.ɵqud(603979776, 2, { titleTpls: 1 }), i0.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_EmailPreviewSideNavComponent_3)), i0.ɵdid(5, 16384, [[2, 4]], 0, i3.NgbPanelTitle, [i0.TemplateRef], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getPanelId(_v.context.$implicit, _v.context.index); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.child; _ck(_v, 7, 0, currVal_1); }, null); }
function View_EmailPreviewSideNavComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "ngb-accordion", [["class", "accordion accordion-nav accordion"], ["role", "tablist"]], [[1, "aria-multiselectable", 0]], null, null, i4.View_NgbAccordion_0, i4.RenderType_NgbAccordion)), i0.ɵdid(1, 2146304, null, 1, i3.NgbAccordion, [i3.NgbAccordionConfig], { activeIds: [0, "activeIds"], closeOtherPanels: [1, "closeOtherPanels"] }, null), i0.ɵqud(603979776, 1, { panels: 1 }), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_2)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵinlineInterpolate(1, "", ("ngbPanel" + _co.activeItemId), ""); var currVal_2 = true; _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = _co.routes; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = !i0.ɵnov(_v, 1).closeOtherPanels; _ck(_v, 0, 0, currVal_0); }); }
export function View_EmailPreviewSideNavComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "sidenav"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "label", [["class", "nav-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Email Templates"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewSideNavComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.routes; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_EmailPreviewSideNavComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-email-preview-sidenav", [], null, null, null, View_EmailPreviewSideNavComponent_0, RenderType_EmailPreviewSideNavComponent)), i0.ɵdid(1, 245760, null, 0, i5.EmailPreviewSideNavComponent, [i6.AppBaseComponentService, i2.ActivatedRoute, i7.EmailPreviewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailPreviewSideNavComponentNgFactory = i0.ɵccf("wv-email-preview-sidenav", i5.EmailPreviewSideNavComponent, View_EmailPreviewSideNavComponent_Host_0, {}, {}, []);
export { EmailPreviewSideNavComponentNgFactory as EmailPreviewSideNavComponentNgFactory };
