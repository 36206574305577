import { Injectable } from '@angular/core';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppConfig } from '@core';
import { ISupporterStub } from '@shared';

@Injectable()
export class LastViewedSupportersService {
  private readonly lastViewedSupportersSubject = new BehaviorSubject<Array<ISupporterStub>>(undefined);
  private readonly lastViewedSupportersCookieName: string;
  private readonly maxAllowedRecentSupporters = 10;
  private readonly envCookiePrefix: string;

  constructor(
    private readonly cookieService: CookieService,
    private readonly config: AppConfig) {
    this.envCookiePrefix = this.config.getConfig('cookiePrefix') as string;
    this.lastViewedSupportersCookieName = `${this.envCookiePrefix}_lastViewedSupporterSearches`;
    this.getLastViewedSupporters();
  }

  public get data(): Observable<Array<ISupporterStub>> {
    return this.lastViewedSupportersSubject.asObservable();
  }

  public getLastViewedSupporters(): Array<ISupporterStub> {
    const lastViewedSupporters = this.cookieService.get(this.lastViewedSupportersCookieName);
    let lastViewedSupportersJson = [] as Array<ISupporterStub>;

    if (lastViewedSupporters) {
      lastViewedSupportersJson = JSON.parse(lastViewedSupporters);
    }

    this.lastViewedSupportersSubject.next(lastViewedSupportersJson);

    return lastViewedSupportersJson;
  }

  public addLastViewedSupporters(supporter: ISupporterStub): void {
    const domain = this.config.getConfig('domain');
    const options: CookieOptions = {
      domain,
      path: '/',
      secure: false
    };

    const lastViewedSupportersJson = this.getLastViewedSupporters();
    if (lastViewedSupportersJson) {
      const existingSupporter = lastViewedSupportersJson.filter(s => s.id === supporter.id);
      // skip adding supporter item if already exists
      if ((existingSupporter && existingSupporter.length)) {
        return;
      }
      // remove last supporter item from the list
      if (lastViewedSupportersJson.length >= this.maxAllowedRecentSupporters) {
        lastViewedSupportersJson.pop();
      }

      // add supporter to the top of the list
      lastViewedSupportersJson.unshift(supporter);
      this.cookieService.put(this.lastViewedSupportersCookieName, JSON.stringify(lastViewedSupportersJson), options);

      this.lastViewedSupportersSubject.next(lastViewedSupportersJson);
    }
  }
}
