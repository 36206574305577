<div *ngIf="team">
  <form class="container">
    <h1>Team Details</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="teamName">Name</label>
        <input type="text" class="form-control" id="teamName" name="teamName" [(ngModel)]="team.name" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label for="teamType">Type</label>
        <input type="text" class="form-control" id="teamType" name="teamType" [(ngModel)]="team.teamTypeCode"
          disabled />
      </div>
    </div>
    <div *ngIf="team.owner">
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="ownerFirstName">Owner First Name</label>
          <input type="text" class="form-control" id="ownerFirstName" name="ownerFirstName"
            [(ngModel)]="team.owner.firstName" disabled />
        </div>
        <div class="col-md-4 mb-3">
          <div>
            <label for="ownerLastName">Owner Last Name</label>
            <input type="text" class="form-control" id="ownerLastName" name="ownerLastName"
              [(ngModel)]="team.owner.lastName" disabled />
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="ownerSupporterId">Owner Supporter ID</label>
          <input type="text" class="form-control" id="ownerSupporterId" name="ownerSupporterId"
            [(ngModel)]="team.owner.id" disabled />
        </div>
        <div class="col-md-4 mb-3">
          <div>
            <label for="ownerEmailAddress">Owner Email Address</label>
            <input type="text" class="form-control" id="ownerEmailAddress" name="ownerEmailAddress"
              [(ngModel)]="team.owner.emailAddress" disabled />
          </div>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="pull-right action-group">
        <button class="btn btn-s pull-right" type="button" *ngIf="team.owner" (click)="removeOwner()">Remove
          Owner</button>
        <button class="btn btn-s pull-right" type="button" (click)="showAddOwnerModal()" *ngIf="!team.owner">
          {{ isSearchNewOwnerControlVisible ? 'Cancel Search' : 'Add Owner' }}
        </button>
        <a class="btn btn-s pull-right" [routerLink]="['/fundraising', 'teams', teamId, 'profiles', 'create']">Create
          Profile</a>
      </div>
    </div>
  </form>
</div>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
