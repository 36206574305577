var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { AppBaseComponent, AppBaseComponentService, PagingWrapper } from '@shared';
var SmilesOrderListComponent = /** @class */ (function (_super) {
    __extends(SmilesOrderListComponent, _super);
    function SmilesOrderListComponent(baseServices) {
        var _this = _super.call(this, baseServices) || this;
        _this.paging = new EventEmitter();
        _this.orders = [];
        _this.pages = [];
        _this.tablePageSizeItems = [];
        _this.activePage = 1;
        _this.tablePageSizeItems = _this.base.appConfig.getConfig('tablePageSizeItems');
        _this.activeTake = _this.tablePageSizeItems[0];
        return _this;
    }
    SmilesOrderListComponent.prototype.ngOnChanges = function (changes) {
        if (changes.pagingWrapper) {
            this.initData();
        }
    };
    SmilesOrderListComponent.prototype.getOrdersForPage = function (pageNumber) {
        this.activePage = pageNumber;
        this.setPaging();
    };
    SmilesOrderListComponent.prototype.selectTake = function (item) {
        this.activeTake = item;
        this.setPaging();
    };
    SmilesOrderListComponent.prototype.setPaging = function () {
        this.paging.next({
            skip: ((this.activePage - 1) * this.activeTake),
            take: this.activeTake
        });
    };
    SmilesOrderListComponent.prototype.initData = function () {
        this.pages = [];
        this.orders = this.pagingWrapper.items;
        var pageCount = Math.ceil(this.pagingWrapper.total / this.activeTake);
        if (pageCount > 1) {
            for (var i = 1; i <= pageCount; i++) {
                this.pages.push(i);
            }
        }
    };
    return SmilesOrderListComponent;
}(AppBaseComponent));
export { SmilesOrderListComponent };
