import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IFundraisingTeam } from '../../models/fundraising-team';
import { IFundraisingTeamTypeCode } from '../../models/fundraising-team-type-code';
import { BaseService } from '../base.service';

@Injectable()
export class TeamsService extends BaseService {

  public getTeamDetails(id: string): Observable<IFundraisingTeam> {
    return this.get<IFundraisingTeam>(`admin/fundraising/teams/${id}`);
  }

  public removeOwner(id: string): Observable<any> {
    const operation = {
      operation: 'removeOwner',
      data: {}
    };

    return this.patch<any>(`admin/fundraising/teams/${id}`, operation);
  }

  public changeOwner(teamId: string, supporterId: string): Observable<any> {
    const model = {
      supporterId
    };
    const operation = {
      operation: 'claimTeam',
      data: model
    };

    return this.patch<any>(`admin/fundraising/teams/${teamId}`, operation);
  }

  public searchTeams(searchTerm: string): Observable<Array<IFundraisingTeam>> {
    return this.get<Array<IFundraisingTeam>>(`admin/fundraising/teams?searchTerm=${searchTerm}&take=10`);
  }

  public createTeam(teamModel: IFundraisingTeam): Observable<any> {
    return this.post<any>('admin/fundraising/teams/', teamModel);
  }

  public getTeamTypeCodes(): Observable<Array<IFundraisingTeamTypeCode>> {
    return this.get<Array<IFundraisingTeamTypeCode>>('fundraising/team-types');
  }
}
