import { CookieService } from 'ngx-cookie';
import { BehaviorSubject } from 'rxjs';
import { AppConfig } from '@core';
var LastViewedSupportersService = /** @class */ (function () {
    function LastViewedSupportersService(cookieService, config) {
        this.cookieService = cookieService;
        this.config = config;
        this.lastViewedSupportersSubject = new BehaviorSubject(undefined);
        this.maxAllowedRecentSupporters = 10;
        this.envCookiePrefix = this.config.getConfig('cookiePrefix');
        this.lastViewedSupportersCookieName = this.envCookiePrefix + "_lastViewedSupporterSearches";
        this.getLastViewedSupporters();
    }
    Object.defineProperty(LastViewedSupportersService.prototype, "data", {
        get: function () {
            return this.lastViewedSupportersSubject.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    LastViewedSupportersService.prototype.getLastViewedSupporters = function () {
        var lastViewedSupporters = this.cookieService.get(this.lastViewedSupportersCookieName);
        var lastViewedSupportersJson = [];
        if (lastViewedSupporters) {
            lastViewedSupportersJson = JSON.parse(lastViewedSupporters);
        }
        this.lastViewedSupportersSubject.next(lastViewedSupportersJson);
        return lastViewedSupportersJson;
    };
    LastViewedSupportersService.prototype.addLastViewedSupporters = function (supporter) {
        var domain = this.config.getConfig('domain');
        var options = {
            domain: domain,
            path: '/',
            secure: false
        };
        var lastViewedSupportersJson = this.getLastViewedSupporters();
        if (lastViewedSupportersJson) {
            var existingSupporter = lastViewedSupportersJson.filter(function (s) { return s.id === supporter.id; });
            // skip adding supporter item if already exists
            if ((existingSupporter && existingSupporter.length)) {
                return;
            }
            // remove last supporter item from the list
            if (lastViewedSupportersJson.length >= this.maxAllowedRecentSupporters) {
                lastViewedSupportersJson.pop();
            }
            // add supporter to the top of the list
            lastViewedSupportersJson.unshift(supporter);
            this.cookieService.put(this.lastViewedSupportersCookieName, JSON.stringify(lastViewedSupportersJson), options);
            this.lastViewedSupportersSubject.next(lastViewedSupportersJson);
        }
    };
    return LastViewedSupportersService;
}());
export { LastViewedSupportersService };
