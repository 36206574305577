import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService, AuthStateService } from '@auth';
import { SignInActionService } from './sign-in-action.service';

@Injectable()
export class SignInRedirectResolve implements Resolve<boolean> {

  constructor(
    private readonly authService: AuthService,
    private readonly authStateService: AuthStateService,
    private readonly signInActionService: SignInActionService,
    private readonly router: Router) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<boolean> {

    const token = route.queryParams.token;
    const action = route.queryParams.action;

    if (!token) {
      if (this.authStateService.current.isAuthenticated && action) {
        return from(this.signInActionService.redirect(action))
          .pipe(map(() => true));
      }

      return of(false);
    }
  }
}
