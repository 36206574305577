import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppBaseComponent, AppBaseComponentService, BrowserStorageService } from '@shared';
import { SignInActionService } from './sign-in-action.service';
import { SignInState } from './sign-in-state';

@Component({
  selector: 'wv-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent extends AppBaseComponent implements OnInit {
  public model: any = {};
  public signingIn: boolean;
  private readonly failedAttempts = 0;
  private readonly signInState: SignInState = undefined;

  constructor(
    private readonly baseServices: AppBaseComponentService,
    private readonly signInActionService: SignInActionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly browserStorageService: BrowserStorageService,
    private readonly router: Router) {
    super(baseServices);
    this.signInState = new SignInState(browserStorageService);
  }

  ngOnInit(): void {
    super.addSubscription(
      this.authStateService.stateChanged$
        .subscribe(
          async () => {
            if (!this.authStateService.current.isAuthenticated) {
              return;
            }

            if (this.activatedRoute.snapshot.queryParams.action) {
              return this.signInActionService.redirect(this.activatedRoute.snapshot.queryParams.action);
            } else if (this.activatedRoute.snapshot.queryParams.returnUrl) {
              window.location.href = this.activatedRoute.snapshot.queryParams.returnUrl;
            } else {
              return this.router.navigate(['/supporters']);
            }
          }, resp => {
            this.apiErrorHandlerService.handleError(resp);
          }));
    }

  public onSubmit(form: NgForm): boolean {

    this.signIn();

    return true;
  }

  public signIn(): void {
    this.signingIn = true;

    window.location.href = `${this.baseServices.appConfig.getConfig('auth0').authUrl}returnUrl=${window.location.origin}`;
  }

}
