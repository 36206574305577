<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title">Change Owner</h4>
    </div>
    <div class="modal-body">
      <div *ngIf="supporterSearching">
        <div class="form-row">
          <div class="col-md-8 mb-3">
            <label class="control-label" for="supporterSearchTerm">Enter a supporter ID or start typing supporter name</label>
            <input class="form-control"
                   id="supporterSearchTerm"
                   name="supporterSearchTerm"
                   autofocus
                   autocomplete="off"
                   (keyup)="changeSupporterSearchTerm($event.target.value)"
                   [(ngModel)]="model.dummy">
          </div>
        </div>
        <div class="form-row">
          <p>{{ searchStatus }}</p>
        </div>
        <div class="form-row">
          <table class="search-results col-md-12 mb-3" *ngIf="supporters && supporters.length">
            <thead>
              <tr>
                <th>Supporter ID</th>
                <th>Supporter Name</th>
                <th>Supporter Email</th>
              </tr>
            </thead>
            <tbody>
              <tr class="clickable" *ngFor="let supporter of supporters" (click)="selectMatchedSupporter(supporter)">
                <td>{{ supporter.id }}</td>
                <td>{{ getSupporterFullName(supporter) }}</td>
                <td>{{ supporter.emailAddress }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div *ngIf="!supporterSearching">
        <p>
          Are you sure you want to make <i>{{ getSupporterFullName(selectedSupporter) }}</i> team owner?
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-s pull-right" *ngIf="supporterSearching" (click)="close()">Close</button>
      <button class="btn btn-s" *ngIf="!supporterSearching" (click)="cancel()">Cancel</button>
      <button class="btn btn-p" *ngIf="!supporterSearching" [ladda]="isLoading" (click)="changeOwner()">Confirm</button>
    </div>
  </div>
</div>
