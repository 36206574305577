var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, ProfilesService } from '@shared';
var FundraisingProfileDetailsComponent = /** @class */ (function (_super) {
    __extends(FundraisingProfileDetailsComponent, _super);
    function FundraisingProfileDetailsComponent(baseServices, profilesService, route) {
        var _this = _super.call(this, baseServices) || this;
        _this.profilesService = profilesService;
        _this.route = route;
        _this.isPerformingAction = false;
        return _this;
    }
    FundraisingProfileDetailsComponent.prototype.ngOnInit = function () {
        this.profileId = this.route.snapshot.params.id;
        this.getProfile();
    };
    FundraisingProfileDetailsComponent.prototype.reinstate = function () {
        var _this = this;
        this.base.dialogService.showConfirmation('Reinstate Profile', "Are you sure you want to reinstate this fundraising profile?\n      <br><i>(Please ensure the commitment is reinstated in Echo when reinstating a Fundraising Profile.)</i>", 'Confirm', function () {
            _this.isPerformingAction = true;
            _this.profilesService.reinstateProfile(_this.profileId)
                .pipe(finalize(function () { return _this.isPerformingAction = false; }))
                .subscribe(function () {
                _this.base.dialogService.showInformation('Success', 'Fundraising Profile reinstated successfully');
                _this.profile.deleted = false;
            }, function (resp) {
                _this.apiErrorHandlerService.handleError(resp);
            });
        });
    };
    FundraisingProfileDetailsComponent.prototype.loadProfileStatus = function () {
        return this.profile.deleted
            ? 'Deleted'
            : (this.profile.published
                ? 'Active | Published'
                : 'Active | Unpublished');
    };
    FundraisingProfileDetailsComponent.prototype.getProfile = function () {
        var _this = this;
        this.loadingState.setLoading();
        this.profilesService.getProfile(this.profileId)
            .pipe(finalize(function () {
            _this.loadingState.setLoaded();
        }))
            .subscribe(function (resp) {
            _this.profile = resp;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    return FundraisingProfileDetailsComponent;
}(AppBaseComponent));
export { FundraisingProfileDetailsComponent };
