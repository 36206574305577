import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

@Injectable()
export class PreviewService extends BaseService {
  private static readonly previewUrl = 'admin/previews';

  public getEmailPreview(type: string, submodel: any): Observable<any> {
    const options = this.anonRequestOptions();
    options.responseType = 'text';
    const model = { data: submodel };

    return this.post<any>(`${PreviewService.previewUrl}/emails/${type}`, model, options);
  }
}
