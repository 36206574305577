import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { CookieModule } from 'ngx-cookie';

import { CanActivateAuthGuard } from './can-activate-auth-guard';
import { AuthStateService } from './services/auth-state.service';
import { AuthService } from './services/auth.service';

const accessToken = 'access_token';

// tslint:disable-next-line:only-arrow-functions
export function tokenGetter(): string {
  return localStorage.getItem(accessToken);
}

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter
      }
    }),
    CookieModule.forRoot()
  ],
  providers: [
    AuthService,
    AuthStateService,
    CanActivateAuthGuard
  ]
})
export class AuthModule { }
