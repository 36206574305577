import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, PreviewService } from '@shared';
import { EmailPreviewService } from './services/email-preview-service';

@Component({
  selector: 'wv-preview',
  templateUrl: './email-preview.component.html'
})
export class EmailPreviewComponent extends AppBaseComponent implements OnInit {
  public data: string;
  public isPreviewLoading = false;
  public preview: any = {};

  constructor(
    base: AppBaseComponentService,
    private readonly previewService: PreviewService,
    private readonly route: ActivatedRoute,
    private readonly emailPreviewService: EmailPreviewService) {
    super(base);
  }

  ngOnInit(): void {
    this.addSubscription(
      this.route.params.subscribe(params => {
        this.initData(params)
          .subscribe(
            resp => this.data = resp as string,
            err => this.apiErrorHandlerService.handleError(err));
      }));
  }

  private initData(params: any): Observable<any> {
    const type = params.type;
    const subType = params.subType;
    const variant = params.variant;

    this.preview = this.emailPreviewService.loadSelectedTypePreview(type, subType, variant);
    const previewType = this.preview.typeName;
    const model = this.preview ? this.preview.data : undefined;
    let location = `/previews/emails/${type}`;

    if (!subType && this.preview.subType) {
      location += `/${this.preview.subType.path}`;

      return from(this.base.router.navigate([location]));
    }

    if (!variant && this.preview.subType && this.preview.subType.child) {
      location += `/${this.preview.subType.path}/${this.preview.variant.path}`;

      return from(this.base.router.navigate([location]));
    }

    return this.loadPreview(previewType, model);
  }

  private loadPreview(type: string, model: any): Observable<any> {
    this.isPreviewLoading = true;

    return this.previewService.getEmailPreview(type, model)
      .pipe(finalize(
        () => {
          this.isPreviewLoading = false;
        }));
  }
}
