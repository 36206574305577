var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var SmilesService = /** @class */ (function (_super) {
    __extends(SmilesService, _super);
    function SmilesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SmilesService.prototype.getSmilesGift = function (id) {
        return this.get(SmilesService.smilesServiceUrl + "/gifts/" + id);
    };
    SmilesService.prototype.getSmilesOrdersBySearchTerm = function (searchTerm, take, skip) {
        if (skip === void 0) { skip = 0; }
        return this.get(SmilesService.smilesServiceUrl + "/orders?searchTerm=" + searchTerm + "&Take=" + take + "&Skip=" + skip);
    };
    SmilesService.prototype.getSmilesOrderByOrderId = function (orderId) {
        return this.get(SmilesService.smilesServiceUrl + "/orders/" + orderId);
    };
    SmilesService.prototype.updateSmilesGift = function (id, emailAddress, sendDate) {
        var model = {
            recipientEmailAddress: emailAddress,
            sendDate: sendDate
        };
        return this.put(SmilesService.smilesServiceUrl + "/gifts/" + id, model);
    };
    SmilesService.prototype.resendSmilesGiftEmail = function (id) {
        var operation = {
            operation: 'resendSmilesGiftEmail',
            data: {}
        };
        return this.patch(SmilesService.smilesServiceUrl + "/gifts/" + id, operation);
    };
    SmilesService.smilesServiceUrl = 'admin/smiles';
    return SmilesService;
}(BaseService));
export { SmilesService };
