import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { BrowserStorageService } from '@shared';

@Injectable()
export class SignInStateService {
  public stateChanged$: Observable<any>;

  private readonly subject: Subject<any>;

  constructor(private readonly browserStorageService: BrowserStorageService) {
    this.subject = new Subject<any>();
    this.stateChanged$ = this.subject.asObservable();
  }

  public setReturnUrl(returnUrl: string): void {
    if (returnUrl !== undefined) {
      this.setItem('returnUrl', returnUrl);
    } else {
      this.removeItem('returnUrl');
    }

    this.subject.next();
  }

  public setEmailAddress(emailAddress: string, emailAddressAvailable: boolean): void {
    this.setItem('emailAddress', emailAddress);
    this.setItem('emailAddressAvailable', emailAddressAvailable !== undefined ? String(emailAddressAvailable) : undefined);

    this.subject.next();
  }

  public clearState(): void {
    this.removeItem('returnUrl');
    this.removeItem('emailAddress');
    this.removeItem('emailAddressAvailable');

    this.subject.next();
  }

  private setItem(key: string, value: any): void {
    if (value === undefined) {
      this.browserStorageService.removeItem(key);

      return;
    }

    this.browserStorageService.setItem(`signInState.${key}`, value);
  }

  private removeItem(key: string): void {
    this.browserStorageService.removeItem(`signInState.${key}`);
  }
}
