import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, ISmilesGift, SmilesService } from '@shared';

@Component({
  selector: 'wv-smiles-gifts',
  templateUrl: './smiles-gifts.component.html'
})
export class SmilesGiftsComponent extends AppBaseComponent implements OnInit {
  public smilesGifts: Array<ISmilesGift>;
  private orderId: number;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly smilesService: SmilesService) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.params.id;
    this.getSmilesGifts();
  }

  public getSmilesGifts(): void {
    this.loadingState.setLoading();
    this.smilesService.getSmilesOrderByOrderId(this.orderId)
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(resp => {
        this.smilesGifts = resp.gifts;
      }, resp => {
        this.apiErrorHandlerService.handleError(resp);
      });
  }
}
