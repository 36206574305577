var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseComponent } from '../../base.component';
import { BaseComponentService } from '../../services/base-component.service';
import { DialogType } from './dialog-type';
var DialogComponent = /** @class */ (function (_super) {
    __extends(DialogComponent, _super);
    function DialogComponent(baseServices) {
        var _this = _super.call(this, baseServices) || this;
        _this.type = DialogType.Information;
        _this.title = undefined;
        _this.message = undefined;
        _this.okCaption = undefined;
        _this.showOk = false;
        _this.cancelCaption = undefined;
        _this.showCancel = false;
        _this.dialogLoading = false;
        _this.modalContainerOptions = {
            overlayClass: '',
            modalClass: 'modal-dialog mybnc-modal'
        };
        return _this;
    }
    DialogComponent.prototype.okClick = function (event) {
        var _this = this;
        event.stopPropagation();
        var close = true;
        if (this.ok) {
            var result = this.ok();
            var obs = result;
            if (obs === undefined) {
                close = result;
            }
            else {
                this.dialogLoading = true;
                obs.subscribe(function (b) {
                    _this.dialogLoading = false;
                    if (b) {
                        _this.close();
                    }
                });
                return;
            }
        }
        if (close) {
            this.close();
        }
    };
    DialogComponent.prototype.cancelClick = function () {
        if (this.cancel) {
            this.cancel();
        }
        this.close();
    };
    return DialogComponent;
}(BaseComponent));
export { DialogComponent };
