var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var ChosenPhotosService = /** @class */ (function (_super) {
    __extends(ChosenPhotosService, _super);
    function ChosenPhotosService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ChosenPhotosService.prototype.find = function (query) {
        return this.get("admin/photos?" + BaseService.objectToQueryString(query));
    };
    ChosenPhotosService.prototype.getById = function (id) {
        return this.get("admin/photos/" + id);
    };
    ChosenPhotosService.prototype.cleanPhotoFileName = function ($fileanme) {
        return $fileanme.replace('uploads/', '');
    };
    return ChosenPhotosService;
}(BaseService));
export { ChosenPhotosService };
