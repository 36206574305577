<form class="container">
  <h1>Search Fundraising Teams</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <div *ngIf="teamSearching">
        <div class="form-row">
          <div class="col-md-8 mb-3">
            <label class="control-label" for="teamSearchTerm">Enter a team supporter ID or start typing team name</label>
            <input class="form-control"
                   id="teamSearchTerm"
                   name="teamSearchTerm"
                   autofocus
                   autocomplete="off"
                   (keyup)="changeTeamSearchTerm($event.target.value)"
                   [(ngModel)]="model.dummy">
          </div>
        </div>
        <div class="form-row">
          <p>{{ searchStatus }}</p>
        </div>
        <div class="form-row">
          <table class="search-results col-md-8 mb-3" *ngIf="teams && teams.length">
            <thead>
            <tr>
              <th>Team ID</th>
              <th>Team Name</th>
            </tr>
            </thead>
            <tbody>
            <tr class="clickable" *ngFor="let team of teams" (click)="selectMatchedTeam(team)">
              <td>{{ team.id }}</td>
              <td>{{ team.name }}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="form-row">
          <h4>OR</h4>
          <div class="col-md-12 mb-3 mt-4">
            <a class="btn btn-p" [routerLink]="['/fundraising', 'teams', 'create']">Create Team</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
