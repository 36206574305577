// immutable authentication state
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';

import { AuthStateService } from '../services/auth-state.service';
import { OAuthIdentity } from './oauth-identity';
import { OAuthTokenResponse } from './oauth-token-reponse';

export class AuthState {
  private static readonly adminRoleClaim = 'Supporter Admin';
  private readonly jwtHelper: JwtHelperService = undefined;

  constructor(
    private readonly cookieService: CookieService,
    private readonly authStateService: AuthStateService,
    private readonly router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
  }

  public get accessToken(): string {
    return this.cookieService.get(this.authStateService.accessTokenName);
  }

  public get refreshToken(): string {
    return this.cookieService.get(this.authStateService.refreshTokenName);
  }

  public get identity(): OAuthIdentity {
    const accessToken = this.accessToken;
    const refreshToken = this.refreshToken;

    if (!accessToken) {
      return undefined;
    }

    const claims: any = this.jwtHelper.decodeToken(accessToken);
    const identity = new OAuthIdentity(new OAuthTokenResponse(accessToken, refreshToken, 0), claims);

    return identity;
  }

  public get isAuthenticated(): boolean {
    return (this.identity && this.identity.claims && this.identity.claims.role === AuthState.adminRoleClaim);
  }
}
