import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

import { AppBaseComponent, AppBaseComponentService, IPagingInfo, ISmilesOrder, PagingWrapper } from '@shared';

@Component({
  selector: 'wv-smiles-order-list',
  templateUrl: './smiles-order-list.component.html'
})
export class SmilesOrderListComponent extends AppBaseComponent implements OnChanges {
  @Input()
  public pagingWrapper: PagingWrapper<ISmilesOrder>;
  @Output()
  public readonly paging: EventEmitter<IPagingInfo> = new EventEmitter<IPagingInfo>();
  public orders: Array<ISmilesOrder> = [];
  public pages: Array<number> = [];
  public tablePageSizeItems: Array<number> = [];
  public activePage = 1;
  private activeTake: number;

  constructor(
    baseServices: AppBaseComponentService) {
    super(baseServices);
    this.tablePageSizeItems = this.base.appConfig.getConfig('tablePageSizeItems');
    this.activeTake = this.tablePageSizeItems[0];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pagingWrapper) {
      this.initData();
    }
  }

  public getOrdersForPage(pageNumber: number): void {
    this.activePage = pageNumber;
    this.setPaging();
  }

  public selectTake(item: number): void {
    this.activeTake = item;
    this.setPaging();
  }

  private setPaging(): void {
    this.paging.next({
      skip: ((this.activePage - 1) * this.activeTake),
      take: this.activeTake
    });
  }

  private initData(): void {
    this.pages = [];
    this.orders = this.pagingWrapper.items;
    const pageCount = Math.ceil(this.pagingWrapper.total / this.activeTake);
    if (pageCount > 1) {
      for (let i = 1; i <= pageCount; i++) {
        this.pages.push(i);
      }
    }
  }
}
