import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';
import { Subject } from 'rxjs';
import { AppConfig } from '../../core/app.config';
import { AuthState } from '../models/auth-state';
var AuthStateService = /** @class */ (function () {
    function AuthStateService(config, cookieService, router) {
        this.config = config;
        this.cookieService = cookieService;
        this.router = router;
        this.adminPortalCookiePrefix = 'admin_portal';
        this.tokenPrefix = undefined;
        this.baseAccessTokenName = 'access_token';
        this.baseRefreshTokenName = 'refresh_token';
        this.jwtHelper = undefined;
        var envName = config.getConfig('cookiePrefix');
        if (envName && envName.toLowerCase() !== 'prod') {
            this.tokenPrefix = envName.toLowerCase() + "_";
        }
        this.subject = new Subject();
        this.stateChanged$ = this.subject.asObservable();
        this.jwtHelper = new JwtHelperService();
    }
    Object.defineProperty(AuthStateService.prototype, "accessTokenName", {
        get: function () {
            return this.tokenPrefix ? this.tokenPrefix + this.baseAccessTokenName : this.baseAccessTokenName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStateService.prototype, "refreshTokenName", {
        get: function () {
            return this.tokenPrefix ? this.tokenPrefix + this.baseRefreshTokenName : this.baseRefreshTokenName;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthStateService.prototype, "current", {
        get: function () {
            return new AuthState(this.cookieService, this, this.router);
        },
        enumerable: true,
        configurable: true
    });
    AuthStateService.prototype.setTokens = function (accessToken, refreshToken, expiresIn) {
        var now = new Date();
        var accessExpiry = new Date(now.getTime() + (expiresIn * 1000));
        var domain = this.getDomain();
        var accessTokenOptions = {
            domain: domain,
            path: '/',
            expires: accessExpiry,
            secure: false // TO DO review this
        };
        var refreshExpiry = new Date(now);
        refreshExpiry.setDate(refreshExpiry.getDate() + 30);
        var refreshTokenOptions = {
            domain: domain,
            path: '/',
            expires: refreshExpiry,
            secure: false // TO DO review this
        };
        this.cookieService.put(this.accessTokenName, accessToken, accessTokenOptions);
        this.cookieService.put(this.refreshTokenName, refreshToken, refreshTokenOptions);
        this.subject.next(this.current.isAuthenticated);
    };
    AuthStateService.prototype.clearState = function () {
        var domain = this.getDomain();
        this.cookieService.remove(this.accessTokenName, { domain: domain });
        this.cookieService.remove(this.refreshTokenName, { domain: domain });
        this.subject.next(false);
    };
    AuthStateService.prototype.getDomain = function () {
        return this.config.getConfig('domain');
    };
    return AuthStateService;
}());
export { AuthStateService };
