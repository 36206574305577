var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { AuthService, AuthStateService } from '@auth';
import { AppConfig, BaseComponentService, DialogService, ModalService } from '@core';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { ValidationRulesService } from './validation-rules.service';
var AppBaseComponentService = /** @class */ (function (_super) {
    __extends(AppBaseComponentService, _super);
    function AppBaseComponentService(dialogService, appConfig, authService, modalService, authStateService, validationRules, router, apiErrorHandlerService) {
        var _this = _super.call(this) || this;
        _this.dialogService = dialogService;
        _this.appConfig = appConfig;
        _this.authService = authService;
        _this.modalService = modalService;
        _this.authStateService = authStateService;
        _this.validationRules = validationRules;
        _this.router = router;
        _this.apiErrorHandlerService = apiErrorHandlerService;
        return _this;
    }
    return AppBaseComponentService;
}(BaseComponentService));
export { AppBaseComponentService };
