import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { saveAs } from 'file-saver';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, ReportsService } from '@shared';

@Component({
  selector: 'wv-child-report',
  templateUrl: './child-report.component.html'
})
export class ChildReportComponent extends AppBaseComponent {
  public model: any = {};

  constructor(
    private readonly reportsService: ReportsService,
    private readonly baseServices: AppBaseComponentService) {
    super(baseServices);
  }

  public onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this.loadingState.setLoading();

    this.reportsService.children(this.model.poolCode)
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(async resp => {
        saveAs(resp.body, resp.filename);
      }, resp => {
        this.base.apiErrorHandlerService.handleError(resp);
      });
  }
}
