var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var PreviewService = /** @class */ (function (_super) {
    __extends(PreviewService, _super);
    function PreviewService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PreviewService.prototype.getEmailPreview = function (type, submodel) {
        var options = this.anonRequestOptions();
        options.responseType = 'text';
        var model = { data: submodel };
        return this.post(PreviewService.previewUrl + "/emails/" + type, model, options);
    };
    PreviewService.previewUrl = 'admin/previews';
    return PreviewService;
}(BaseService));
export { PreviewService };
