/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "../core/loader/loader.component.ngfactory";
import * as i5 from "../core/loader/loader.component";
import * as i6 from "../shared/safe-html.pipe";
import * as i7 from "@angular/platform-browser";
import * as i8 from "./email-preview-sidenav.component.ngfactory";
import * as i9 from "./email-preview-sidenav.component";
import * as i10 from "../shared/app-base-component.service";
import * as i11 from "./services/email-preview-service";
import * as i12 from "./email-preview.component";
import * as i13 from "../shared/api/preview.service";
var styles_EmailPreviewComponent = [];
var RenderType_EmailPreviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmailPreviewComponent, data: {} });
export { RenderType_EmailPreviewComponent as RenderType_EmailPreviewComponent };
function View_EmailPreviewComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Variant"])), (_l()(), i0.ɵted(3, null, [" - ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preview.variant.name; _ck(_v, 3, 0, currVal_0); }); }
function View_EmailPreviewComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 4), (_l()(), i0.ɵted(3, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 2, 0, "/previews/emails/", _co.preview.type.path, _co.preview.subType.path, _v.context.$implicit.path); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); }); }
function View_EmailPreviewComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(2, 212992, null, 2, i2.NgbDropdown, [i0.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i0.NgZone, i2.ɵz], null, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i0.ɵeld(5, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-light dropdown-toggle"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i2.ɵr, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[2, 4]], i2.ɵq, null, [i2.ɵr]), (_l()(), i0.ɵted(-1, null, [" View another variant "])), (_l()(), i0.ɵeld(9, 0, null, null, 3, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i0.ɵdid(10, 16384, [[1, 4]], 0, i2.ɵp, [i2.NgbDropdown, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_4)), i0.ɵdid(12, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_5 = _co.preview.subType.child; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 6).dropdown.isOpen(); _ck(_v, 5, 0, currVal_1); var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 10).dropdown.isOpen(); var currVal_4 = i0.ɵnov(_v, 10).placement; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); }); }
function View_EmailPreviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "email-preview-title"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, [" - ", " "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_2)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_3)), i0.ɵdid(8, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.preview.variant; _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.preview.subType && _co.preview.subType.child); _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preview.type.name; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.preview.subType.name; _ck(_v, 4, 0, currVal_1); }); }
function View_EmailPreviewComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), _co.data)); _ck(_v, 0, 0, currVal_0); }); }
function View_EmailPreviewComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i5.LoaderComponent, [], null, null)], null, null); }
export function View_EmailPreviewComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.SafeHtmlPipe, [i7.DomSanitizer]), (_l()(), i0.ɵeld(1, 0, null, null, 1, "wv-email-preview-sidenav", [], null, null, null, i8.View_EmailPreviewSideNavComponent_0, i8.RenderType_EmailPreviewSideNavComponent)), i0.ɵdid(2, 245760, null, 0, i9.EmailPreviewSideNavComponent, [i10.AppBaseComponentService, i1.ActivatedRoute, i11.EmailPreviewService], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(5, 0, null, null, 2, "div", [["class", "preview-email-template side-nav-gutter"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_5)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EmailPreviewComponent_6)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 2, 0); var currVal_0 = ((_co.preview && _co.preview.type) && _co.preview.subType); _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.data && !_co.isPreviewLoading); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isPreviewLoading; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_EmailPreviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-preview", [], null, null, null, View_EmailPreviewComponent_0, RenderType_EmailPreviewComponent)), i0.ɵdid(1, 245760, null, 0, i12.EmailPreviewComponent, [i10.AppBaseComponentService, i13.PreviewService, i1.ActivatedRoute, i11.EmailPreviewService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EmailPreviewComponentNgFactory = i0.ɵccf("wv-preview", i12.EmailPreviewComponent, View_EmailPreviewComponent_Host_0, {}, {}, []);
export { EmailPreviewComponentNgFactory as EmailPreviewComponentNgFactory };
