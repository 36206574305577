<div *ngIf="smilesGift">
  <form class="container" (ngSubmit)="onSubmit(smilesGiftForm)" #smilesGiftForm="ngForm" novalidate>
    <h1>Smiles Gift</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="giftType">Gift Type</label>
        <input type="text" class="form-control" id="giftType" name="giftType"
               [(ngModel)]="smilesGift.giftType" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label for="sendDate">Send Date</label>
        <input type="text"
               class="form-control"
               id="sendDate"
               name="sendDate"
               *ngIf="!isEditing"
               disabled
               [value]="smilesGift.sendDate | date:'dd MMMM yyyy'"
               [title]="smilesGift.sendDate | date:'dd MMMM yyyy hh:mm:ss a'" />
        <div *ngIf="isEditing">
          <div class="input-group">
            <input class="form-control"
                   placeholder="yyyy-MM-dd"
                   required
                   name="dp"
                   [(ngModel)]="sendDateModel"
                   (change)="evalDp(sendDate, sendDateRef, $event)"
                   (dateSelect)="evalDp(sendDate, sendDateRef, $event)"
                   ngbDatepicker
                   [minDate]="sendMinDate"
                   #sendDateRef
                   #sendDate="ngModel"
                   #sendDatePicker="ngbDatepicker">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="sendDatePicker.toggle()" type="button"></button>
            </div>
          </div>
          <wv-field-helper class="inline-block" id="sendDateHelper" [control]="sendDate"
                           [errorMessages]="{'required': 'Please enter a send date.',
                           'ngbDate': 'Please enter a valid future date'}">
          </wv-field-helper>
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="senderEmail">Sender Email</label>
        <input type="text" class="form-control" id="senderEmail" name="senderEmail"
               [(ngModel)]="smilesGift.supporter.emailAddress" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <div>
          <label for="recipientEmail">Recipient Email</label>
          <input type="text" class="form-control" id="recipientEmail" name="recipientEmail"
                 required
                 [pattern]="validationRules.emailAddressPattern"
                 [(ngModel)]="smilesGift.recipientEmailAddress" [disabled]="!isEditing"
                 #recipientEmail="ngModel" />
        </div>
        <wv-field-helper class="inline-block" id="recipientEmailHelper" [control]="recipientEmail"
                         [errorMessages]="{'required': 'Please enter a email address.',
                           'pattern': 'Please enter email in a valid format \'mymail@domain.com\'.'}">
        </wv-field-helper>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Status</label>
        <h2>
          <span class="badge"
                [class.badge-success]="smilesGiftStatus == SmilesGiftStatus.Sent"
                [class.badge-danger]="smilesGiftStatus == SmilesGiftStatus.Unsent"
                [class.badge-secondary]="smilesGiftStatus == SmilesGiftStatus.Sent || smilesGiftStatus == SmilesGiftStatus.Pending"
                [title]="smilesGift.sentDate | date:'dd MMMM yyyy hh:mm:ss a'">
            {{ SmilesGiftStatus[smilesGiftStatus] }}
          </span>
        </h2>
      </div>
      <div class="col-md-4 mb-3" *ngIf="smilesGift.sendError">
        <label for="sendError">Send Error</label>
        <textarea type="text" class="form-control text-white bg-danger"
                  id="sendError" name="sendError" rows="2"
                  [(ngModel)]="smilesGift.sendError" disabled>
        </textarea>
      </div>
    </div>
    <div class="form-row">
      <div class="pull-right action-group">
        <button class="btn btn-p pull-right" type="submit" *ngIf="isEditing" [ladda]="isPerformingAction">Update</button>
        <button class="btn btn-p pull-right" type="button" *ngIf="!isEditing" (click)="edit()">Edit</button>
        <button class="btn btn-s pull-right" type="button" *ngIf="isEditing" (click)="cancelEditing()">Cancel</button>
        <button class="btn btn-s pull-right" type="button" *ngIf="!isEditing" (click)="sendEmail()" [ladda]="isPerformingAction">
          {{ smilesGiftStatus === SmilesGiftStatus.Sent ? 'Resend' : 'Send now' }}
        </button>
      </div>
    </div>
  </form>
</div>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
