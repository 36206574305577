<form class="mt-3" (ngSubmit)="onSubmit(createTeamForm)"
      #createTeamForm="ngForm"
      novalidate
      *ngIf="!loadingState.isLoading()">
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <div>
        <label for="teamName">Name</label>
        <input type="text"
               class="form-control"
               id="teamName"
               name="teamName"
               required
               [pattern]="validationRules.namePattern"
               #teamName="ngModel"
               [(ngModel)]="model.name" />
      </div>
      <wv-field-helper class="inline-block" id="teamNameHelper" [control]="teamName"
                       [errorMessages]="{'required': 'Enter your team name.',
                       'pattern': 'Please enter a valid team name e.g. St Annes School'}">
      </wv-field-helper>
    </div>
    <div class="col-md-4 mb-3">
      <div>
        <label for="teamTypeCode">Team Type Code</label>
        <select class="form-control"
                name="teamTypeCode"
                id="teamTypeCode"
                #teamTypeCode="ngModel"
                [(ngModel)]="model.teamTypeCode"
                required>
          <option *ngFor="let teamTypeCode of teamTypeCodes"
                  [value]="teamTypeCode.code">
            {{ teamTypeCode.name }}
          </option>
        </select>
      </div>
      <wv-field-helper class="inline-block" id="teamTypeCodeHelper" [control]="teamTypeCode"
                       [errorMessages]="{'required': 'Select a team type code'}">
      </wv-field-helper>
    </div>
  </div>
  <div class="form-row" *ngIf="isLoadingExistingTeam">
    <div class="col-md-4 mb-3">
      <div>
        <label for="echoId">Echo ID</label>
        <input type="text"
               class="form-control"
               id="echoId"
               name="echoId"
               required
               #echoId="ngModel"
               [(ngModel)]="model.echoId" />
      </div>
      <wv-field-helper class="inline-block" id="echoIdHelper" [control]="echoId"
                       [errorMessages]="{'required': 'Enter an echo supporter ID.'}">
      </wv-field-helper>
    </div>
  </div>
    <div class="form-row">
      <div class="action-group">
        <button class="btn btn-p" type="submit" [ladda]="isPerformingAction">Create</button>
      </div>
    </div>
</form>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
