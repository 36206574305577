import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { LoadingStateManager } from '@core';
import { AppBaseComponent, AppBaseComponentService, TransactionService } from '@shared';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'wv-transaction-list',
  templateUrl: './transaction-list.component.html'
})
export class TransactionListComponent extends AppBaseComponent implements OnInit {

  public model: any = {};
  public transactions: any;
  public total: number;
  public readonly pageSize = 20;
  public page = 1;
  public statuses: Array<string>;
  public transactionTypes: Array<string>;
  public productTypes: Array<string>;
  public searchState = new LoadingStateManager();

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly transactionService: TransactionService) {
    super(base);
  }

  public ngOnInit(): void {

    this.loadingState.setLoading();

    const params = this.route.snapshot.queryParams;
    this.page = params.page ? Number.parseInt(params.page, 10) : 1;
    this.model.status = params.status;
    this.model.transactionType = params.transactionType;
    this.model.productType = params.productType;
    this.model.transactionId = params.transactionId;
    this.model.supporterId = params.supporterId;

    forkJoin(
      this.transactionService.getTransactionStatuses(),
      this.transactionService.getTransactionTypes(),
      this.transactionService.getProductTypes()
    )
    .subscribe(([statuses, transactionTypes, productTypes]) => {
      this.statuses = statuses;
      this.transactionTypes = transactionTypes;
      this.productTypes = productTypes;
    });

    this.loadPage(this.page, this.pageSize);
  }

  public pageChange(pageNumber: number): void {
    this.updateFilters();
    this.loadPage(pageNumber, this.pageSize);
  }

  public onSubmit(_form: NgForm): void {
    this.page = 1;
    this.updateFilters();
    this.loadPage(this.page, this.pageSize);
  }

  private updateFilters(): void {
    this.base.router.navigate([], {
      queryParams: {
        page: this.page,
        status: this.model.status,
        transactionType: this.model.transactionType,
        productType: this.model.productType,
        transactionId: this.model.transactionId,
        supporterId: this.model.supporterId
      }
    })
    .catch();
  }

  private loadPage(page: number, pageSize: number): void {

    if (this.loadingState.isLoaded()) {
      this.loadingState.setRefreshing();
    }

    this.transactionService.find({
      skip: (page - 1) * this.pageSize,
      take: pageSize,
      status: this.model.status,
      transactionType: this.model.transactionType,
      productType: this.model.productType,
      transactionId: this.model.transactionId,
      supporterId: this.model.supporterId
    })
      .subscribe(transactions => {
        this.loadingState.setLoaded();
        this.total = transactions.total;
        this.transactions = transactions.items;
      });
  }
}
