var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base.service';
var ProfilesService = /** @class */ (function (_super) {
    __extends(ProfilesService, _super);
    function ProfilesService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ProfilesService.prototype.getProfile = function (id) {
        return this.get("admin/fundraising/profiles/" + id);
    };
    ProfilesService.prototype.reinstateProfile = function (id) {
        var operation = {
            operation: 'reinstateProfile',
            data: {}
        };
        return this.patch("admin/fundraising/profiles/" + id, operation);
    };
    ProfilesService.prototype.searchFundraisingProfiles = function (searchTerm) {
        return this.get("admin/fundraising/profiles?searchTerm=" + searchTerm + "&limit=10");
    };
    ProfilesService.prototype.createEchoTeamProfile = function (teamId, campaignCode, commitmentId) {
        var model = {
            campaignCode: campaignCode,
            teamId: teamId,
            commitmentId: commitmentId
        };
        return this.post('admin/fundraising/profiles', model);
    };
    return ProfilesService;
}(BaseService));
export { ProfilesService };
