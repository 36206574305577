/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./modal-container.component";
import * as i3 from "../services/base-component.service";
var styles_ModalContainerComponent = [];
var RenderType_ModalContainerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalContainerComponent, data: {} });
export { RenderType_ModalContainerComponent as RenderType_ModalContainerComponent };
function View_ModalContainerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ModalContainerComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { dynamicContent: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "modal-backdrop fade"]], [[4, "display", null]], null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "modal"], ["role", "dialog"], ["tabindex", "-1"]], [[4, "display", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onBackDropClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵeld(4, 0, null, null, 2, "div", [["role", "document"]], null, null, null, null, null)), i0.ɵdid(5, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵand(16777216, [[1, 3], ["dynamicContent", 2]], null, 0, null, View_ModalContainerComponent_1))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "modal"; var currVal_3 = _co.options.overlayClass; _ck(_v, 3, 0, currVal_2, currVal_3); var currVal_4 = _co.options.modalClass; _ck(_v, 5, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = "block"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "block"; _ck(_v, 2, 0, currVal_1); }); }
export function View_ModalContainerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ModalContainerComponent_0, RenderType_ModalContainerComponent)), i0.ɵdid(1, 180224, null, 0, i2.ModalContainerComponent, [i3.BaseComponentService, i0.ComponentFactoryResolver, i0.ElementRef, i1.DOCUMENT, i0.RendererFactory2], null, null)], null, null); }
var ModalContainerComponentNgFactory = i0.ɵccf("ng-component", i2.ModalContainerComponent, View_ModalContainerComponent_Host_0, { destroy: "destroy", options: "options" }, {}, []);
export { ModalContainerComponentNgFactory as ModalContainerComponentNgFactory };
