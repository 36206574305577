import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  NgbAccordionModule,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbPaginationModule,
  NgbTabsetModule
} from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';

import { SupportersRoutingModule } from './supporters-routing.module';

import { SmilesGiftDetailsComponent } from './smiles-gift/smiles-gift-details.component';
import { SmilesGiftsComponent } from './smiles-gift/smiles-gifts.component';
import { SmilesOrderListComponent } from './smiles-order/smiles-order-list.component';
import { SmilesOrderComponent } from './smiles-order/smiles-order.component';
import { SupporterChildMessageDetailsComponent } from './supporter-child-message-details/supporter-child-message-details.component';
import { SupporterChildMessagesListComponent } from './supporter-child-messages-list/supporter-child-messages-list.component';
import { SupporterDetailsComponent } from './supporter-details.component';
import { SupporterUserListComponent } from './supporter-users-list.component';
import { SupportersComponent } from './supporters.component';
import { ChangeSupporterModalComponent } from './user/change-supporter-modal.component';
import { UserComponent } from './user/user.component';

@NgModule({
  declarations: [
    SmilesGiftDetailsComponent,
    SmilesGiftsComponent,
    SupportersComponent,
    SupporterDetailsComponent,
    SmilesOrderComponent,
    SmilesOrderListComponent,
    SupporterUserListComponent,
    ChangeSupporterModalComponent,
    UserComponent,
    SupporterChildMessagesListComponent,
    SupporterChildMessageDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbTabsetModule,
    LaddaModule,

    CoreModule,
    SharedModule,

    SupportersRoutingModule
  ],
  entryComponents: [
    ChangeSupporterModalComponent
  ]
})
export class SupportersModule { }
