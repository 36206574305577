import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, ISmilesGift, SmilesService } from '@shared';
import { ISmilesGiftActionOptions } from './smiles-gift-action-options';
import { SmilesGiftStatus } from './smiles-gift-status';

@Component({
  selector: 'wv-smiles-gift-details',
  templateUrl: './smiles-gift-details.component.html'
})
export class SmilesGiftDetailsComponent extends AppBaseComponent implements OnInit {
  public SmilesGiftStatus = SmilesGiftStatus;
  public smilesGift: ISmilesGift;
  public smilesGiftStatus: SmilesGiftStatus;
  public isEditing = false;
  public isPerformingAction = false;
  public sendDateModel: any = {};
  public baseSmilesGift: ISmilesGift; // used to have a revert state when cancel button is pressed
  public sendMinDate: any;
  private giftId: string;

  private static getDateFromNgbModel(dateModel: any): Date {
    return new Date(
      `${dateModel.month}-${dateModel.day}-${dateModel.year}`);
  }

  private static getSmilesGiftStatus(smilesGift: ISmilesGift): SmilesGiftStatus {
    if (smilesGift.sentDate && smilesGift.sentDate) {
      return SmilesGiftStatus.Sent;
    }

    if (smilesGift.sendDate && !smilesGift.sentDate) {
      const localDate = new Date();
      const sendDateLocal = new Date(`${smilesGift.sendDate} UTC`);

      if (sendDateLocal > localDate) {
        return SmilesGiftStatus.Pending;
      }

      return SmilesGiftStatus.Unsent;
    }

    return SmilesGiftStatus.Unknown;
  }

  constructor(
    baseServices: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly smilesService: SmilesService) {
    super(baseServices);
  }

  public ngOnInit(): void {
    this.giftId = this.route.snapshot.params.id;
    this.getSmilesGift(this.giftId);

    // Set min date for sendDate to tomorrow's date
    const now = new Date();

    this.sendMinDate = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };
  }

  public edit(): void {
    this.isEditing = true;
    const sendDate = new Date(this.smilesGift.sendDate);
    this.sendDateModel = {
      year: sendDate.getFullYear(),
      month: sendDate.getMonth() + 1,
      day: sendDate.getDate()
    };
  }

  public onSubmit(form: NgForm): boolean {
    if (form.invalid) {

      return false;
    }

    const options: ISmilesGiftActionOptions = {
      confirmationDialogTitle: 'Update smiles gift',
      confirmationDialogMessage: 'Are you sure you want to update this smiles gift?',
      informationDialogMessage: 'Smiles Gift updated successfully',
      isUpdate: true
    };

    const sendDate = SmilesGiftDetailsComponent.getDateFromNgbModel(this.sendDateModel);

    this.performAction(options, () =>
      this.smilesService.updateSmilesGift(
        this.giftId,
        this.smilesGift.recipientEmailAddress,
        sendDate.toUTCString())); // perform update action

    return true;
  }

  public sendEmail(): void {
    const options: ISmilesGiftActionOptions = {
      confirmationDialogTitle: 'Send email',
      confirmationDialogMessage: 'Are you sure you want to send this smiles gift email?',
      informationDialogMessage: 'Smiles Gift email sent successfully',
      isUpdate: false
    };

    this.performAction(options, () => this.smilesService.resendSmilesGiftEmail(this.giftId)); // perform update action
  }

  public cancelEditing(): void {
    this.isEditing = false;
    this.smilesGift = { ...this.baseSmilesGift };
  }

  // Kludge: Force datepickers (and maybe other custom controls) to update the element validity,
  // which affects the: is-valid and: is-invalid pseudo-classes
  // and therefore the visual display - due to Bootstrap 4
  public evalDp(dp, element, event): void {
    if (dp.invalid) {
      element.setCustomValidity('is-invalid');
    } else {
      element.setCustomValidity('');
    }
  }

  private getSmilesGift(id: string): void {
    this.loadingState.setLoading();
    this.smilesService.getSmilesGift(id)
      .pipe(finalize(() => {
        this.loadingState.setLoaded();
      }))
      .subscribe((resp: ISmilesGift) => {
        this.smilesGift = resp;
        this.baseSmilesGift = { ...resp };
        this.smilesGiftStatus = SmilesGiftDetailsComponent.getSmilesGiftStatus(resp);
      });
  }

  private performAction(options: ISmilesGiftActionOptions, action: () =>
    Observable<any>): Observable<any> {
    return this.dialogService.showConfirmation(options.confirmationDialogTitle,
      options.confirmationDialogMessage,
      'Confirm',
      () => {
        this.isPerformingAction = true;
        action()
          .pipe(finalize(
            () => this.isPerformingAction = false))
          .subscribe(
            () => {
              this.dialogService.showInformation('Success', options.informationDialogMessage);

              if (options.isUpdate) {
                this.smilesGift.sendDate = SmilesGiftDetailsComponent.getDateFromNgbModel(this.sendDateModel)
                  .toString();
                this.baseSmilesGift = { ...this.smilesGift };
                this.isEditing = false;

                return;
              }

              // change smiles gift status label
              this.smilesGiftStatus = SmilesGiftStatus.Sent;
            }, resp => {
              this.apiErrorHandlerService.handleError(resp);
            });
      });
  }
}
