import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ApiErrorHandlerService } from './api-error-handler.service';
import { ChosenPhotosService } from './api/chosen-photos.service';
import { CampaignsService } from './api/fundraising/campaigns.service';
import { ProfilesService } from './api/fundraising/profiles.service';
import { TeamsService } from './api/fundraising/teams.service';
import { PreviewService } from './api/preview.service';
import { ReportsService } from './api/reports/reports.service';
import { SmilesService } from './api/smiles.service';
import { SupporterChildMessageService } from './api/supporter-child-message.service';
import { SupporterService } from './api/supporter.service';
import { TransactionService } from './api/transaction.service';
import { UserService } from './api/user.service';
import { AppBaseComponentService } from './app-base-component.service';
import { ApplicationInitialisationService } from './application-initialisation.service';
import { BrowserStorageService } from './browser-storage.service';
import { LastViewedSupportersService } from './last-viewed-supporters.service';
import { SafeHtmlPipe } from './safe-html.pipe';
import { TruncatePipe } from './truncate.pipe';
import { ValidationRulesService } from './validation-rules.service';

@NgModule({
  declarations: [
    SafeHtmlPipe,
    TruncatePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    SafeHtmlPipe,
    TruncatePipe
  ],
  providers: [
    AppBaseComponentService,
    ApplicationInitialisationService,
    BrowserStorageService,
    ValidationRulesService,
    SmilesService,
    SupporterService,
    TeamsService,
    ProfilesService,
    LastViewedSupportersService,
    UserService,
    ApiErrorHandlerService,
    PreviewService,
    CampaignsService,
    ReportsService,
    TransactionService,
    ChosenPhotosService,
    SupporterChildMessageService
  ]
})
export class SharedModule { }
