import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { ReportsRoutingModule } from './reports-routing.module';

import { ChildReportComponent } from './child-report/child-report.component';
import { ProjectReportComponent } from './project-report/project-report.component';

@NgModule({
  declarations: [
    ChildReportComponent,
    ProjectReportComponent
  ],
  exports: [
    ChildReportComponent,
    ProjectReportComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,

    LaddaModule,

    CoreModule,
    SharedModule,
    ReportsRoutingModule
  ]
})
export class ReportsModule { }
