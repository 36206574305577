var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, SmilesService } from '@shared';
var SmilesOrderComponent = /** @class */ (function (_super) {
    __extends(SmilesOrderComponent, _super);
    function SmilesOrderComponent(baseServices, smilesService) {
        var _this = _super.call(this, baseServices) || this;
        _this.smilesService = smilesService;
        _this.model = {};
        _this.loadingOrders = false;
        var tablePageSizeItems = _this.base.appConfig.getConfig('tablePageSizeItems');
        _this.take = tablePageSizeItems[0];
        return _this;
    }
    SmilesOrderComponent.prototype.onSubmit = function (form) {
        if (form.invalid) {
            return false;
        }
        this.loadingOrders = true;
        this.getOrders(0, this.take);
        return true;
    };
    SmilesOrderComponent.prototype.onPagingChange = function (paging) {
        if (paging) {
            this.take = paging.take;
            this.getOrders(paging.skip, paging.take);
        }
    };
    SmilesOrderComponent.prototype.getOrders = function (skip, take) {
        var _this = this;
        this.smilesService.getSmilesOrdersBySearchTerm(this.model.searchTerm, take, skip)
            .pipe(finalize(function () { return _this.loadingOrders = false; }))
            .subscribe(function (resp) {
            _this.pagingWrapper = resp;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    return SmilesOrderComponent;
}(AppBaseComponent));
export { SmilesOrderComponent };
