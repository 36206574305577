<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>

<ng-container *ngIf="!loadingState.isLoading()">
  <form class="supporter-search container" (ngSubmit)="onSubmit(supporterForm)" #supporterForm="ngForm" novalidate>
    <h1>Transactions</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="transactionId">Transaction ID</label>
        <input type="text" class="form-control" id="transactionId" name="transactionId"
          placeholder="Enter a transaction ID" [(ngModel)]="model.transactionId" #transactionId="ngModel" />
      </div>

      <div class="col-md-6 mb-3">
        <label for="supporterId">Supporter ID</label>
        <input type="text" class="form-control" id="supporterId" name="supporterId" placeholder="Enter a supporter ID"
          [(ngModel)]="model.supporterId" #supporterId="ngModel" />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3">
        <div class="pull-right action-group">
          <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isRefreshing()">Search</button>
        </div>
      </div>
    </div>

  </form>

  <table class="table table-condensed table-striped" [class.table-loading]="loadingState.isRefreshing()">
    <thead>
      <tr>
        <th>ID</th>
        <th>Guid</th>
        <th>Trigger</th>
        <th>Supporter ID</th>
        <th>Event Code</th>
        <th>File Name</th>
        <th>File Type</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      <tr class="clickable" *ngFor="let item of chosenPhotos"
        [routerLink]="['/chosen-photos', item.id, 'details']">
        <td>{{ item.id }}</td>
        <td>{{ item.guid }}</td>
        <td>{{ item.trigger }}</td>
        <td>{{ item.supporterId }}</td>
        <td>{{ item.eventCode }}</td>
        <td>{{ chosenPhotosService.cleanPhotoFileName(item.fileName) }}</td>
        <td>{{ item.fileType }}</td>
        <td [title]="item.createdDate | date: 'dd MMM yyyy hh:mm:ss a'">
          {{ item.createdDate | date: 'dd MMM yyyy' }}</td>
      </tr>
    </tbody>
  </table>

  <nav class="pull-right inline-block" *ngIf="chosenPhotos">
    <ngb-pagination [(page)]="page" [maxSize]="10" [pageSize]="pageSize" [collectionSize]="total" (pageChange)="pageChange($event)">
    </ngb-pagination>
  </nav>
</ng-container>
