var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';
import { AppBaseComponent, AppBaseComponentService, SupporterChildMessageService } from '@shared';
var SupporterChildMessagesListComponent = /** @class */ (function (_super) {
    __extends(SupporterChildMessagesListComponent, _super);
    function SupporterChildMessagesListComponent(base, route, supporterChildMessageService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.supporterChildMessageService = supporterChildMessageService;
        _this.model = {};
        _this.pageSize = 10;
        _this.page = 1;
        return _this;
    }
    SupporterChildMessagesListComponent.prototype.ngOnInit = function () {
        this.loadingState.setLoading();
        var params = this.route.snapshot.queryParams;
        this.page = params.page ? Number.parseInt(params.page, 10) : 1;
        this.loadPage(this.page, this.pageSize);
    };
    SupporterChildMessagesListComponent.prototype.pageChange = function (pageNumber) {
        this.updateFilters();
        this.loadPage(pageNumber, this.pageSize);
    };
    SupporterChildMessagesListComponent.prototype.onSubmit = function (_form) {
        this.page = 1;
        this.updateFilters();
        this.loadPage(this.page, this.pageSize);
    };
    SupporterChildMessagesListComponent.prototype.export = function () {
        var _this = this;
        if (this.loadingState.isLoaded()) {
            this.loadingState.setRefreshing();
        }
        this.supporterChildMessageService.export(this.model.childId, this.model.supporterId)
            .subscribe(function (resp) {
            _this.loadingState.setLoaded();
            saveAs(resp.body, resp.filename);
        }, function (resp) {
            _this.base.apiErrorHandlerService.handleError(resp);
        });
    };
    SupporterChildMessagesListComponent.prototype.updateFilters = function () {
        this.base.router.navigate([], {
            queryParams: {
                page: this.page
            }
        })
            .catch();
    };
    SupporterChildMessagesListComponent.prototype.loadPage = function (page, pageSize) {
        var _this = this;
        if (this.loadingState.isLoaded()) {
            this.loadingState.setRefreshing();
        }
        this.supporterChildMessageService.find((page - 1) * this.pageSize, pageSize, this.model.childId, this.model.supporterId)
            .subscribe(function (results) {
            _this.loadingState.setLoaded();
            _this.total = results.total;
            _this.messages = results.items;
        });
    };
    return SupporterChildMessagesListComponent;
}(AppBaseComponent));
export { SupporterChildMessagesListComponent };
