import { HttpClient, HttpErrorResponse, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';

import { AuthService, AuthStateService } from '@auth';
import { AppConfig, DialogService } from '@core';

@Injectable()
export class BaseService {
  private static readonly notFoundString = 'Not found';
  private static readonly unknownString = 'Unexpected error occured';
  private static readonly unauthorizedString = 'Unauthroized request';
  private static readonly internalServerErrorString = 'Internal Server Error';
  private static readonly forbiddenErrorString = 'Forbidden action';
  private readonly apiUrlBase: string;

  protected static objectToQueryString(params: any): string {
    // use our own query string builder instead of URLSearchParams because of issues encoding the '+' character.
    let queryString = '';
    for (const key of Object.keys(params)) {
      const value = params[key];

      if (value === undefined || value === null) {
        continue;
      }

      if (queryString.length > 0) {
          queryString += '&';
      }

      queryString += `${String(key)}=${encodeURIComponent(value)}`;
    }

    return queryString;
}

  // API responses contain ISO date strings that need to be converted to Date objects.
  private static mapToDateObjects(object: any): any {
    if (!object) {
      return object;
    }

    for (const attribute of Object.keys(object)) {
      const value = object[attribute];

      if (typeof value === 'object') {
        this.mapToDateObjects(value);
      } else if (typeof value === 'string') {
        // Match ISO date strings.
        if (value.match(/\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d(\.\d+)?([+-][0-2]\d:[0-5]\d|Z)/)) {
          object[attribute] = new Date(value);
        }
      }
    }

    return object;
  }

  constructor(
    private readonly httpClient: HttpClient,
    protected config: AppConfig,
    private readonly router: Router,
    private readonly dialogService: DialogService,
    private readonly authStateService: AuthStateService,
    private readonly authService: AuthService
  ) {
    this.apiUrlBase = `${config.getConfig('api').authority}/api`;
  }

  protected get<ResponseType>(path: string, options?: any): Observable<ResponseType> {
    return this.send(options, o =>
      this.httpClient.get<ResponseType>(`${this.apiUrlBase}/${path}`, o));
  }

  protected patch<ResponseType>(path: string, body: any, options?: any): Observable<ResponseType> {
    return this.send(options, o =>
      this.httpClient.patch<ResponseType>(`${this.apiUrlBase}/${path}`, body, o));
  }

  protected put<ResponseType>(path: string, body: any, options?: any): Observable<ResponseType> {
    return this.send(options, o =>
      this.httpClient.put<ResponseType>(`${this.apiUrlBase}/${path}`, body, o));
  }

  protected post<ResponseType>(path: string, body: any, options?: any): Observable<ResponseType> {
    return this.send(options, o =>
      this.httpClient.post<ResponseType>(`${this.apiUrlBase}/${path}`, body, o));
  }

  protected anonRequestOptions(responseType = 'json'): any {
    return {
      headers:
        new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      withCredentials: false,
      responseType
    };
  }

  protected authRequestOptions(): any {
    return {
      headers:
        new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.authStateService.current.accessToken}`
        }),
      withCredentials: true,
      responseType: 'json'
    };
  }

  private send<ResponseType>(options: any, sendAction: (options: any) =>
    Observable<HttpEvent<ResponseType>>): Observable<ResponseType> {
    let authOptions = options;
    if (!options) {
      if (this.authStateService.current.accessToken) {
        authOptions = this.authRequestOptions();
      } else if (this.authStateService.current.refreshToken) {

        return this.authService.useRefreshToken(this.authStateService.current.refreshToken)
          .pipe(flatMap(oauthToken => this.send(options, sendAction)));
      } else {
        // We need to return something in-case this was called by a resolver
        this.authService.logout();
      }
    }

    return sendAction(authOptions)
      .pipe(
        map(response => BaseService.mapToDateObjects(response))
      );
  }
}
