import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { SignInRedirectResolve } from './sign-in-redirect.resolve';
import { SignInComponent } from './sign-in.component';

const routes: Routes = [
  { path: 'sign-in', component: SignInComponent, resolve: { loginRedirect: SignInRedirectResolve } }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class SignInRoutingModule { }
