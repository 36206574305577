import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, IPagingInfo, ISmilesOrder, PagingWrapper, SmilesService } from '@shared';

@Component({
  selector: 'wv-smiles-order',
  templateUrl: './smiles-order.component.html'
})
export class SmilesOrderComponent extends AppBaseComponent {
  public pagingWrapper: PagingWrapper<ISmilesOrder>;
  public model: any = {};
  public loadingOrders = false;
  public take: number;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly smilesService: SmilesService) {
    super(baseServices);
    const tablePageSizeItems = this.base.appConfig.getConfig('tablePageSizeItems');
    this.take = tablePageSizeItems[0];
  }

  public onSubmit(form: NgForm): boolean {
    if (form.invalid) {

      return false;
    }

    this.loadingOrders = true;
    this.getOrders(0, this.take);

    return true;
  }

  public onPagingChange(paging: IPagingInfo): void {
    if (paging) {
      this.take = paging.take;
      this.getOrders(paging.skip, paging.take);
    }
  }

  public getOrders(skip: number, take: number): void {
    this.smilesService.getSmilesOrdersBySearchTerm(this.model.searchTerm, take, skip)
      .pipe(finalize(
        () => this.loadingOrders = false))
      .subscribe(resp => {
        this.pagingWrapper = resp;
      }, resp => {
        this.apiErrorHandlerService.handleError(resp);
      });
  }
}
