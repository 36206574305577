import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppBaseComponent, AppBaseComponentService, ISupporterChildMessage, SupporterChildMessageService } from '@shared';

@Component({
  selector: 'wv-supporter-child-message-details',
  templateUrl: './supporter-child-message-details.component.html'
})
export class SupporterChildMessageDetailsComponent extends AppBaseComponent implements OnInit {
  public message: ISupporterChildMessage;

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly supporterChildMessageService: SupporterChildMessageService
  ) {
    super(base);
  }

  public ngOnInit(): void {
    this.loadingState.setLoading();

    this.supporterChildMessageService.getById(this.route.snapshot.params.id)
      .subscribe(message => {
        this.loadingState.setLoaded();
        this.message = message;
      });
  }

}
