import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthStateService } from '@auth';
import { AppConfig, BaseComponent, DialogService, ModalService } from '@core';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { AppBaseComponentService } from './app-base-component.service';
import { ValidationRulesService } from './validation-rules.service';

export abstract class AppBaseComponent extends BaseComponent {
  public isAuthenticated = false;
  public appConfig: AppConfig;
  public authStateService: AuthStateService;
  public dialogService: DialogService;
  public validationRules: ValidationRulesService;
  public modalService: ModalService;
  public apiErrorHandlerService: ApiErrorHandlerService;

  constructor(
    public base: AppBaseComponentService
  ) {
    super(base);

    this.appConfig = base.appConfig;
    this.authStateService = base.authStateService;
    this.validationRules = base.validationRules;
    this.isAuthenticated = base.authStateService.current.isAuthenticated;
    this.modalService = base.modalService;
    this.dialogService = base.dialogService;
    this.apiErrorHandlerService = base.apiErrorHandlerService;
    super.addSubscription(
      this.authStateService.stateChanged$
        .subscribe(authenticated => {
          this.isAuthenticated = authenticated;
        }));
  }
}
