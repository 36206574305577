import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from '@auth';

import { SmilesGiftDetailsComponent } from './smiles-gift/smiles-gift-details.component';
import { SmilesGiftsComponent } from './smiles-gift/smiles-gifts.component';
import { SmilesOrderComponent } from './smiles-order/smiles-order.component';
import { SupporterChildMessageDetailsComponent } from './supporter-child-message-details/supporter-child-message-details.component';
import { SupporterChildMessagesListComponent } from './supporter-child-messages-list/supporter-child-messages-list.component';
import { SupporterDetailsComponent } from './supporter-details.component';
import { SupportersComponent } from './supporters.component';
import { UserComponent } from './user/user.component';

const routes: Routes = [
  { path: 'supporters', component: SupportersComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'supporters/:id', component: SupporterDetailsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'supporter-child-messages', component: SupporterChildMessagesListComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'supporter-child-messages/:id', component: SupporterChildMessageDetailsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'users/:id', component: UserComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'smiles-orders', component: SmilesOrderComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'smiles-orders/:id/smiles-gifts', component: SmilesGiftsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'smiles-gifts/:id', component: SmilesGiftDetailsComponent, canActivate: [CanActivateAuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class SupportersRoutingModule { }
