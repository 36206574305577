import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AuthState } from '@auth';
import { AppBaseComponent, AppBaseComponentService, IUser, UserService } from '@shared';
import { ChangeSupporterModalComponent } from './change-supporter-modal.component';

@Component({
  selector: 'wv-user',
  templateUrl: './user.component.html'
})
export class UserComponent extends AppBaseComponent implements OnInit {
  public user: IUser;
  public isPerformingAction = false;
  private userId: number;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly userService: UserService,
    private readonly route: ActivatedRoute) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params.id;
    this.getUserDetails();
  }

  public impersonateUser(): void {
    this.isPerformingAction = true;
    this.base.authService.ensureAuthentication()
      .pipe(
        finalize(() => this.isPerformingAction = false))
      .subscribe(
        () => {
          this.userService.impersonateUser(this.user.primarySupporterId)
            .subscribe(() => {
            const myWvUrl = this.appConfig.getConfig('donationFunnel').authority;
            window.open(`${myWvUrl}/sign-in?action=impersonate`,
              '_blank');
          });
        },
        async () => this.base.router.navigate(['sign-in'], {
          queryParams: { returnUrl: this.base.router.routerState.snapshot.url }
        })
      );
  }

  public changeSupporter(): void {
    this.base.modalService.create(ChangeSupporterModalComponent, {
      userId: this.userId,
      onSuccess: () => { this.getUserDetails(); }
    });
  }

  private getUserDetails(): void {
    this.loadingState.setLoading();
    this.userService.getUser(this.userId)
      .pipe(
        finalize(() => { this.loadingState.setLoaded(); }))
      .subscribe(
        resp => {
          this.user = resp;
        },
        resp => {
          this.apiErrorHandlerService.handleError(resp);
        });
  }
}
