import { EmailPreviewOperations } from '@shared';
import { IEmailPreviewRoute } from './email-preview-route';

export class EmailPreviewRouteMap {
  public routes: Array<IEmailPreviewRoute> = [
    {
      path: 'activateUser',
      name: 'Activate User',
      previewType: EmailPreviewOperations.ActivateUser
    },
    {
      path: 'chosenCampaignConfirmation',
      name: 'Chosen Campaign Confirmation',
      previewType: EmailPreviewOperations.ChosenCampaignConfirmation
    },
    {
      path: 'contactChild',
      name: 'Contact Child',
      previewType: EmailPreviewOperations.ContactChild
    },
    {
      path: 'contentModerationNotification',
      name: 'Content Moderation Notification',
      previewType: EmailPreviewOperations.ContentModerationNotification
    },
    {
      path: 'donationThankYou',
      name: 'Donation Thank You',
      child: [
        {
          path: 'campaign',
          name: 'Campaign',
          previewType: EmailPreviewOperations.DonationThankYouCampaign,
          child: [
            {
              path: 'no-location-no-cause',
              name: 'No location, no cause',
              data: {
                causeName: '',
                locationName: '',
                statement: ''
              }
            },
            {
              path: 'has-location-and-cause',
              name: 'Has location and cause',
              data: {
                causeName: 'Water',
                locationName: 'Asia',
                statement: ''
              }
            },
            {
              path: 'has-location',
              name: 'Has location only',
              data: {
                causeName: '',
                locationName: 'Asia',
                statement: ''
              }
            },
            {
              path: 'has-cause',
              name: 'Has cause only',
              data: {
                causeName: 'Water',
                locationName: '',
                statement: ''
              }
            },
            {
              path: 'has-statement',
              name: 'Has campaign statement',
              data: {
                causeName: '',
                locationName: '',
                statement: 'Test statement'
              }
            }
          ]
        },
        {
          path: 'child-sponsorship',
          name: 'Child Sponsorship',
          previewType: EmailPreviewOperations.DonationThankYouCspon,
          child: [
            {
              path: 'recurring',
              name: 'Recurring',
              data: {
                isOneOff: false
              }
            },
            {
              path: 'one-off',
              name: 'One Off',
              data: {
                isOneOff: true
              }
            }]
        },
        {
          path: 'fundraising',
          name: 'Fundraising',
          previewType: EmailPreviewOperations.DonationThankYouFundraising,
          data: {
            statement: 'supports World Vision\'s fundraising work'
          }
        },
        {
          path: 'chosen',
          name: 'Chosen',
          previewType: EmailPreviewOperations.DonationThankYouChosen,
          child: [
            {
              path: 'single',
              name: 'One Child',
              data: {
                quantity: 1
              }
            },
            {
              path: 'multiple',
              name: 'Multiple Children',
              data: {
                quantity: 3
              }
            }]
        },
        {
          path: 'smiles-ecard',
          name: 'Smiles ECard',
          previewType: EmailPreviewOperations.DonationThankYouSmilesECard
        },
        {
          path: 'smiles-no-card',
          name: 'Smiles No Card',
          previewType: EmailPreviewOperations.DonationThankYouSmilesNoCard
        },
        {
          path: 'smiles-printed-card',
          name: 'Smiles Printed Card',
          previewType: EmailPreviewOperations.DonationThankYouSmilesPrintedCard
        }
      ]
    },
    {
      path: 'fundraisingFundraiserInvitation',
      name: 'Fundraising Fundraiser Invitation',
      previewType: EmailPreviewOperations.FundraisingFundraiserInvitation
    },
    {
      path: 'fundraisingGuardianNotification',
      name: 'Fundraising Guardian Notification',
      previewType: EmailPreviewOperations.FundraisingGuardianNotification
    },
    {
      path: 'fundraisingManagerInvitation',
      name: 'Fundraising Manager Invitation',
      previewType: EmailPreviewOperations.FundraisingManagerInvitation
    },
    {
      path: 'fundraisingManagerNotification',
      name: 'Fundraising Manager Notification',
      previewType: EmailPreviewOperations.FundraisingManagerNotification
    },
    {
      path: 'fundraisingSupporterInvitation',
      name: 'Fundraising Supporter Invitation',
      previewType: EmailPreviewOperations.FundraisingSupporterInvitation
    },
    {
      path: 'linkFacebookAccount',
      name: 'Link Facebook Account',
      previewType: EmailPreviewOperations.LinkFacebookAccount
    },
    {
      path: 'resetPasswordRequest',
      name: 'Reset Password Request',
      previewType: EmailPreviewOperations.ResetPasswordRequest
    },
    {
      path: 'smilesECard',
      name: 'Smiles ECard',
      previewType: EmailPreviewOperations.SmilesECard
    }
  ];
}
