import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, IFundraisingTeamTypeCode, TeamsService } from '@shared';

@Component({
  selector: 'wv-new-team',
  templateUrl: './new-team.component.html'
})
export class FundraisingNewTeamComponent extends AppBaseComponent implements OnInit {
  @Input()
  public isLoadingExistingTeam = false;
  public model: any = {};
  public isPerformingAction = false;
  public teamTypeCodes: Array<IFundraisingTeamTypeCode>;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly teamsService: TeamsService) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.setTeamTypeCodes();
  }

  public onSubmit(form: NgForm): any {
    if (form.invalid) {
      return false;
    }

    this.isPerformingAction = true;
    this.teamsService.createTeam(this.model)
        .pipe(finalize(
            () => this.isPerformingAction = false))
        .subscribe(
          async resp => this.base.router.navigate(['/fundraising/teams/', resp.id]),
          resp => {
                this.apiErrorHandlerService.handleError(resp);
            });

    return true;
  }

  private setTeamTypeCodes(): void {
    this.loadingState.setLoading();
    this.teamsService.getTeamTypeCodes()
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(
        resp => {
          this.teamTypeCodes = resp;
        },
        resp => {
          this.apiErrorHandlerService.handleError(resp);
        });
  }
}
