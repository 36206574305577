export enum EmailPreviewOperations {
  Unknown,
  ActivateUser,
  ChosenCampaignConfirmation,
  ContactChild,
  ContentModerationNotification,
  DonationThankYouCampaign,
  DonationThankYouCspon,
  DonationThankYouChosen,
  DonationThankYouFundraising,
  DonationThankYouSmilesECard,
  DonationThankYouSmilesNoCard,
  DonationThankYouSmilesPrintedCard,
  FundraisingGuardianNotification,
  FundraisingManagerInvitation,
  FundraisingManagerNotification,
  FundraisingFundraiserInvitation,
  FundraisingSupporterInvitation,
  LinkFacebookAccount,
  ResetPasswordRequest,
  SmilesECard
}
