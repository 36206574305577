<div class="smiles-orders container">
  <h1>Smiles Orders</h1>
  <hr class="heading-hr" align="left" />
  <div class="supporter-search">
    <form (ngSubmit)="onSubmit(orderForm)" #orderForm="ngForm" novalidate>
      <div class="form-row">
        <div class="col-md-8 mb-3">
          <label for="searchTerm">Enter Supporter ID or Recipient/Sender email</label>
          <input type="text" class="form-control"
                 id="searchTerm"
                 name="searchTerm"
                 required
                 [(ngModel)]="model.searchTerm"
                 #searchTerm="ngModel" />
        </div>
        <wv-field-helper id="searchTermHelper" [control]="searchTerm"
                         [errorMessages]="{'required': 'Please enter a search term'}">
        </wv-field-helper>
        <div class="col-md-8 mb-3">
          <div class="pull-right action-group">
            <button class="btn btn-p pull-right" type="submit" [ladda]="loadingOrders">View Orders</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <wv-smiles-order-list *ngIf="pagingWrapper"
                        [pagingWrapper]="pagingWrapper"
                        (paging)="onPagingChange($event)">
  </wv-smiles-order-list>
</div>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
