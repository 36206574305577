import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from '@auth';

import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';

const routes: Routes = [
  { path: 'transactions', component: TransactionListComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'transactions/:id/details', component: TransactionDetailsComponent, canActivate: [CanActivateAuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class TransactionsRoutingModule { }
