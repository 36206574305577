import { Component } from '@angular/core';

import { AppBaseComponent, AppBaseComponentService } from '@shared';

@Component({
  selector: 'wv-new-team-tabs',
  templateUrl: './new-team-tabs.component.html'
})
export class FundraisingNewTeamTabsComponent extends AppBaseComponent {

  constructor(
    baseServices: AppBaseComponentService) {
    super(baseServices);
  }
}
