import { Injectable } from '@angular/core';

import { EmailPreviewOperations } from '@shared';
import { IEmailPreviewRoute } from '../models/email-preview-route';
import { EmailPreviewRouteMap } from '../models/email-preview-route-map';
import { IEmailPreview } from './email-preview';

@Injectable()
export class EmailPreviewService {

  public loadSelectedTypePreview(type: string, subType: string, variant: string): IEmailPreview {
    const routes = this.loadAllRoutes();

    const selectedTypeRoute = routes.find(x => x.path === type);

    if (!selectedTypeRoute) {
      return undefined;
    }

    const subTypes = selectedTypeRoute.child;
    const preview: IEmailPreview = {
      type: selectedTypeRoute,
      typeName: EmailPreviewOperations[selectedTypeRoute.previewType ? selectedTypeRoute.previewType : '']
    };

    if (subTypes) {
      const selectedSubType = subType ? subTypes.find(x => x.path === subType) : subTypes[0];
      preview.subType = selectedSubType;
      preview.typeName = EmailPreviewOperations[selectedSubType.previewType];
      preview.data = selectedSubType.data;
      const variants = selectedSubType.child;

      if (variants) {
        const selectedVariant = variant ? variants.find(x => x.path === variant) : variants[0];
        preview.variant = selectedVariant;
        preview.data = selectedVariant.data;
      }
    }

    return preview;
  }

  public loadAllRoutes(): Array<IEmailPreviewRoute> {
    const routeMap = new EmailPreviewRouteMap();

    return routeMap.routes;
  }
}
