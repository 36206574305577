import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

@Injectable()
export class AppConfig {
  private config: Object = undefined;
  private root = 'config';
  private env = '';

  constructor(
    private readonly http: HttpClient
  ) { }

  /**
   * Retrieve a config value
   */
  public getConfig(key: any): any {
    return this.config[key];
  }

  /**
   * This method:
   *   a) Loads "config.json" to get the current working environment
   *   b) If "environment" property of response is "development", loads "config.local.json" to get all local overrides
   */
  public async load(windowOverride?: string): Promise<any> {
    this.initConfig(windowOverride);
    await this.initEnv();

    return new Promise((resolve, reject) => {

      this.http.get<any>(`${this.root}/config${this.env}.json?t=${new Date().getTime()}`)
        .pipe(
          flatMap(res => {

            this.config = res;
            const environment = res.environment;

            if (environment !== 'development') {

              return of(res);
            }

            return this.http
              .get(`${this.root}/config.local.json?t=${new Date().getTime()}`)
              .pipe(
                flatMap(localRes => {

                  // Merge the overrides
                  this.config = { ...this.config, ...localRes };

                  return of(true);
                }),
                catchError((error: any) =>
                  of(true)));
          }),
          catchError((error: any): any => {
            resolve(true);

            return throwError('Server error');
          }))
        .subscribe((res: any) => {
          resolve(true);
        });
    });
  }

  private initConfig(windowOverride?: string): void {

    // Content app: allow caller website to override root
    if (windowOverride) {
      if (windowOverride === 'pageRoot' && window.location.host !== 'localhost:3000') {

        // tslint:disable-next-line:prefer-template
        this.root = window.location.origin + '/dist/config';

        return;
      }
      const appAuthority = window[windowOverride];
      if (appAuthority) {
        this.root = `${appAuthority}/config`;

        return;
      }
    }

    // Default: MyWV, all environments
  }

  private async initEnv(): Promise<void> {

    const requestOptions: Object = {
      responseType: 'text'
    };

    // tslint:disable-next-line:newline-per-chained-call
    await this.http.get<string>(`${window.location.origin}/angularConfig`, requestOptions).toPromise().then(res => {
      // tslint:disable-next-line:max-line-length
      if (res === 'UAT' || res === 'Stage' || res === 'Production' || res === 'Staging' || res === 'Development' || res === 'production' || res === 'Local' || res === 'Dwayne' || res === 'UATTestStripe') {
        this.env = `.${res}`;
      }
    });
  }
}
