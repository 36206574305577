var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBaseComponent, AppBaseComponentService, TransactionService } from '@shared';
import { forkJoin } from 'rxjs';
var TransactionDetailsComponent = /** @class */ (function (_super) {
    __extends(TransactionDetailsComponent, _super);
    function TransactionDetailsComponent(base, route, transactionService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.transactionService = transactionService;
        return _this;
    }
    TransactionDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingState.setLoading();
        forkJoin(this.transactionService.getById(this.route.snapshot.params.id), this.transactionService.getCommitmentsByTransactionId(this.route.snapshot.params.id))
            .subscribe(function (_a) {
            var transaction = _a[0], commitments = _a[1];
            _this.loadingState.setLoaded();
            _this.transaction = transaction;
            _this.commitments = commitments;
        });
    };
    return TransactionDetailsComponent;
}(AppBaseComponent));
export { TransactionDetailsComponent };
