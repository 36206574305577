<form class="supporter-search container" (ngSubmit)="onSubmit(supporterForm)" #supporterForm="ngForm" novalidate>
  <h1>Search a supporter</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <label for="supporterId">Supporter ID</label>
      <input type="text" class="form-control"
             id="supporterId"
             name="supporterId"
             required
             placeholder="Enter a supporter ID"
             [(ngModel)]="model.supporterId"
             #supporterId="ngModel" />
    </div>
    <wv-field-helper id="supporterIdHelper" [control]="supporterId"
                     [errorMessages]="{'required': 'Please enter a supporter ID'}">
    </wv-field-helper>
    <div class="col-md-8 mb-3">
      <div class="pull-right action-group">
        <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isLoading()">Search</button>
      </div>
    </div>
  </div>

</form>
