var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { finalize, switchMap } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, UserService } from '@shared';
var ChangeSupporterModalComponent = /** @class */ (function (_super) {
    __extends(ChangeSupporterModalComponent, _super);
    function ChangeSupporterModalComponent(baseServices, userService) {
        var _this = _super.call(this, baseServices) || this;
        _this.userService = userService;
        _this.model = {};
        _this.isLoading = false;
        _this.modalContainerOptions = {
            overlayClass: '',
            modalClass: 'modal-dialog'
        };
        return _this;
    }
    ChangeSupporterModalComponent.prototype.onSubmit = function (form) {
        var _this = this;
        if (form.invalid) {
            return;
        }
        this.isLoading = true;
        this.base.authService.ensureAuthentication()
            .pipe(switchMap(function () { return _this.userService.setSupporterId(_this.userId, parseInt(_this.model.newSupporterId, 10)); }), finalize(function () {
            _this.isLoading = false;
            _this.close();
        }))
            .subscribe(function () { return _this.onSuccess(); }, function (resp) { return _this.apiErrorHandlerService.handleError(resp); });
    };
    return ChangeSupporterModalComponent;
}(AppBaseComponent));
export { ChangeSupporterModalComponent };
