import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICreateEchoTeamProfile } from '../../models/create-echo-team-profile';
import { FundraisingProfile } from '../../models/fundraising-profile';
import { IFundraisingProfileSearchResult } from '../../models/fundraising-profile-search-result';
import { BaseService } from '../base.service';

@Injectable()
export class ProfilesService extends BaseService {

  public getProfile(id: string): Observable<FundraisingProfile> {
    return this.get<FundraisingProfile>(`admin/fundraising/profiles/${id}`);
  }

  public reinstateProfile(id: string): Observable<any> {
    const operation = {
      operation: 'reinstateProfile',
      data: {}
    };

    return this.patch<any>(`admin/fundraising/profiles/${id}`, operation);
  }

  public searchFundraisingProfiles(searchTerm: string): Observable<IFundraisingProfileSearchResult> {
    return this.get<IFundraisingProfileSearchResult>(`admin/fundraising/profiles?searchTerm=${searchTerm}&limit=10`);
  }

  public createEchoTeamProfile(teamId: string, campaignCode: string, commitmentId: string): Observable<any> {
    const model: ICreateEchoTeamProfile = {
      campaignCode,
      teamId,
      commitmentId
    };

    return this.post<any>('admin/fundraising/profiles', model);
  }
}
