var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, ProfilesService } from '@shared';
var FundraisingSearchProfilesComponent = /** @class */ (function (_super) {
    __extends(FundraisingSearchProfilesComponent, _super);
    function FundraisingSearchProfilesComponent(baseServices, profilesService) {
        var _this = _super.call(this, baseServices) || this;
        _this.profilesService = profilesService;
        _this.profiles = [];
        _this.profileSearching = true;
        _this.searchTerm$ = new Subject();
        _this.searchStatus = undefined;
        _this.model = {};
        _this.isLoading = false;
        _this.minimumSearchCharacters = 3;
        return _this;
    }
    FundraisingSearchProfilesComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchTerm$.pipe(debounceTime(300), distinctUntilChanged(), switchMap(function (term) {
            _this.searchStatus = 'Searching...';
            if (term.length < _this.minimumSearchCharacters) {
                _this.searchStatus = "Please enter " + _this.minimumSearchCharacters + " or more characters to search";
                return of(false);
            }
            return _this.profilesService.searchFundraisingProfiles(term);
        }))
            .subscribe(function (result) {
            if (result === false) {
                _this.profiles = [];
                return;
            }
            var profiles = result;
            _this.searchStatus = profiles.length === 0 ? 'There is no matching profile.' : 'Were you looking for:';
            _this.profiles = profiles;
        });
    };
    FundraisingSearchProfilesComponent.prototype.changeProfileSearchTerm = function (searchTerm) {
        this.profileSearchTerm = searchTerm;
        this.searchTerm$.next(searchTerm);
    };
    FundraisingSearchProfilesComponent.prototype.selectMatchedProfile = function (profileMatch) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.base.router.navigate(['/fundraising/profiles/', profileMatch.id])];
            });
        });
    };
    return FundraisingSearchProfilesComponent;
}(AppBaseComponent));
export { FundraisingSearchProfilesComponent };
