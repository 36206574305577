import { DOCUMENT } from '@angular/common';
import {
  Component, ComponentFactoryResolver, ComponentRef,
  ElementRef, Inject, Input, Renderer2, RendererFactory2, Type
} from '@angular/core';
import { Subject } from 'rxjs';

import { DynamicContainerComponent } from '../dynamic/dynamic-container.component';
import { BaseComponentService } from '../services/base-component.service';
import { IModalContent } from './modal-content';

@Component({
  templateUrl: 'modal-container.component.html'
})
export class ModalContainerComponent extends DynamicContainerComponent {

  @Input()
  public destroy: () => void;

  @Input()
  public options: any;

  private readonly renderer: Renderer2;

  constructor(
    baseServices: BaseComponentService,
    componentFactoryResolver: ComponentFactoryResolver,
    private readonly elementRef: ElementRef,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly rendererFactory: RendererFactory2
  ) {
    super(baseServices, componentFactoryResolver);
    this.renderer = rendererFactory.createRenderer(undefined, undefined);

    const body = this.document.body;
    this.renderer.addClass(body, 'modal-open');

    setTimeout(
      () => {
        this.elementRef.nativeElement.getElementsByClassName('modal-backdrop')[0]
          .classList
          .add('show');
        this.elementRef.nativeElement.getElementsByClassName('modal')[0]
          .classList
          .add('show');
      }, 0);
  }

  public initModal(
    contentComponentType: Type<IModalContent>,
    subject: Subject<any>, parameters?: Object): ComponentRef<IModalContent> {
    const modalContent = super.init(contentComponentType, parameters);

    modalContent.instance.close = result => {
      this.close();
      subject.next(result);
    };

    return modalContent;
  }

  public onBackDropClick(event): void {
    if (event.target === event.currentTarget) {
      this.close();
    }
  }

  public close(): void {
    const body = this.document.body;
    this.renderer.removeClass(body, 'modal-open');
    this.elementRef.nativeElement.getElementsByClassName('modal-backdrop')[0]
      .classList
      .remove('show');
    this.elementRef.nativeElement.getElementsByClassName('modal')[0]
      .classList
      .remove('show');
    setTimeout(
      () => {
        this.destroy();
      }, 250); // Allow the modal to fade
  }
}
