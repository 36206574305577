/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
import * as i2 from "angular2-ladda/module/ladda.directive";
import * as i3 from "angular2-ladda/module/ladda-config";
import * as i4 from "./sign-in.component";
import * as i5 from "../shared/app-base-component.service";
import * as i6 from "./sign-in-action.service";
import * as i7 from "@angular/router";
import * as i8 from "../shared/browser-storage.service";
var styles_SignInComponent = [];
var RenderType_SignInComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SignInComponent, data: {} });
export { RenderType_SignInComponent as RenderType_SignInComponent };
export function View_SignInComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "form", [["class", "needs-validation"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngSubmit"], [null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("ngSubmit" === en)) {
        var pd_2 = (_co.onSubmit(i0.ɵnov(_v, 2)) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 4210688, [["loginForm", 4]], 0, i1.NgForm, [[8, null], [8, null]], null, { ngSubmit: "ngSubmit" }), i0.ɵprd(2048, null, i1.ControlContainer, null, [i1.NgForm]), i0.ɵdid(4, 16384, null, 0, i1.NgControlStatusGroup, [[4, i1.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 6, "div", [["class", "dashboard-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(6, 0, null, null, 5, "div", [["class", "sign-in"]], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Sign in to World Vision Admin Portal"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "button", [["class", "btn btn-p"], ["data-spinner-color", "#ffffff"], ["id", "dropDownLogin"], ["type", "submit"]], null, null, null, null, null)), i0.ɵdid(10, 737280, null, 0, i2.LaddaDirective, [i0.ElementRef, [2, i3.LaddaConfig]], { loading: [0, "loading"] }, null), (_l()(), i0.ɵted(-1, null, [" Sign in "]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.signingIn; _ck(_v, 10, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_SignInComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-sign-in", [], null, null, null, View_SignInComponent_0, RenderType_SignInComponent)), i0.ɵdid(1, 245760, null, 0, i4.SignInComponent, [i5.AppBaseComponentService, i6.SignInActionService, i7.ActivatedRoute, i8.BrowserStorageService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SignInComponentNgFactory = i0.ɵccf("wv-sign-in", i4.SignInComponent, View_SignInComponent_Host_0, {}, {}, []);
export { SignInComponentNgFactory as SignInComponentNgFactory };
