<div class="d-inline-block" *ngIf="lastViewedSupporters && lastViewedSupporters.length" ngbDropdown>
  <button class="btn btn-light" ngbDropdownToggle>
    Last viewed Supporters
  </button>
  <div ngbDropdownMenu>
    <button class="dropdown-item"
            *ngFor="let supporter of lastViewedSupporters"
            [routerLink]="['supporters', supporter.id]">
      {{ supporter.id }}: {{ supporter.firstName }} {{ supporter.lastName }}
    </button>
  </div>
</div>
