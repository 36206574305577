var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';
var AppConfig = /** @class */ (function () {
    function AppConfig(http) {
        this.http = http;
        this.config = undefined;
        this.root = 'config';
        this.env = '';
    }
    /**
     * Retrieve a config value
     */
    AppConfig.prototype.getConfig = function (key) {
        return this.config[key];
    };
    /**
     * This method:
     *   a) Loads "config.json" to get the current working environment
     *   b) If "environment" property of response is "development", loads "config.local.json" to get all local overrides
     */
    AppConfig.prototype.load = function (windowOverride) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.initConfig(windowOverride);
                        return [4 /*yield*/, this.initEnv()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/, new Promise(function (resolve, reject) {
                                _this.http.get(_this.root + "/config" + _this.env + ".json?t=" + new Date().getTime())
                                    .pipe(flatMap(function (res) {
                                    _this.config = res;
                                    var environment = res.environment;
                                    if (environment !== 'development') {
                                        return of(res);
                                    }
                                    return _this.http
                                        .get(_this.root + "/config.local.json?t=" + new Date().getTime())
                                        .pipe(flatMap(function (localRes) {
                                        // Merge the overrides
                                        _this.config = __assign({}, _this.config, localRes);
                                        return of(true);
                                    }), catchError(function (error) {
                                        return of(true);
                                    }));
                                }), catchError(function (error) {
                                    resolve(true);
                                    return throwError('Server error');
                                }))
                                    .subscribe(function (res) {
                                    resolve(true);
                                });
                            })];
                }
            });
        });
    };
    AppConfig.prototype.initConfig = function (windowOverride) {
        // Content app: allow caller website to override root
        if (windowOverride) {
            if (windowOverride === 'pageRoot' && window.location.host !== 'localhost:3000') {
                // tslint:disable-next-line:prefer-template
                this.root = window.location.origin + '/dist/config';
                return;
            }
            var appAuthority = window[windowOverride];
            if (appAuthority) {
                this.root = appAuthority + "/config";
                return;
            }
        }
        // Default: MyWV, all environments
    };
    AppConfig.prototype.initEnv = function () {
        return __awaiter(this, void 0, void 0, function () {
            var requestOptions;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        requestOptions = {
                            responseType: 'text'
                        };
                        // tslint:disable-next-line:newline-per-chained-call
                        return [4 /*yield*/, this.http.get(window.location.origin + "/angularConfig", requestOptions).toPromise().then(function (res) {
                                // tslint:disable-next-line:max-line-length
                                if (res === 'UAT' || res === 'Stage' || res === 'Production' || res === 'Staging' || res === 'Development' || res === 'production' || res === 'Local' || res === 'Dwayne' || res === 'UATTestStripe') {
                                    _this.env = "." + res;
                                }
                            })];
                    case 1:
                        // tslint:disable-next-line:newline-per-chained-call
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return AppConfig;
}());
export { AppConfig };
