import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';

import { AuthService } from '@auth';
import { DialogService } from '@core';

@Injectable()
export class ApiErrorHandlerService {
  private static readonly notFoundString = 'Not found';
  private static readonly unknownString = 'Unexpected error occured';
  private static readonly unauthorizedString = 'Unauthroized request';
  private static readonly internalServerErrorString = 'Internal server error';
  private static readonly forbiddenErrorString = 'Forbidden action';
  private static readonly badRequest = 'Bad request';

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly dialogService: DialogService
  ) { }

  public handleError(response: any, errorMessage?: string): Observable<any> {
    if (errorMessage) {
      return this.dialogService.showError('Error', errorMessage);
    }

    switch (response.status) {
      case 400:
        const error = response.error;
        if (error && error.reason) {
          const message = error.errors
            ? error.errors.reduce(
              (accumulator: string, currentValue) => `${accumulator}${currentValue.message}  `, '')
            : error.description;

          return this.dialogService.showError('Error', message);
        }

        return this.dialogService.showError('Error', ApiErrorHandlerService.badRequest);
      case 401:
        this.authService.logout();
        break;
      case 403:
        return this.dialogService.showError('Error', ApiErrorHandlerService.forbiddenErrorString);
      case 404:
        return this.dialogService.showError('Error', ApiErrorHandlerService.notFoundString);
      case 500:
        return this.dialogService.showError('Error', ApiErrorHandlerService.internalServerErrorString);
      default:
        return this.dialogService.showError('Error', ApiErrorHandlerService.unknownString);
    }
  }
}
