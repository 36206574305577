import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthIdentity } from './oauth-identity';
import { OAuthTokenResponse } from './oauth-token-reponse';
var AuthState = /** @class */ (function () {
    function AuthState(cookieService, authStateService, router) {
        this.cookieService = cookieService;
        this.authStateService = authStateService;
        this.router = router;
        this.jwtHelper = undefined;
        this.jwtHelper = new JwtHelperService();
    }
    Object.defineProperty(AuthState.prototype, "accessToken", {
        get: function () {
            return this.cookieService.get(this.authStateService.accessTokenName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthState.prototype, "refreshToken", {
        get: function () {
            return this.cookieService.get(this.authStateService.refreshTokenName);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthState.prototype, "identity", {
        get: function () {
            var accessToken = this.accessToken;
            var refreshToken = this.refreshToken;
            if (!accessToken) {
                return undefined;
            }
            var claims = this.jwtHelper.decodeToken(accessToken);
            var identity = new OAuthIdentity(new OAuthTokenResponse(accessToken, refreshToken, 0), claims);
            return identity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthState.prototype, "isAuthenticated", {
        get: function () {
            return (this.identity && this.identity.claims && this.identity.claims.role === AuthState.adminRoleClaim);
        },
        enumerable: true,
        configurable: true
    });
    AuthState.adminRoleClaim = 'Supporter Admin';
    return AuthState;
}());
export { AuthState };
