/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@ng-bootstrap/ng-bootstrap";
import * as i3 from "@angular/common";
import * as i4 from "./last-viewed-supporters.component";
import * as i5 from "../../shared/last-viewed-supporters.service";
var styles_LastViewedSupportersComponent = [];
var RenderType_LastViewedSupportersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LastViewedSupportersComponent, data: {} });
export { RenderType_LastViewedSupportersComponent as RenderType_LastViewedSupportersComponent };
function View_LastViewedSupportersComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "button", [["class", "dropdown-item"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵted(3, null, [" ", ": ", " ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "supporters", _v.context.$implicit.id); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.id; var currVal_2 = _v.context.$implicit.firstName; var currVal_3 = _v.context.$implicit.lastName; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }); }
function View_LastViewedSupportersComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "d-inline-block"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i0.ɵdid(1, 212992, null, 2, i2.NgbDropdown, [i0.ChangeDetectorRef, i2.NgbDropdownConfig, i3.DOCUMENT, i0.NgZone, i2.ɵz], null, null), i0.ɵqud(335544320, 1, { _menu: 0 }), i0.ɵqud(335544320, 2, { _anchor: 0 }), (_l()(), i0.ɵeld(4, 0, null, null, 3, "button", [["aria-haspopup", "true"], ["class", "btn btn-light dropdown-toggle"], ["ngbDropdownToggle", ""]], [[1, "aria-expanded", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).toggleOpen() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i2.ɵr, [i2.NgbDropdown, i0.ElementRef], null, null), i0.ɵprd(2048, [[2, 4]], i2.ɵq, null, [i2.ɵr]), (_l()(), i0.ɵted(-1, null, [" Last viewed Supporters "])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], null, null, null, null)), i0.ɵdid(9, 16384, [[1, 4]], 0, i2.ɵp, [i2.NgbDropdown, i0.ElementRef, i0.Renderer2], null, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_LastViewedSupportersComponent_2)), i0.ɵdid(11, 278528, null, 0, i3.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_5 = _co.lastViewedSupporters; _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 1).isOpen(); _ck(_v, 0, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 5).dropdown.isOpen(); _ck(_v, 4, 0, currVal_1); var currVal_2 = true; var currVal_3 = i0.ɵnov(_v, 9).dropdown.isOpen(); var currVal_4 = i0.ɵnov(_v, 9).placement; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_LastViewedSupportersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LastViewedSupportersComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.lastViewedSupporters && _co.lastViewedSupporters.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LastViewedSupportersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-last-viewed-supporters", [], null, null, null, View_LastViewedSupportersComponent_0, RenderType_LastViewedSupportersComponent)), i0.ɵdid(1, 114688, null, 0, i4.LastViewedSupportersComponent, [i5.LastViewedSupportersService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LastViewedSupportersComponentNgFactory = i0.ɵccf("wv-last-viewed-supporters", i4.LastViewedSupportersComponent, View_LastViewedSupportersComponent_Host_0, {}, {}, []);
export { LastViewedSupportersComponentNgFactory as LastViewedSupportersComponentNgFactory };
