<form *ngIf="transaction" class="container">
  <h1>Transaction Details</h1>
  <hr align="left" />

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Transaction ID</label>
      <input type="text" class="form-control" [value]="transaction.id" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Supporter ID</label>
      <input type="text" class="form-control" [value]="transaction.supporterId" disabled />
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Transaction Type</label>
      <input type="text" class="form-control" [value]="transaction.transactionType" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Product Type</label>
      <input type="text" class="form-control" [value]="transaction.productType" disabled />
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Amount</label>
      <input type="text" class="form-control" [value]="transaction.amount" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Status</label>
      <br />
      <wv-transaction-status-badge [status]="transaction.status"></wv-transaction-status-badge>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Date</label>
      <input type="text" class="form-control" [value]="transaction.dateTimeStamp | date:'dd MMMM yyyy HH:mm:ss'"
        disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Channel</label>
      <br />
      <input type="text" class="form-control" [value]="transaction.echoChannel" disabled />
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Recurring</label>
      <input type="text" class="form-control" [value]="transaction.recurring ? 'Yes' : 'No'" disabled />
    </div>
  </div>

  <hr />

  <ng-container *ngIf="transaction.productType === 'Chosen'">
    <h3>Chosen Details</h3>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Chosen Campaign Code</label>
        <input type="text" class="form-control" [value]="transaction.chosenCampaignCode" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label>Quantity</label>
        <input type="text" class="form-control" [value]="transaction.quantity" disabled />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Chosen Photo File Upload</label>
        <input type="text" class="form-control" [value]="transaction.chosenPhotoFileUploadId" disabled />
        <br/>
        <img [src]="transaction.chosenPhotoFileUploadUrl" class="img-thumbnail" *ngIf="transaction.chosenPhotoFileUploadUrl" />
      </div>
      <div class="col-md-4 mb-3">
        <label>Chosen Continuation Code</label>
        <input type="text" class="form-control" [value]="transaction.continuationCode" disabled />
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="transaction.productType === 'Campaign' || transaction.productType === 'Custom'">
    <h3>Campaign Details</h3>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Campaign Code</label>
        <input type="text" class="form-control" [value]="transaction.campaignCode" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label>Echo Cause Code</label>
        <input type="text" class="form-control" [value]="transaction.echoCauseCode" disabled />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Echo Location Code</label>
        <input type="text" class="form-control" [value]="transaction.echoLocationCode" disabled />
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="transaction.productType === 'Fundraising'">
    <h3>Fundraising Details</h3>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Fundraising Profile ID</label>
        <input type="text" class="form-control" [value]="transaction.fundraisingProfileId" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label>Display as Anonymous</label>
        <input type="text" class="form-control" [value]="transaction.displayAsAnonymous" disabled />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Message</label>
        <input type="text" class="form-control" [value]="transaction.message" disabled />
      </div>
    </div>
  </ng-container>


  <ng-container *ngIf="transaction.productType === 'ChildSponsorship'">
    <h3>Child Sponsorship Details</h3>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Echo Child ID</label>
        <input type="text" class="form-control" [value]="transaction.echoChildId" disabled />
      </div>
    </div>
  </ng-container>

  <hr />

  <h3>Payment</h3>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>New Credit Card</label>
      <input type="text" class="form-control" [value]="transaction.newCreditCard" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Save Credit Card</label>
      <input type="text" class="form-control" [value]="transaction.saveCreditCard" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Request</label>
      <pre>{{ transaction.requestContent }}</pre>
    </div>
    <div class="col-md-4 mb-3">
      <label>Response</label>
      <pre>{{ transaction.responseContent }}</pre>
    </div>
  </div>

  <hr />

  <h3>Echo</h3>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Donation Code</label>
      <input type="text" class="form-control" [value]="transaction.donationCode" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Echo Sync Error</label>
      <div class="alert alert-danger" *ngIf="transaction.echoSyncError">{{ transaction.echoSyncError }}</div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Echo Sync Date</label>
      <input type="text" class="form-control" [value]="transaction.echoSyncDate" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Echo Response Trigger Code</label>
      <input type="text" class="form-control" [value]="transaction.echoResponseTriggerCode" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Echo Payment ID</label>
      <input type="text" class="form-control" [value]="transaction.echoPaymentId" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Echo Scheduled Payment ID</label>
      <input type="text" class="form-control" [value]="transaction.echoScheduledPaymentId" disabled />
    </div>
  </div>

  <div class="card">
    <div class="card-header"><strong>Commitments</strong></div>
    <div class="card-body">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>ID</th>
            <th>Echo ID</th>
            <th>Amount</th>
            <th>Date</th>
            <th>Sync Date</th>
            <th>Error</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let commitment of commitments">
            <td>{{ commitment.id }}</td>
            <td>{{ commitment.echoCommitmentId }}</td>
            <td>{{ commitment.amount }}</td>
            <td>{{ commitment.dateTimeStamp | date:'dd/MM/yy HH:mm:ss' }}</td>
            <td>{{ commitment.echoSyncDate | date:'dd/MM/yy HH:mm:ss' }}</td>
            <td>
              <div class="alert alert-danger" *ngIf="commitment.echoSyncError">{{ commitment.echoSyncError }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</form>

<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
