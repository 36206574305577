<wv-email-preview-sidenav></wv-email-preview-sidenav>
<div class="email-preview-title" *ngIf="preview && preview.type && preview.subType">
  <h3>
    <b>{{ preview.type.name }}</b> - {{ preview.subType.name }}
  </h3>
  <h4 *ngIf="preview.variant">
    <b>Variant</b> - {{ preview.variant.name }}
  </h4>
  <div *ngIf="preview.subType && preview.subType.child">
    <div class="d-inline-block" ngbDropdown>
      <button class="btn btn-light" ngbDropdownToggle>
        View another variant
      </button>
      <div ngbDropdownMenu>
        <button class="dropdown-item"
                *ngFor="let variant of preview.subType.child"
                [routerLink]="['/previews/emails/', preview.type.path , preview.subType.path , variant.path]">
          {{ variant.name }}
        </button>
      </div>
    </div>
  </div>
  <hr />
</div>
<div class="preview-email-template side-nav-gutter">
  <div *ngIf="data && !isPreviewLoading" [innerHTML]="data | safeHtml"></div>
</div>
<wv-loader *ngIf="isPreviewLoading"></wv-loader>
