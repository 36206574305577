var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBaseComponent, AppBaseComponentService, ChosenPhotosService } from '@shared';
import { forkJoin } from 'rxjs';
var ChosenPhotosDetailsComponent = /** @class */ (function (_super) {
    __extends(ChosenPhotosDetailsComponent, _super);
    function ChosenPhotosDetailsComponent(base, route, chosenPhotosService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.chosenPhotosService = chosenPhotosService;
        return _this;
    }
    ChosenPhotosDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingState.setLoading();
        forkJoin(this.chosenPhotosService.getById(this.route.snapshot.params.id))
            .subscribe(function (_a) {
            var chosenPhotos = _a[0], commitments = _a[1];
            _this.loadingState.setLoaded();
            _this.chosenPhotos = chosenPhotos;
        });
    };
    return ChosenPhotosDetailsComponent;
}(AppBaseComponent));
export { ChosenPhotosDetailsComponent };
