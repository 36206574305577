<div class="container">
  <h1>Create Fundraising Team</h1>
  <hr class="heading-hr" align="left" />
  <ngb-tabset>
    <ngb-tab title="Create New">
      <ng-template ngbTabContent>
        <wv-new-team></wv-new-team>
      </ng-template>
    </ngb-tab>
    <ngb-tab title="Load existing">
      <ng-template ngbTabContent>
        <wv-new-team [isLoadingExistingTeam]="true"></wv-new-team>
      </ng-template>
    </ngb-tab>
  </ngb-tabset>
</div>
