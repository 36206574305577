import { ComponentFactoryResolver, ComponentRef, Injectable, Type, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';

import { ModalContainerComponent } from './modal-container.component';
import { IModalContent } from './modal-content';

@Injectable()
export class ModalService {
  public modalContainer: ComponentRef<ModalContainerComponent>;
  private viewContainerRef: ViewContainerRef;

  constructor(
    private readonly router: Router,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {

    router.events.subscribe(event => {
      if (this.modalContainer && event instanceof NavigationStart) {
        this.modalContainer.destroy();
      }
    });
  }

  public registerViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.viewContainerRef = viewContainerRef;
  }

  /**
   * Creates an instance of a modal.
   *
   * @param contentComponentType The type of modal to instantiate.
   * @param parameters The parameters to pass to the instance of the modal.
   * @return An observable that you can optionally subscribe to. It will emit when the modal is closed.
   */
  public create(contentComponentType: Type<IModalContent>, parameters?: Object): Observable<any> {
    if (this.modalContainer) {
      this.modalContainer.instance.destroy();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ModalContainerComponent);

    this.viewContainerRef.clear();
    this.modalContainer = this.viewContainerRef.createComponent(componentFactory);

    const subject = new Subject<any>();

    const instanceRef = this.modalContainer.instance.initModal(contentComponentType, subject, parameters);
    this.modalContainer.instance.options = instanceRef.instance.modalContainerOptions;

    // Provide static version of container to destroy() method to avoid closing wrong container
    const instanceContainer = this.modalContainer;

    this.modalContainer.instance.destroy = () => {
      instanceContainer.destroy();

      if (instanceContainer === this.modalContainer) {
        this.modalContainer = undefined;
      }
      subject.complete();
    };

    return subject;
  }
}
