var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBaseComponent, AppBaseComponentService, SupporterChildMessageService } from '@shared';
var SupporterChildMessageDetailsComponent = /** @class */ (function (_super) {
    __extends(SupporterChildMessageDetailsComponent, _super);
    function SupporterChildMessageDetailsComponent(base, route, supporterChildMessageService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.supporterChildMessageService = supporterChildMessageService;
        return _this;
    }
    SupporterChildMessageDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingState.setLoading();
        this.supporterChildMessageService.getById(this.route.snapshot.params.id)
            .subscribe(function (message) {
            _this.loadingState.setLoaded();
            _this.message = message;
        });
    };
    return SupporterChildMessageDetailsComponent;
}(AppBaseComponent));
export { SupporterChildMessageDetailsComponent };
