<div class="sidenav">
  <label class="nav-header">Email Templates</label>
  <hr/>
  <ngb-accordion class="accordion accordion-nav"
                 *ngIf="routes"
                 [closeOthers]="true"
                 activeIds="{{ 'ngbPanel' + activeItemId }}">
    <ngb-panel class="card"
               *ngFor="let navRoute of routes; let i = index;"
               [id]="getPanelId(navRoute, i)">
      <ng-template ngbPanelTitle class="card-header">
        <h5 class="mb-0">
          <a *ngIf="navRoute.child && navRoute.child.length"
             class="btn btn-link btn-icn"
             (click)="toggleExpanded(i)"
             [ngClass]="[activeItemId === i ? 'active' : 'collapsed']"
             [attr.aria-expanded]="expanded === (expandedString + i)">
            {{ navRoute.name }}
          </a>
          <a *ngIf="!navRoute.child || !navRoute.child.length"
             class="btn btn-link"
             [ngClass]="[activeItemId === i ? 'active' : 'collapsed']"
             [routerLink]="[previewEmailPath, navRoute.path]">
            {{ navRoute.name }}
          </a>
        </h5>
      </ng-template>
      <ng-template *ngIf="navRoute.child" ngbPanelContent class="collapse">
        <div class="card-body">
          <ul class="nav flex-column">
            <li *ngFor="let subNav of navRoute.child" class="nav-item">
              <a class="nav-link"
                 routerLinkActive="active"
                 [routerLink]="[previewEmailPath, navRoute.path, subNav.path]">{{ subNav.name }}</a>
            </li>
          </ul>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
