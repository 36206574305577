import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { finalize, switchMap } from 'rxjs/operators';

import { IModalContent } from '@core';
import { AppBaseComponent, AppBaseComponentService, UserService } from '@shared';

@Component({
  selector: 'wv-team-owner-modal',
  templateUrl: './change-supporter-modal.component.html'
})
export class ChangeSupporterModalComponent extends AppBaseComponent implements IModalContent {

  public userId: number;
  public model: any = {};
  public modalContainerOptions: any;
  public close: () => any;
  public isLoading = false;
  public onSuccess: () => any;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly userService: UserService) {
    super(baseServices);

    this.modalContainerOptions = {
      overlayClass: '',
      modalClass: 'modal-dialog'
    };
  }

  public onSubmit(form: NgForm): void {
    if (form.invalid) {
      return;
    }

    this.isLoading = true;

    this.base.authService.ensureAuthentication()
      .pipe(
        switchMap(() => this.userService.setSupporterId(this.userId, parseInt(this.model.newSupporterId, 10))),
        finalize(() => {
          this.isLoading = false;
          this.close();
      }))
      .subscribe(
        () => this.onSuccess(),
        resp => this.apiErrorHandlerService.handleError(resp));
  }
}
