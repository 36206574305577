import { Injectable } from '@angular/core';

@Injectable()
export class ValidationRulesService {
  public emailAddressPattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`'{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  // This should allow a wider variety of names, but leaving it to match the integration layer for now
  public namePattern = /^[A-Za-z]+([\sA-Za-z-'])*$/;

  public echoFundraisingCommitmentIdPattern = /^(3524)[\.][0-9]+$/;
}
