import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, CampaignsService, FundraisingCampaignStub, ProfilesService } from '@shared';

@Component({
  selector: 'wv-create-echo-team-profile',
  templateUrl: './create-echo-team-profile.component.html'
})
export class FundraisingCreateEchoTeamProfileComponent extends AppBaseComponent implements OnInit {
  public campaigns: Array<FundraisingCampaignStub>;
  public model: any = {};
  public isPerformingAction = false;
  public selectedCampaign: FundraisingCampaignStub;
  private teamId: string;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly campaignService: CampaignsService,
    private readonly profileService: ProfilesService,
    private readonly route: ActivatedRoute) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.teamId = this.route.snapshot.params.id;
    this.loadCampaigns();
  }

  public loadCampaigns(): void {
    this.loadingState.setLoading();
    this.campaignService.getAll()
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(
        resp => this.campaigns = resp);
  }

  public onSubmit(form: NgForm): boolean {
    if (form.invalid) {
      return false;
    }

    this.isPerformingAction = true;
    this.profileService.createEchoTeamProfile(this.teamId, this.model.echoTeamProductCode, this.model.commitmentId)
      .pipe(finalize(
        () => {
          this.isPerformingAction = false;
        }))
      .subscribe(async resp => {
        const profileId = resp.id;

        return this.base.router.navigate(['/fundraising/profiles', profileId]);
      }, error => {
        this.apiErrorHandlerService.handleError(error);
      });

    return true;
  }

}
