/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./transaction-status-badge.component";
var styles_TransactionStatusBadgeComponent = [];
var RenderType_TransactionStatusBadgeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TransactionStatusBadgeComponent, data: {} });
export { RenderType_TransactionStatusBadgeComponent as RenderType_TransactionStatusBadgeComponent };
export function View_TransactionStatusBadgeComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "badge"]], null, null, null, null, null)), i0.ɵdid(1, 278528, null, 0, i1.NgClass, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "badge"; var currVal_1 = _co.badgeClass; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.status; _ck(_v, 2, 0, currVal_2); }); }
export function View_TransactionStatusBadgeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-transaction-status-badge", [], null, null, null, View_TransactionStatusBadgeComponent_0, RenderType_TransactionStatusBadgeComponent)), i0.ɵdid(1, 573440, null, 0, i2.TransactionStatusBadgeComponent, [], null, null)], null, null); }
var TransactionStatusBadgeComponentNgFactory = i0.ɵccf("wv-transaction-status-badge", i2.TransactionStatusBadgeComponent, View_TransactionStatusBadgeComponent_Host_0, { status: "status" }, {}, []);
export { TransactionStatusBadgeComponentNgFactory as TransactionStatusBadgeComponentNgFactory };
