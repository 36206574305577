<div *ngIf="!loadingState.isLoading()">
  <form class="container"
        (ngSubmit)="onSubmit(createProfileForm)"
        #createProfileForm="ngForm"
        novalidate>
    <h1>Create Team Profile</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <div>
          <label for="campaignCode">Campaign Code</label>
          <select class="form-control"
                  name="campaignCode"
                  id="campaignCode"
                  #campaignCode="ngModel"
                  [(ngModel)]="model.echoTeamProductCode"
                  required>
            <option *ngFor="let campaign of campaigns"
                    [value]="campaign.code">
              {{ campaign.echoTeamProductCode }}
            </option>
          </select>
        </div>
        <wv-field-helper class="inline-block" id="campaignCodeHelper" [control]="campaignCode"
                         [errorMessages]="{'required': 'Please choose a campaign.'}">
        </wv-field-helper>
      </div>
      <div class="col-md-4 mb-3">
        <div>
          <label for="commitmentId">Commitment OID</label>
          <input type="text" class="form-control" id="commitmentId" name="commitmentId"
                 [pattern]="validationRules.echoFundraisingCommitmentIdPattern"
                 [(ngModel)]="model.commitmentId"
                 #commitmentId="ngModel" />
          <small class="help-block text-muted">Leave blank to create a new commitment</small>
        </div>
        <wv-field-helper class="inline-block" id="commitmentIdHelper" [control]="commitmentId"
                         [errorMessages]="{'required': 'Please enter a commitment ID.',
                           'pattern': 'Please enter a valid commitment OID, e.g. \'3524.&lt;number&gt;\'.'}">
        </wv-field-helper>
      </div>
    </div>
    <div class="form-row">
      <div class="action-group">
        <button class="btn btn-p" type="submit" [ladda]="isPerformingAction">Create</button>
      </div>
    </div>
  </form>
</div>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
