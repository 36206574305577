var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppBaseComponent, AppBaseComponentService } from '@shared';
import { EmailPreviewService } from './services/email-preview-service';
var EmailPreviewSideNavComponent = /** @class */ (function (_super) {
    __extends(EmailPreviewSideNavComponent, _super);
    function EmailPreviewSideNavComponent(base, activateRoute, emailPreviewService) {
        var _this = _super.call(this, base) || this;
        _this.activateRoute = activateRoute;
        _this.emailPreviewService = emailPreviewService;
        _this.activeItemId = 1;
        _this.expandedString = 'expanded';
        _this.previewEmailPath = '/previews/emails';
        return _this;
    }
    EmailPreviewSideNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.activateRoute.params.subscribe(function (params) {
            // fetch all donationThankYou sub-routes
            _this.routes = _this.emailPreviewService.loadAllRoutes();
            _this.initNav();
        });
    };
    EmailPreviewSideNavComponent.prototype.toggleExpanded = function (i) {
        // kludge: to make the aria expanded (+/- on accordion) work as expected
        var expandedNone = this.expandedString + "x";
        this.expanded = (this.expanded !== this.expandedString + i)
            ? this.expandedString + i
            : expandedNone;
    };
    EmailPreviewSideNavComponent.prototype.getPanelId = function (route, index) {
        return (route.child ? 'ngbPanel' : 'prevent-ngbPanel') + index;
    };
    EmailPreviewSideNavComponent.prototype.initNav = function () {
        var _this = this;
        this.activeItemId = this.routes.findIndex(function (r) { return _this.base.router.url.includes("/" + r.path); });
        this.expanded = this.expandedString + this.activeItemId.toString();
    };
    return EmailPreviewSideNavComponent;
}(AppBaseComponent));
export { EmailPreviewSideNavComponent };
