/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./style-guide.component";
import * as i2 from "../../shared/app-base-component.service";
var styles_StyleGuideComponent = [];
var RenderType_StyleGuideComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StyleGuideComponent, data: {} });
export { RenderType_StyleGuideComponent as RenderType_StyleGuideComponent };
export function View_StyleGuideComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-p"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.testModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Test Chained Modals"]))], null, null); }
export function View_StyleGuideComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-style-guide", [], null, null, null, View_StyleGuideComponent_0, RenderType_StyleGuideComponent)), i0.ɵdid(1, 180224, null, 0, i1.StyleGuideComponent, [i2.AppBaseComponentService], null, null)], null, null); }
var StyleGuideComponentNgFactory = i0.ɵccf("wv-style-guide", i1.StyleGuideComponent, View_StyleGuideComponent_Host_0, {}, {}, []);
export { StyleGuideComponentNgFactory as StyleGuideComponentNgFactory };
