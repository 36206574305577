var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentFactoryResolver, ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { BaseComponent } from '../base.component';
/**
 * This component allows you to include a dynamically created component within its template.
 *
 * How to use:
 * 1. Subclass this component.
 * 2. Include a tag with #dynamicContent in the template e.g. <ng-template #dynamicContent></ng-template>
 * 3. Call the init() function with the type of the component to include and any parameters to pass to the component.
 */
var DynamicContainerComponent = /** @class */ (function (_super) {
    __extends(DynamicContainerComponent, _super);
    function DynamicContainerComponent(baseServices, componentFactoryResolver) {
        var _this = _super.call(this, baseServices) || this;
        _this.componentFactoryResolver = componentFactoryResolver;
        return _this;
    }
    DynamicContainerComponent.prototype.init = function (contentComponentType, parameters) {
        var componentFactory = this.componentFactoryResolver.resolveComponentFactory(contentComponentType);
        this.dynamicContent.clear();
        var contentComponent = this.dynamicContent.createComponent(componentFactory);
        Object.assign(contentComponent.instance, parameters);
        return contentComponent;
    };
    return DynamicContainerComponent;
}(BaseComponent));
export { DynamicContainerComponent };
