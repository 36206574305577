import { Component, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, IFundraisingTeam, TeamsService } from '@shared';

@Component({
  selector: 'wv-search-teams',
  templateUrl: './search-teams.component.html'
})
export class FundraisingSearchTeamsComponent extends AppBaseComponent implements OnInit {
  public teams: Array<IFundraisingTeam> = [];
  public teamId: string;
  public teamSearchTerm: string;
  public teamSearching = true;
  public searchTerm$ = new Subject<string>();
  public searchStatus: string = undefined;
  public model: any = {};
  public isLoading = false;
  private readonly minimumSearchCharacters = 3;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly teamsService: TeamsService) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(300)
      , distinctUntilChanged()
      , switchMap(term => {
        this.searchStatus = 'Searching...';
        if (term.length < this.minimumSearchCharacters) {
          this.searchStatus = `Please enter ${this.minimumSearchCharacters} or more characters to search`;

          return of(false) as any;
        }

        return this.teamsService.searchTeams(term) as any;
      }))
      .subscribe(result => {
        if (result === false) {
          this.teams = [];

          return;
        }
        const teams = result as Array<IFundraisingTeam>;

        this.searchStatus = teams.length === 0
          ? 'There is no matching team.'
          : 'Were you looking for:';

        this.teams = teams;
      });
  }

  public changeTeamSearchTerm(searchTerm: string): void {
    this.teamSearchTerm = searchTerm;
    this.searchTerm$.next(searchTerm);
  }

  public async selectMatchedTeam(teamMatch: IFundraisingTeam): Promise<any> {
    return this.base.router.navigate(['/fundraising/teams/', teamMatch.id]);
  }
}
