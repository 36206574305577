var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';
var ReportsService = /** @class */ (function (_super) {
    __extends(ReportsService, _super);
    function ReportsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ReportsService.getFileNameFromHttpResponse = function (httpResponse) {
        var contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
        var result = contentDispositionHeader
            .split(';')[1]
            .trim()
            .split('=')[1];
        return result
            .replace(/"/g, '');
    };
    ReportsService.prototype.children = function (poolCode) {
        var operation = {
            poolCode: poolCode
        };
        return this.generate('admin/reports/children', operation);
    };
    ReportsService.prototype.projects = function (projectId) {
        var operation = {
            projectId: projectId
        };
        return this.generate('admin/reports/projects', operation);
    };
    ReportsService.prototype.generate = function (url, operation) {
        var options = this.authRequestOptions();
        options.responseType = 'blob';
        options.observe = 'response';
        return this.post(url, operation, options)
            .pipe(map(function (resp) {
            var filename = ReportsService.getFileNameFromHttpResponse(resp);
            return { filename: filename, body: resp.body };
        }));
    };
    return ReportsService;
}(BaseService));
export { ReportsService };
