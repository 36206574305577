import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppBaseComponent, AppBaseComponentService, ITransaction, ITransactionCommitment, TransactionService } from '@shared';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'wv-transaction-details',
  templateUrl: './transaction-details.component.html'
})
export class TransactionDetailsComponent extends AppBaseComponent implements OnInit {
  public transaction: ITransaction;
  public commitments: Array<ITransactionCommitment>;

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly transactionService: TransactionService
  ) {
    super(base);
   }

  public ngOnInit(): void {
    this.loadingState.setLoading();

    forkJoin(
      this.transactionService.getById(this.route.snapshot.params.id),
      this.transactionService.getCommitmentsByTransactionId(this.route.snapshot.params.id)
    )
    .subscribe(([transaction, commitments]) => {
      this.loadingState.setLoaded();
      this.transaction = transaction;
      this.commitments = commitments;
    });
  }

}
