var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentFactoryResolver, ComponentRef, ElementRef, Renderer2, RendererFactory2, Type } from '@angular/core';
import { DynamicContainerComponent } from '../dynamic/dynamic-container.component';
import { BaseComponentService } from '../services/base-component.service';
var ModalContainerComponent = /** @class */ (function (_super) {
    __extends(ModalContainerComponent, _super);
    function ModalContainerComponent(baseServices, componentFactoryResolver, elementRef, document, rendererFactory) {
        var _this = _super.call(this, baseServices, componentFactoryResolver) || this;
        _this.elementRef = elementRef;
        _this.document = document;
        _this.rendererFactory = rendererFactory;
        _this.renderer = rendererFactory.createRenderer(undefined, undefined);
        var body = _this.document.body;
        _this.renderer.addClass(body, 'modal-open');
        setTimeout(function () {
            _this.elementRef.nativeElement.getElementsByClassName('modal-backdrop')[0]
                .classList
                .add('show');
            _this.elementRef.nativeElement.getElementsByClassName('modal')[0]
                .classList
                .add('show');
        }, 0);
        return _this;
    }
    ModalContainerComponent.prototype.initModal = function (contentComponentType, subject, parameters) {
        var _this = this;
        var modalContent = _super.prototype.init.call(this, contentComponentType, parameters);
        modalContent.instance.close = function (result) {
            _this.close();
            subject.next(result);
        };
        return modalContent;
    };
    ModalContainerComponent.prototype.onBackDropClick = function (event) {
        if (event.target === event.currentTarget) {
            this.close();
        }
    };
    ModalContainerComponent.prototype.close = function () {
        var _this = this;
        var body = this.document.body;
        this.renderer.removeClass(body, 'modal-open');
        this.elementRef.nativeElement.getElementsByClassName('modal-backdrop')[0]
            .classList
            .remove('show');
        this.elementRef.nativeElement.getElementsByClassName('modal')[0]
            .classList
            .remove('show');
        setTimeout(function () {
            _this.destroy();
        }, 250); // Allow the modal to fade
    };
    return ModalContainerComponent;
}(DynamicContainerComponent));
export { ModalContainerComponent };
