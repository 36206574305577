import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, FundraisingProfile, ProfilesService } from '@shared';

@Component({
  selector: 'wv-profile-details',
  templateUrl: './profile-details.component.html'
})
export class FundraisingProfileDetailsComponent extends AppBaseComponent implements OnInit {
  public profile: FundraisingProfile;
  public isPerformingAction = false;
  private profileId: string;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly profilesService: ProfilesService,
    private readonly route: ActivatedRoute) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.profileId = this.route.snapshot.params.id;
    this.getProfile();
  }

  public reinstate(): void {
    this.base.dialogService.showConfirmation(
      'Reinstate Profile',
      `Are you sure you want to reinstate this fundraising profile?
      <br><i>(Please ensure the commitment is reinstated in Echo when reinstating a Fundraising Profile.)</i>`, 'Confirm', () => {
        this.isPerformingAction = true;
        this.profilesService.reinstateProfile(this.profileId)
          .pipe(finalize(
            () => this.isPerformingAction = false))
          .subscribe(
            () => {
              this.base.dialogService.showInformation('Success', 'Fundraising Profile reinstated successfully');
              this.profile.deleted = false;
            }, resp => {
              this.apiErrorHandlerService.handleError(resp);
            });
      });
  }

  public loadProfileStatus(): string {
    return this.profile.deleted
      ? 'Deleted'
      : (this.profile.published
        ? 'Active | Published'
        : 'Active | Unpublished');
  }

  private getProfile(): void {
    this.loadingState.setLoading();
    this.profilesService.getProfile(this.profileId)
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(
        resp => {
          this.profile = resp;
        },
        resp => {
          this.apiErrorHandlerService.handleError(resp);
        });
  }
}
