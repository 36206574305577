/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/router";
import * as i4 from "../core/loader/loader.component.ngfactory";
import * as i5 from "../core/loader/loader.component";
import * as i6 from "./search-teams.component";
import * as i7 from "../shared/app-base-component.service";
import * as i8 from "../shared/api/fundraising/teams.service";
var styles_FundraisingSearchTeamsComponent = [];
var RenderType_FundraisingSearchTeamsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FundraisingSearchTeamsComponent, data: {} });
export { RenderType_FundraisingSearchTeamsComponent as RenderType_FundraisingSearchTeamsComponent };
function View_FundraisingSearchTeamsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "tr", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectMatchedTeam(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.id; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_FundraisingSearchTeamsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "table", [["class", "search-results col-md-8 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 5, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 4, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Team ID"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Team Name"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FundraisingSearchTeamsComponent_3)), i0.ɵdid(9, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.teams; _ck(_v, 9, 0, currVal_0); }, null); }
function View_FundraisingSearchTeamsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 8, "div", [["class", "col-md-8 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 1, "label", [["class", "control-label"], ["for", "teamSearchTerm"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Enter a team supporter ID or start typing team name"])), (_l()(), i0.ɵeld(5, 0, null, null, 5, "input", [["autocomplete", "off"], ["autofocus", ""], ["class", "form-control"], ["id", "teamSearchTerm"], ["name", "teamSearchTerm"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "keyup"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("keyup" === en)) {
        var pd_4 = (_co.changeTeamSearchTerm($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_co.model.dummy = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i0.ɵdid(6, 16384, null, 0, i2.DefaultValueAccessor, [i0.Renderer2, i0.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i0.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.DefaultValueAccessor]), i0.ɵdid(8, 671744, null, 0, i2.NgModel, [[2, i2.ControlContainer], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i0.ɵdid(10, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i0.ɵeld(11, 0, null, null, 2, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(12, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["", ""])), (_l()(), i0.ɵeld(14, 0, null, null, 2, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FundraisingSearchTeamsComponent_2)), i0.ɵdid(16, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(17, 0, null, null, 7, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["OR"])), (_l()(), i0.ɵeld(20, 0, null, null, 4, "div", [["class", "col-md-12 mb-3 mt-4"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 3, "a", [["class", "btn btn-p"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 22).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(22, 671744, null, 0, i3.RouterLinkWithHref, [i3.Router, i3.ActivatedRoute, i1.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(23, 3), (_l()(), i0.ɵted(-1, null, ["Create Team"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = "teamSearchTerm"; var currVal_8 = _co.model.dummy; _ck(_v, 8, 0, currVal_7, currVal_8); var currVal_10 = (_co.teams && _co.teams.length); _ck(_v, 16, 0, currVal_10); var currVal_13 = _ck(_v, 23, 0, "/fundraising", "teams", "create"); _ck(_v, 22, 0, currVal_13); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 10).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 10).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 10).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 10).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 10).ngClassValid; var currVal_5 = i0.ɵnov(_v, 10).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 10).ngClassPending; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = _co.searchStatus; _ck(_v, 13, 0, currVal_9); var currVal_11 = i0.ɵnov(_v, 22).target; var currVal_12 = i0.ɵnov(_v, 22).href; _ck(_v, 21, 0, currVal_11, currVal_12); }); }
function View_FundraisingSearchTeamsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-loader", [], null, null, null, i4.View_LoaderComponent_0, i4.RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i5.LoaderComponent, [], null, null)], null, null); }
export function View_FundraisingSearchTeamsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "form", [["class", "container"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_bh, [], null, null), i0.ɵdid(2, 4210688, null, 0, i2.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i0.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i0.ɵeld(5, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Search Fundraising Teams"])), (_l()(), i0.ɵeld(7, 0, null, null, 0, "hr", [["align", "left"], ["class", "heading-hr"]], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "form-row"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "col-md-8 mb-3"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FundraisingSearchTeamsComponent_1)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FundraisingSearchTeamsComponent_4)), i0.ɵdid(13, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.teamSearching; _ck(_v, 11, 0, currVal_7); var currVal_8 = _co.loadingState.isLoading(); _ck(_v, 13, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 4).ngClassValid; var currVal_5 = i0.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_FundraisingSearchTeamsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-search-teams", [], null, null, null, View_FundraisingSearchTeamsComponent_0, RenderType_FundraisingSearchTeamsComponent)), i0.ɵdid(1, 245760, null, 0, i6.FundraisingSearchTeamsComponent, [i7.AppBaseComponentService, i8.TeamsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FundraisingSearchTeamsComponentNgFactory = i0.ɵccf("wv-search-teams", i6.FundraisingSearchTeamsComponent, View_FundraisingSearchTeamsComponent_Host_0, {}, {}, []);
export { FundraisingSearchTeamsComponentNgFactory as FundraisingSearchTeamsComponentNgFactory };
