<div class="modal-content">
  <div class="modal-body">
    <h1 class="modal-title">{{ title }}</h1>
    <div [innerHTML]="message"></div>
  </div>
  <div class="modal-footer left-right">
        <button type="button" class="btn btn-s"
                data-dismiss="modal"
                *ngIf="showCancel"
                (click)="cancelClick()">
          {{ cancelCaption || getString('action.cancel') }}
        </button>
        <button type="button" class="btn btn-p btn-pad"
                [class.disabled]="dialogLoading"
                [disabled]="dialogLoading"
                [ladda]="dialogLoading"
                data-spinner-color='#ffffff'
                *ngIf="showOk"
                (click)="okClick($event)">
          {{ okCaption || getString('action.confirm') }}
        </button>
  </div>
</div>
