import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AuthModule } from '@auth';
import { AppConfig, CoreModule } from '@core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ChosenPhotosModule } from './chosen-photos/chosen-photos.module';
import { FundraisingModule } from './fundraising/fundraising.module';
import { MasterModule } from './master/master.module';
import { PreviewModule } from './preview/preview.module';
import { ReportsModule } from './reports/reports.module';
import { SignInModule } from './sign-in/sign-in.module';
import { SupportersModule } from './supporters/supporters.module';
import { TransactionsModule } from './transactions/transactions.module';

import { ApplicationInitialisationService } from '@shared';

// tslint:disable-next-line:only-arrow-functions
export function initApp(appInitService: ApplicationInitialisationService): () => Promise<any> {
  return async () => appInitService.initialise();
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AuthModule,
    CoreModule,
    AppRoutingModule,
    SignInModule,
    SupportersModule,
    TransactionsModule,
    ChosenPhotosModule,
    FundraisingModule,
    PreviewModule,
    ReportsModule,
    MasterModule
  ],
  providers: [
    AppConfig,
    ApplicationInitialisationService,
    { provide: APP_INITIALIZER, useFactory: initApp, deps: [ApplicationInitialisationService], multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
