import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { EmailPreviewComponent } from './email-preview.component';

const routes: Routes = [
  { path: 'previews/emails/:type', component: EmailPreviewComponent },
  { path: 'previews/emails/:type/:subType', component: EmailPreviewComponent },
  { path: 'previews/emails/:type/:subType/:variant', component: EmailPreviewComponent }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class PreviewRoutingModule { }
