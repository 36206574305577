import { Observable, Subject } from 'rxjs';

import { LoadingState } from './loading-state';

export class LoadingStateManager {

  public state: LoadingState;
  public stateChanged$: Observable<LoadingState>;
  private readonly subject: Subject<LoadingState>;

  constructor() {
    this.subject = new Subject<LoadingState>();
    this.stateChanged$ = this.subject.asObservable();

    this.subject.subscribe(s => this.state = s);
  }

  public setLoading(): void {
    this.subject.next(LoadingState.Initial);
  }

  public setRefreshing(): void {
    this.subject.next(LoadingState.Refresh);
  }

  public setLoaded(): void {
    this.subject.next(LoadingState.None);
  }

  public isLoading(): boolean {
    return this.state === LoadingState.Initial;
  }

  public isLoaded(): boolean {
    return this.state === LoadingState.None;
  }

  public isRefreshing(): boolean {
    return this.state === LoadingState.Refresh;
  }
}
