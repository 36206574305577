import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupporterProfile } from '../models/supporter-profile';
import { ISupporterStub } from '../models/supporter-stub';
import { IUser } from '../models/user';
import { BaseService } from './base.service';

@Injectable()
export class SupporterService extends BaseService {

  public getSupporter(id: number): Observable<SupporterProfile> {
    return this.get<SupporterProfile>(`admin/supporters/${id}`);
  }

  public getUsersBySupporter(supporterId: number): Observable<Array<IUser>> {
    return this.get<Array<IUser>>(`admin/supporters/${supporterId}/users`);
  }

  public getSupportersBySearchTerm(searchTerm: string): Observable<Array<ISupporterStub>> {
    return this.get<Array<ISupporterStub>>(`admin/supporters?searchTerm=${searchTerm}&limit=10`);
  }
}
