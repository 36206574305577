import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { FundraisingRoutingModule } from './fundraising-routing.module';

import { FundraisingCreateEchoTeamProfileComponent } from './create-echo-team-profile.component';
import { FundraisingNewTeamTabsComponent } from './new-team-tabs.component';
import { FundraisingNewTeamComponent } from './new-team.component';
import { FundraisingProfileDetailsComponent } from './profile-details.component';
import { FundraisingSearchProfilesComponent } from './search-profiles.component';
import { FundraisingSearchTeamsComponent } from './search-teams.component';
import { FundraisingTeamDetailsComponent } from './team-details.component';
import { FundraisingTeamOwnerModalComponent } from './team-owner-modal.component';

@NgModule({
  declarations: [
    FundraisingCreateEchoTeamProfileComponent,
    FundraisingTeamDetailsComponent,
    FundraisingTeamOwnerModalComponent,
    FundraisingSearchTeamsComponent,
    FundraisingSearchProfilesComponent,
    FundraisingProfileDetailsComponent,
    FundraisingNewTeamComponent,
    FundraisingNewTeamTabsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgbTabsetModule,
    LaddaModule,

    CoreModule,
    SharedModule,

    FundraisingRoutingModule
  ],
  entryComponents: [
    FundraisingTeamOwnerModalComponent
  ]
})
export class FundraisingModule { }
