import { Injectable } from '@angular/core';
import { PagingWrapper } from '@shared';
import { Observable } from 'rxjs';
import { IChosenPhotos } from '../models/chosen-photos';
import { IChosenPhotosQuery } from '../models/chosen-photos-query';
import { BaseService } from './base.service';

@Injectable()
export class ChosenPhotosService extends BaseService {

  public find(query: IChosenPhotosQuery): Observable<PagingWrapper<IChosenPhotos>> {
    return this.get<PagingWrapper<IChosenPhotos>>(`admin/photos?${BaseService.objectToQueryString(query)}`);
  }

  public getById(id: string): Observable<IChosenPhotos> {
    return this.get<IChosenPhotos>(`admin/photos/${id}`);
  }

  public cleanPhotoFileName($fileanme): string {
    return $fileanme.replace('uploads/', '');
  }
}
