import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';

import { ChosenPhotosRoutingModule } from './chosen-photos-routing.module';

import { ChosenPhotosDetailsComponent } from './chosen-photos-details/chosen-photos-details.component';
import { ChosenPhotosListComponent } from './chosen-photos-list/chosen-photos-list.component';

@NgModule({
  declarations: [ChosenPhotosListComponent, ChosenPhotosDetailsComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    SharedModule,
    LaddaModule,
    NgbPaginationModule,
    ChosenPhotosRoutingModule
  ]
})
export class ChosenPhotosModule { }
