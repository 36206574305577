var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingStateManager } from '@core';
import { AppBaseComponent, AppBaseComponentService, ChosenPhotosService } from '@shared';
var ChosenPhotosListComponent = /** @class */ (function (_super) {
    __extends(ChosenPhotosListComponent, _super);
    function ChosenPhotosListComponent(base, route, chosenPhotosService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.chosenPhotosService = chosenPhotosService;
        _this.model = {};
        _this.pageSize = 20;
        _this.page = 1;
        _this.searchState = new LoadingStateManager();
        return _this;
    }
    ChosenPhotosListComponent.prototype.ngOnInit = function () {
        this.loadingState.setLoading();
        var params = this.route.snapshot.queryParams;
        this.page = params.page ? Number.parseInt(params.page, 10) : 1;
        this.model.transactionId = params.transactionId;
        this.model.supporterId = params.supporterId;
        this.loadPage(this.page, this.pageSize);
    };
    ChosenPhotosListComponent.prototype.pageChange = function (pageNumber) {
        this.updateFilters();
        this.loadPage(pageNumber, this.pageSize);
    };
    ChosenPhotosListComponent.prototype.onSubmit = function (_form) {
        this.page = 1;
        this.updateFilters();
        this.loadPage(this.page, this.pageSize);
    };
    ChosenPhotosListComponent.prototype.updateFilters = function () {
        this.base.router.navigate([], {
            queryParams: {
                page: this.page,
                transactionId: this.model.transactionId,
                supporterId: this.model.supporterId
            }
        })
            .catch();
    };
    ChosenPhotosListComponent.prototype.loadPage = function (page, pageSize) {
        var _this = this;
        if (this.loadingState.isLoaded()) {
            this.loadingState.setRefreshing();
        }
        this.chosenPhotosService.find({
            skip: (page - 1) * this.pageSize,
            take: pageSize,
            transactionId: this.model.transactionId,
            supporterId: this.model.supporterId
        })
            .subscribe(function (chosenPhotos) {
            _this.loadingState.setLoaded();
            _this.total = chosenPhotos.total;
            _this.chosenPhotos = chosenPhotos.items;
        });
    };
    return ChosenPhotosListComponent;
}(AppBaseComponent));
export { ChosenPhotosListComponent };
