import { Component } from '@angular/core';

import { AppBaseComponent, AppBaseComponentService } from '@shared';

@Component({
  selector: 'wv-master',
  templateUrl: './master.component.html'
})
export class MasterComponent extends AppBaseComponent {

  constructor(baseServices: AppBaseComponentService) {
    super(baseServices);
  }

  // tslint:disable-next-line:typedef
  public async signOut() {
    this.base.authService.logout();
  }
}
