var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { map } from 'rxjs/operators';
import { ReportsService } from '@shared';
import { BaseService } from './base.service';
var SupporterChildMessageService = /** @class */ (function (_super) {
    __extends(SupporterChildMessageService, _super);
    function SupporterChildMessageService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupporterChildMessageService.prototype.find = function (skip, take, childId, supporterId) {
        return this.get("admin/supporter-child-messages?" + BaseService.objectToQueryString({ skip: skip, take: take, childId: childId, supporterId: supporterId }));
    };
    SupporterChildMessageService.prototype.export = function (childId, supporterId) {
        var url = "admin/supporter-child-messages?" + BaseService.objectToQueryString({ childId: childId, supporterId: supporterId });
        var options = this.authRequestOptions();
        options.responseType = 'blob';
        options.observe = 'response';
        return this.post(url, undefined, options)
            .pipe(map(function (resp) {
            var filename = ReportsService.getFileNameFromHttpResponse(resp);
            return { filename: filename, body: resp.body };
        }));
    };
    SupporterChildMessageService.prototype.getById = function (id) {
        return this.get("admin/supporter-child-messages/" + id);
    };
    return SupporterChildMessageService;
}(BaseService));
export { SupporterChildMessageService };
