import { Component, OnInit } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, IFundraisingProfileSearchResult, ProfilesService } from '@shared';

@Component({
  selector: 'wv-search-profiles',
  templateUrl: './search-profiles.component.html'
})
export class FundraisingSearchProfilesComponent extends AppBaseComponent implements OnInit {
  public profiles: Array<IFundraisingProfileSearchResult> = [];
  public profileId: string;
  public profileSearchTerm: string;
  public profileSearching = true;
  public searchTerm$ = new Subject<string>();
  public searchStatus: string = undefined;
  public model: any = {};
  public isLoading = false;
  private readonly minimumSearchCharacters = 3;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly profilesService: ProfilesService) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(300)
      , distinctUntilChanged()
      , switchMap(term => {
        this.searchStatus = 'Searching...';
        if (term.length < this.minimumSearchCharacters) {
          this.searchStatus = `Please enter ${this.minimumSearchCharacters} or more characters to search`;

          return of(false) as any;
        }

        return this.profilesService.searchFundraisingProfiles(term) as any;
      }))
      .subscribe(result => {
        if (result === false) {
          this.profiles = [];

          return;
        }
        const profiles = result as Array<IFundraisingProfileSearchResult>;
        this.searchStatus = profiles.length === 0 ? 'There is no matching profile.' : 'Were you looking for:';
        this.profiles = profiles;
      });
  }

  public changeProfileSearchTerm(searchTerm: string): void {
    this.profileSearchTerm = searchTerm;
    this.searchTerm$.next(searchTerm);
  }

  public async selectMatchedProfile(profileMatch: IFundraisingProfileSearchResult): Promise<any> {
    return this.base.router.navigate(['/fundraising/profiles/', profileMatch.id]);
  }
}
