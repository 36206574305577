/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./new-team.component.ngfactory";
import * as i2 from "./new-team.component";
import * as i3 from "../shared/app-base-component.service";
import * as i4 from "../shared/api/fundraising/teams.service";
import * as i5 from "../../../node_modules/@ng-bootstrap/ng-bootstrap/ng-bootstrap.ngfactory";
import * as i6 from "@ng-bootstrap/ng-bootstrap";
import * as i7 from "./new-team-tabs.component";
var styles_FundraisingNewTeamTabsComponent = [];
var RenderType_FundraisingNewTeamTabsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FundraisingNewTeamTabsComponent, data: {} });
export { RenderType_FundraisingNewTeamTabsComponent as RenderType_FundraisingNewTeamTabsComponent };
function View_FundraisingNewTeamTabsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-new-team", [], null, null, null, i1.View_FundraisingNewTeamComponent_0, i1.RenderType_FundraisingNewTeamComponent)), i0.ɵdid(1, 245760, null, 0, i2.FundraisingNewTeamComponent, [i3.AppBaseComponentService, i4.TeamsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_FundraisingNewTeamTabsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-new-team", [], null, null, null, i1.View_FundraisingNewTeamComponent_0, i1.RenderType_FundraisingNewTeamComponent)), i0.ɵdid(1, 245760, null, 0, i2.FundraisingNewTeamComponent, [i3.AppBaseComponentService, i4.TeamsService], { isLoadingExistingTeam: [0, "isLoadingExistingTeam"] }, null)], function (_ck, _v) { var currVal_0 = true; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FundraisingNewTeamTabsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Create Fundraising Team"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "hr", [["align", "left"], ["class", "heading-hr"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 14, "ngb-tabset", [], null, null, null, i5.View_NgbTabset_0, i5.RenderType_NgbTabset)), i0.ɵdid(5, 2146304, null, 1, i6.NgbTabset, [i6.NgbTabsetConfig], null, null), i0.ɵqud(603979776, 1, { tabs: 1 }), (_l()(), i0.ɵeld(7, 0, null, null, 5, "ngb-tab", [["title", "Create New"]], null, null, null, null, null)), i0.ɵdid(8, 2113536, [[1, 4]], 2, i6.NgbTab, [], { title: [0, "title"] }, null), i0.ɵqud(603979776, 2, { titleTpls: 1 }), i0.ɵqud(603979776, 3, { contentTpls: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_FundraisingNewTeamTabsComponent_1)), i0.ɵdid(12, 16384, [[3, 4]], 0, i6.NgbTabContent, [i0.TemplateRef], null, null), (_l()(), i0.ɵeld(13, 0, null, null, 5, "ngb-tab", [["title", "Load existing"]], null, null, null, null, null)), i0.ɵdid(14, 2113536, [[1, 4]], 2, i6.NgbTab, [], { title: [0, "title"] }, null), i0.ɵqud(603979776, 4, { titleTpls: 1 }), i0.ɵqud(603979776, 5, { contentTpls: 1 }), (_l()(), i0.ɵand(0, null, null, 1, null, View_FundraisingNewTeamTabsComponent_2)), i0.ɵdid(18, 16384, [[5, 4]], 0, i6.NgbTabContent, [i0.TemplateRef], null, null)], function (_ck, _v) { var currVal_0 = "Create New"; _ck(_v, 8, 0, currVal_0); var currVal_1 = "Load existing"; _ck(_v, 14, 0, currVal_1); }, null); }
export function View_FundraisingNewTeamTabsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-new-team-tabs", [], null, null, null, View_FundraisingNewTeamTabsComponent_0, RenderType_FundraisingNewTeamTabsComponent)), i0.ɵdid(1, 180224, null, 0, i7.FundraisingNewTeamTabsComponent, [i3.AppBaseComponentService], null, null)], null, null); }
var FundraisingNewTeamTabsComponentNgFactory = i0.ɵccf("wv-new-team-tabs", i7.FundraisingNewTeamTabsComponent, View_FundraisingNewTeamTabsComponent_Host_0, {}, {}, []);
export { FundraisingNewTeamTabsComponentNgFactory as FundraisingNewTeamTabsComponentNgFactory };
