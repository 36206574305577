import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from '@auth';

import { ChildReportComponent } from './child-report/child-report.component';
import { ProjectReportComponent } from './project-report/project-report.component';

const routes: Routes = [
  { path: 'reports/child-report', component: ChildReportComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'reports/project-report', component: ProjectReportComponent, canActivate: [CanActivateAuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class ReportsRoutingModule { }
