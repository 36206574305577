var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from '../base.service';
var TeamsService = /** @class */ (function (_super) {
    __extends(TeamsService, _super);
    function TeamsService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TeamsService.prototype.getTeamDetails = function (id) {
        return this.get("admin/fundraising/teams/" + id);
    };
    TeamsService.prototype.removeOwner = function (id) {
        var operation = {
            operation: 'removeOwner',
            data: {}
        };
        return this.patch("admin/fundraising/teams/" + id, operation);
    };
    TeamsService.prototype.changeOwner = function (teamId, supporterId) {
        var model = {
            supporterId: supporterId
        };
        var operation = {
            operation: 'claimTeam',
            data: model
        };
        return this.patch("admin/fundraising/teams/" + teamId, operation);
    };
    TeamsService.prototype.searchTeams = function (searchTerm) {
        return this.get("admin/fundraising/teams?searchTerm=" + searchTerm + "&take=10");
    };
    TeamsService.prototype.createTeam = function (teamModel) {
        return this.post('admin/fundraising/teams/', teamModel);
    };
    TeamsService.prototype.getTeamTypeCodes = function () {
        return this.get('fundraising/team-types');
    };
    return TeamsService;
}(BaseService));
export { TeamsService };
