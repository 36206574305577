import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ISupporterChildMessage, PagingWrapper, ReportsService } from '@shared';
import { BaseService } from './base.service';

@Injectable()
export class SupporterChildMessageService extends BaseService {

  public find(skip: number, take: number, childId: string, supporterId: string): Observable<PagingWrapper<ISupporterChildMessage>> {
    return this.get<PagingWrapper<ISupporterChildMessage>>(
      `admin/supporter-child-messages?${BaseService.objectToQueryString({ skip, take, childId, supporterId })}`);
  }

  public export(childId: string, supporterId: string): Observable<any> {
    const url = `admin/supporter-child-messages?${BaseService.objectToQueryString({ childId, supporterId })}`;

    const options = this.authRequestOptions();
    options.responseType = 'blob';
    options.observe = 'response';

    return this.post<any>(url, undefined, options)
      .pipe(
        map(resp => {
          const filename = ReportsService.getFileNameFromHttpResponse(resp);

          return { filename, body: resp.body };
        }));
  }

  public getById(id: string): Observable<ISupporterChildMessage> {
    return this.get<ISupporterChildMessage>(`admin/supporter-child-messages/${id}`);
  }
}
