<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>

<ng-container *ngIf="!loadingState.isLoading()">
  <form class="supporter-search container" (ngSubmit)="onSubmit(supporterForm)" #supporterForm="ngForm" novalidate>
    <h1>Supporter Child Messages</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="transactionId">Child ID</label>
        <input type="text" class="form-control" id="childId" name="childId"
          placeholder="Enter a Child ID" [(ngModel)]="model.childId" #transactionId="ngModel" />
      </div>

      <div class="col-md-6 mb-3">
        <label for="supporterId">Supporter ID</label>
        <input type="number" class="form-control" id="supporterId" name="supporterId" placeholder="Enter a supporter ID"
          [(ngModel)]="model.supporterId" #supporterId="ngModel" />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3">
        <div class="pull-right action-group">
          <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isRefreshing()">Search</button>
          <button class="btn btn-s pull-right" type="button" (click)="export()" [ladda]="loadingState.isRefreshing()">Export</button>
        </div>
      </div>
    </div>

  </form>

  <div class="container">
    <table class="table table-condensed table-striped" [class.table-loading]="loadingState.isRefreshing()">
      <thead>
        <tr>
          <th>ID</th>
          <th>Child ID</th>
          <th>Supporter ID</th>
          <th style="width: 50%">Message</th>
          <th>Has Image</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        <tr class="clickable" *ngFor="let message of messages"
          [routerLink]="['/supporter-child-messages', message.id]">
          <td>{{ message.id }}</td>
          <td>{{ message.childId }}</td>
          <td>{{ message.supporterId }}</td>
          <td>{{ message.message | truncate:100:true:'...' }}</td>
          <td>{{ !!message.imageFilename ? 'Yes' : 'No' }}</td>
          <td [title]="message.createdDate | date: 'dd MMM yyyy hh:mm:ss a'">
            {{ message.createdDate | date: 'dd MMM yyyy' }}</td>
        </tr>
      </tbody>
    </table>

    <nav class="pull-right inline-block" *ngIf="messages">
      <ngb-pagination [(page)]="page" [maxSize]="10" [pageSize]="pageSize" [collectionSize]="total" (pageChange)="pageChange($event)">
      </ngb-pagination>
    </nav>
  </div>
</ng-container>
