import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { ModalService } from './modal.service';

@Component({
  selector: 'wv-modal-placeholder',
  template: '<div #modalplaceholder></div>'
})
export class ModalPlaceholderComponent implements OnInit {

  @ViewChild('modalplaceholder', { read: ViewContainerRef })
  public viewContainerRef: ViewContainerRef;

  constructor(private readonly modalService: ModalService) {
  }

  public ngOnInit(): void {
    this.modalService.registerViewContainerRef(this.viewContainerRef);
  }
}
