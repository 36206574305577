import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService, AuthStateService } from '@auth';
import { AppConfig, BaseComponentService, DialogService, ModalService } from '@core';
import { ApiErrorHandlerService } from './api-error-handler.service';
import { ValidationRulesService } from './validation-rules.service';

@Injectable()
export class AppBaseComponentService extends BaseComponentService {
  constructor(
    public dialogService: DialogService,
    public appConfig: AppConfig,
    public authService: AuthService,
    public modalService: ModalService,
    public authStateService: AuthStateService,
    public validationRules: ValidationRulesService,
    public router: Router,
    public apiErrorHandlerService: ApiErrorHandlerService
  ) {
    super();
  }
}
