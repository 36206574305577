var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, SmilesService } from '@shared';
import { SmilesGiftStatus } from './smiles-gift-status';
var SmilesGiftDetailsComponent = /** @class */ (function (_super) {
    __extends(SmilesGiftDetailsComponent, _super);
    function SmilesGiftDetailsComponent(baseServices, route, smilesService) {
        var _this = _super.call(this, baseServices) || this;
        _this.route = route;
        _this.smilesService = smilesService;
        _this.SmilesGiftStatus = SmilesGiftStatus;
        _this.isEditing = false;
        _this.isPerformingAction = false;
        _this.sendDateModel = {};
        return _this;
    }
    SmilesGiftDetailsComponent.getDateFromNgbModel = function (dateModel) {
        return new Date(dateModel.month + "-" + dateModel.day + "-" + dateModel.year);
    };
    SmilesGiftDetailsComponent.getSmilesGiftStatus = function (smilesGift) {
        if (smilesGift.sentDate && smilesGift.sentDate) {
            return SmilesGiftStatus.Sent;
        }
        if (smilesGift.sendDate && !smilesGift.sentDate) {
            var localDate = new Date();
            var sendDateLocal = new Date(smilesGift.sendDate + " UTC");
            if (sendDateLocal > localDate) {
                return SmilesGiftStatus.Pending;
            }
            return SmilesGiftStatus.Unsent;
        }
        return SmilesGiftStatus.Unknown;
    };
    SmilesGiftDetailsComponent.prototype.ngOnInit = function () {
        this.giftId = this.route.snapshot.params.id;
        this.getSmilesGift(this.giftId);
        // Set min date for sendDate to tomorrow's date
        var now = new Date();
        this.sendMinDate = {
            year: now.getFullYear(),
            month: now.getMonth() + 1,
            day: now.getDate()
        };
    };
    SmilesGiftDetailsComponent.prototype.edit = function () {
        this.isEditing = true;
        var sendDate = new Date(this.smilesGift.sendDate);
        this.sendDateModel = {
            year: sendDate.getFullYear(),
            month: sendDate.getMonth() + 1,
            day: sendDate.getDate()
        };
    };
    SmilesGiftDetailsComponent.prototype.onSubmit = function (form) {
        var _this = this;
        if (form.invalid) {
            return false;
        }
        var options = {
            confirmationDialogTitle: 'Update smiles gift',
            confirmationDialogMessage: 'Are you sure you want to update this smiles gift?',
            informationDialogMessage: 'Smiles Gift updated successfully',
            isUpdate: true
        };
        var sendDate = SmilesGiftDetailsComponent.getDateFromNgbModel(this.sendDateModel);
        this.performAction(options, function () {
            return _this.smilesService.updateSmilesGift(_this.giftId, _this.smilesGift.recipientEmailAddress, sendDate.toUTCString());
        }); // perform update action
        return true;
    };
    SmilesGiftDetailsComponent.prototype.sendEmail = function () {
        var _this = this;
        var options = {
            confirmationDialogTitle: 'Send email',
            confirmationDialogMessage: 'Are you sure you want to send this smiles gift email?',
            informationDialogMessage: 'Smiles Gift email sent successfully',
            isUpdate: false
        };
        this.performAction(options, function () { return _this.smilesService.resendSmilesGiftEmail(_this.giftId); }); // perform update action
    };
    SmilesGiftDetailsComponent.prototype.cancelEditing = function () {
        this.isEditing = false;
        this.smilesGift = __assign({}, this.baseSmilesGift);
    };
    // Kludge: Force datepickers (and maybe other custom controls) to update the element validity,
    // which affects the: is-valid and: is-invalid pseudo-classes
    // and therefore the visual display - due to Bootstrap 4
    SmilesGiftDetailsComponent.prototype.evalDp = function (dp, element, event) {
        if (dp.invalid) {
            element.setCustomValidity('is-invalid');
        }
        else {
            element.setCustomValidity('');
        }
    };
    SmilesGiftDetailsComponent.prototype.getSmilesGift = function (id) {
        var _this = this;
        this.loadingState.setLoading();
        this.smilesService.getSmilesGift(id)
            .pipe(finalize(function () {
            _this.loadingState.setLoaded();
        }))
            .subscribe(function (resp) {
            _this.smilesGift = resp;
            _this.baseSmilesGift = __assign({}, resp);
            _this.smilesGiftStatus = SmilesGiftDetailsComponent.getSmilesGiftStatus(resp);
        });
    };
    SmilesGiftDetailsComponent.prototype.performAction = function (options, action) {
        var _this = this;
        return this.dialogService.showConfirmation(options.confirmationDialogTitle, options.confirmationDialogMessage, 'Confirm', function () {
            _this.isPerformingAction = true;
            action()
                .pipe(finalize(function () { return _this.isPerformingAction = false; }))
                .subscribe(function () {
                _this.dialogService.showInformation('Success', options.informationDialogMessage);
                if (options.isUpdate) {
                    _this.smilesGift.sendDate = SmilesGiftDetailsComponent.getDateFromNgbModel(_this.sendDateModel)
                        .toString();
                    _this.baseSmilesGift = __assign({}, _this.smilesGift);
                    _this.isEditing = false;
                    return;
                }
                // change smiles gift status label
                _this.smilesGiftStatus = SmilesGiftStatus.Sent;
            }, function (resp) {
                _this.apiErrorHandlerService.handleError(resp);
            });
        });
    };
    return SmilesGiftDetailsComponent;
}(AppBaseComponent));
export { SmilesGiftDetailsComponent };
