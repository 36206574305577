<div *ngIf="orders && orders.length">
  <table class="table table-striped">
    <thead>
    <tr>
      <th>Order ID</th>
      <th>Supporter ID</th>
      <th>Supporter First Name</th>
      <th>Supporter Last Name</th>
      <th>Date of Order</th>
    </tr>
    </thead>
    <tbody>
    <tr class="clickable" *ngFor="let order of orders" [routerLink]="['/smiles-orders', order.orderId, 'smiles-gifts']">
      <td>{{ order.orderId }}</td>
      <td>{{ order.supporter.id }}</td>
      <td>{{ order.supporter.firstName }}</td>
      <td>{{ order.supporter.lastName }}</td>
      <td [title]="order.orderDate | date: 'dd MMM yyyy hh:mm:ss a'">{{ order.orderDate | date: 'dd MMM yyyy' }}</td>
    </tr>
    </tbody>
  </table>
  <div *ngIf="pages && pages.length">
    <div class="row inline-block" *ngIf="tablePageSizeItems && tablePageSizeItems.length">
      <div class="col">
        <div ngbDropdown class="d-inline-block">
          <button class="btn btn-primary" ngbDropdownToggle>
            Items per page
          </button>
          <div ngbDropdownMenu>
            <button class="dropdown-item"
                    [class.active]="item === activeTake"
                    *ngFor="let item of tablePageSizeItems"
                    (click)="selectTake(item)">
              {{ item }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <nav class="pull-right inline-block">
      <ul class="pagination">
        <li *ngFor="let page of pages" class="page-item" [class.active]="page == activePage">
          <a class="page-link" (click)="getOrdersForPage(page)">{{ page }}</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
<h3 *ngIf="orders && !orders.length">No Smiles Orders found</h3>
