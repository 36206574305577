<div *ngIf="profile">
  <form class="container">
    <h1>Fundraising Profile Details</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="profileId">ID</label>
        <input type="text" class="form-control" id="profileId" name="profileId"
               [(ngModel)]="profile.id" disabled />
      </div>
      <div class="col-md-4 mb-3">
        <label for="profileType">Type</label>
        <input type="text" class="form-control" id="profileType" name="profileType"
               [(ngModel)]="profile.profileType" disabled />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="deadline">Deadline</label>
        <input type="text" class="form-control" id="deadline" name="deadline"
               [value]="profile.deadline | date:'dd MMMM yyyy'" disabled />
      </div>
      <div class="col-md-4 mb-3" *ngIf="profile.campaign">
        <label for="campaignName">Campaign Name</label>
        <input type="text" class="form-control" id="campaignName" name="campaignName"
               [(ngModel)]="profile.campaign.name" disabled />
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label>Status</label>
        <h2>
          <span class="badge"
                [class.badge-success]="!profile.deleted && profile.published"
                [class.badge-secondary]="!profile.deleted && !profile.published"
                [class.badge-danger]="profile.deleted">
            {{ loadProfileStatus() }}
          </span>
        </h2>
      </div>
    </div>
    <div *ngIf="profile.team">
      <h4>Team Details</h4>
      <hr class="heading-hr" align="left" />
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="teamId">Team Name</label>
          <input type="text" class="form-control" id="teamId" name="teamId"
                 [(ngModel)]="profile.team.id" disabled />
        </div>
        <div class="col-md-4 mb-3">
          <label for="teamName">Team Name</label>
          <input type="text" class="form-control" id="teamName" name="teamName"
                 [(ngModel)]="profile.team.name" disabled />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="teamTypeCode">Team Type Code</label>
          <input type="text" class="form-control" id="teamTypeCode" name="teamTypeCode"
                 [(ngModel)]="profile.team.teamTypeCode" disabled />
        </div>
      </div>
    </div>
    <div *ngIf="profile.owner">
      <h4>Owner Details</h4>
      <hr class="heading-hr" align="left" />
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="ownerSupporterId">Owner SupporterId</label>
          <input type="text" class="form-control" id="ownerSupporterId" name="ownerSupporterId"
                 [(ngModel)]="profile.owner.id" disabled />
        </div>
        <div class="col-md-4 mb-3">
          <label for="ownerFirstName">Owner First Name</label>
          <input type="text" class="form-control" id="ownerFirstName" name="ownerFirstName"
                 [(ngModel)]="profile.owner.firstName" disabled />
        </div>
      </div>
      <div class="form-row">
        <div class="col-md-4 mb-3">
          <label for="ownerLastName">Owner Last Name</label>
          <input type="text" class="form-control" id="ownerLastName" name="ownerLastName"
                 [(ngModel)]="profile.owner.lastName" disabled />
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="pull-right action-group">
        <button class="btn btn-s pull-right" type="button" *ngIf="profile.deleted" [ladda]="isPerformingAction" (click)="reinstate()">Reinstate</button>
      </div>
    </div>
  </form>
</div>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
