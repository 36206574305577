/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "angular2-ladda/module/ladda.directive";
import * as i2 from "angular2-ladda/module/ladda-config";
import * as i3 from "@angular/common";
import * as i4 from "./dialog.component";
import * as i5 from "../../services/base-component.service";
var styles_DialogComponent = [];
var RenderType_DialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DialogComponent, data: {} });
export { RenderType_DialogComponent as RenderType_DialogComponent };
function View_DialogComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-s"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancelClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.cancelCaption || _co.getString("action.cancel")); _ck(_v, 1, 0, currVal_0); }); }
function View_DialogComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn btn-p btn-pad"], ["data-spinner-color", "#ffffff"], ["type", "button"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.okClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 737280, null, 0, i1.LaddaDirective, [i0.ElementRef, [2, i2.LaddaConfig]], { loading: [0, "loading"], disabled: [1, "disabled"] }, null), (_l()(), i0.ɵted(2, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.dialogLoading; var currVal_2 = _co.dialogLoading; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dialogLoading; _ck(_v, 0, 0, currVal_0); var currVal_3 = (_co.okCaption || _co.getString("action.confirm")); _ck(_v, 2, 0, currVal_3); }); }
export function View_DialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "div", [["class", "modal-content"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵeld(4, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "modal-footer left-right"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_1)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DialogComponent_2)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showCancel; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.showOk; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 4, 0, currVal_1); }); }
export function View_DialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_DialogComponent_0, RenderType_DialogComponent)), i0.ɵdid(1, 180224, null, 0, i4.DialogComponent, [i5.BaseComponentService], null, null)], null, null); }
var DialogComponentNgFactory = i0.ɵccf("ng-component", i4.DialogComponent, View_DialogComponent_Host_0, {}, {}, []);
export { DialogComponentNgFactory as DialogComponentNgFactory };
