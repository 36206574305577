import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';

import {
  AppBaseComponent,
  AppBaseComponentService,
  IPagingInfo,
  ISmilesOrder,
  IUser,
  PagingWrapper,
  SmilesService,
  SupporterProfile,
  SupporterService
} from '../shared';

@Component({
  selector: 'wv-supporter-details',
  templateUrl: './supporter-details.component.html'
})
export class SupporterDetailsComponent extends AppBaseComponent implements OnInit {
  public supporterProfile: SupporterProfile;
  public supporterId: number;
  public pagingWrapper: PagingWrapper<ISmilesOrder>;
  public isLoadingOrders = false;
  public take: number;
  public users: Array<IUser>;
  public isLoadingUsers = false;

  constructor(
    private readonly baseServices: AppBaseComponentService,
    private readonly smilesService: SmilesService,
    private readonly route: ActivatedRoute,
    private readonly supporterService: SupporterService) {
    super(baseServices);
    const tablePageSizeItems = this.base.appConfig.getConfig('tablePageSizeItems');
    this.take = tablePageSizeItems[0];
  }

  ngOnInit(): void {
    this.addSubscription(this.route.params.subscribe(params => {
      this.supporterId = params.id;
      this.getSupporterDetails();
    }));
    this.getSupporterDetails();
  }

  public onPagingChange(paging: IPagingInfo): void {
    if (paging) {
      this.take = paging.take;
      this.getOrders(paging.skip, paging.take);
    }
  }

  public beforeChange($event: NgbPanelChangeEvent): void {
    if ($event.panelId === 'panel-orders' && !this.pagingWrapper) {
      this.getOrders(0, this.take);

      return;
    }
    if ($event.panelId === 'panel-users' && !this.users) {
      this.getUsers();
    }
  }

  public getSupporterDetails(): void {
    this.loadingState.setLoading();
    this.supporterService.getSupporter(this.supporterId)
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe((resp: SupporterProfile) => {
        this.supporterProfile = resp;
      }, resp => {
        this.apiErrorHandlerService.handleError(resp);
      });
  }

  public getOrders(skip: number, take: number): void {
    if (this.supporterId) {
      this.isLoadingOrders = true;
      this.smilesService.getSmilesOrdersBySearchTerm(this.supporterId, take, skip)
        .pipe(finalize(
          () => this.isLoadingOrders = false))
        .subscribe(resp => {
          this.pagingWrapper = resp;
        }, resp => {
          this.apiErrorHandlerService.handleError(resp);
        });

      return;
    }
    this.dialogService.showInformation(undefined, 'Please provide a supporter ID');
  }

  public getUsers(): void {
    this.isLoadingUsers = true;
    this.supporterService.getUsersBySupporter(this.supporterId)
      .pipe(finalize(
        () => this.isLoadingUsers = false))
      .subscribe(users => {
        this.users = users;
      }, resp => {
        this.apiErrorHandlerService.handleError(resp);
      });
  }

  public getPrimaryPhoneNumberString(): string {
    const phone = this.supporterProfile.primaryPhoneNumber;
    if (phone) {

      return `${phone.countryCode}-${phone.areaCode}-${phone.nationalNumber}`;
    }

    return '';
  }
}
