import { Injectable, Injector } from '@angular/core';

import { AppConfig } from '@core';

@Injectable()
export class ApplicationInitialisationService {
  private config: AppConfig;

  constructor(
    protected injector: Injector
  ) { }

  public async initialise(): Promise<any> {
    this.config = this.injector.get(AppConfig);

    return this.config.load();
  }
}
