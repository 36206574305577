import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { MasterRoutingModule } from './master-routing.module';

import { LastViewedSupportersComponent } from './last-viewed-supporters/last-viewed-supporters.component';
import { MasterComponent } from './master.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';

@NgModule({
  declarations: [
    LastViewedSupportersComponent,
    MasterComponent,
    StyleGuideComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,

    CoreModule,
    SharedModule,
    MasterRoutingModule
  ],
  exports: [
    MasterComponent
  ]
})
export class MasterModule { }
