<form (ngSubmit)="onSubmit(loginForm)" class="needs-validation" #loginForm="ngForm" novalidate>
  <div class="dashboard-content">
    <div class="sign-in">
      <h1>Sign in to World Vision Admin Portal</h1>

      <button id="dropDownLogin" type="submit" class="btn btn-p"
              [ladda]="signingIn"
              data-spinner-color="#ffffff">
        Sign in
      </button>
    </div>
  </div>
</form>
