import { ComponentFactoryResolver, ComponentRef, Type, ViewChild, ViewContainerRef } from '@angular/core';

import { BaseComponent } from '../base.component';
import { BaseComponentService } from '../services/base-component.service';

/**
 * This component allows you to include a dynamically created component within its template.
 *
 * How to use:
 * 1. Subclass this component.
 * 2. Include a tag with #dynamicContent in the template e.g. <ng-template #dynamicContent></ng-template>
 * 3. Call the init() function with the type of the component to include and any parameters to pass to the component.
 */
export abstract class DynamicContainerComponent extends BaseComponent {

  @ViewChild('dynamicContent', { read: ViewContainerRef })
  public dynamicContent: ViewContainerRef;

  constructor(
    baseServices: BaseComponentService,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {
    super(baseServices);
  }

  public init<ComponentType>(contentComponentType: Type<ComponentType>, parameters?: Object): ComponentRef<ComponentType> {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(contentComponentType);

    this.dynamicContent.clear();
    const contentComponent = this.dynamicContent.createComponent(componentFactory);

    Object.assign(contentComponent.instance, parameters);

    return contentComponent;
  }
}
