import { BrowserStorageService } from '@shared';

// immutable sign in state

export class SignInState {
  constructor(private readonly browserStorageService: BrowserStorageService) {
  }

  public get returnUrl(): string {
    return this.getItem('returnUrl');
  }

  public get emailAddress(): string {
    return this.getItem('emailAddress');
  }

  private getItem(key: string): string {
    return this.browserStorageService.getItem(`signInState.${key}`);
  }
}
