import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { LoadingStateManager } from '@core';
import { AppBaseComponent, AppBaseComponentService, ChosenPhotosService } from '@shared';

@Component({
  selector: 'wv-chosen-photos-list',
  templateUrl: './chosen-photos-list.component.html'
})
export class ChosenPhotosListComponent extends AppBaseComponent implements OnInit {

  public model: any = {};
  public chosenPhotos: any;
  public total: number;
  public readonly pageSize = 20;
  public page = 1;
  public searchState = new LoadingStateManager();

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    public readonly chosenPhotosService: ChosenPhotosService) {
    super(base);
  }

  public ngOnInit(): void {

    this.loadingState.setLoading();

    const params = this.route.snapshot.queryParams;
    this.page = params.page ? Number.parseInt(params.page, 10) : 1;
    this.model.transactionId = params.transactionId;
    this.model.supporterId = params.supporterId;

    this.loadPage(this.page, this.pageSize);
  }

  public pageChange(pageNumber: number): void {
    this.updateFilters();
    this.loadPage(pageNumber, this.pageSize);
  }

  public onSubmit(_form: NgForm): void {
    this.page = 1;
    this.updateFilters();
    this.loadPage(this.page, this.pageSize);
  }

  private updateFilters(): void {
    this.base.router.navigate([], {
      queryParams: {
        page: this.page,
        transactionId: this.model.transactionId,
        supporterId: this.model.supporterId
      }
    })
    .catch();
  }

  private loadPage(page: number, pageSize: number): void {

    if (this.loadingState.isLoaded()) {
      this.loadingState.setRefreshing();
    }

    this.chosenPhotosService.find({
      skip: (page - 1) * this.pageSize,
      take: pageSize,
      transactionId: this.model.transactionId,
      supporterId: this.model.supporterId
    })
      .subscribe(chosenPhotos => {
        this.loadingState.setLoaded();
        this.total = chosenPhotos.total;
        this.chosenPhotos = chosenPhotos.items;
      });
  }
}
