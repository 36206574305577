import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { SignInRoutingModule } from './sign-in-routing.module';

import { SignInActionService } from './sign-in-action.service';
import { SignInRedirectResolve } from './sign-in-redirect.resolve';
import { SignInStateService } from './sign-in-state.service';
import { SignInComponent } from './sign-in.component';

@NgModule({
  declarations: [
    SignInComponent
  ],
  exports: [
    SignInComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,

    LaddaModule,

    CoreModule,
    SharedModule,
    SignInRoutingModule
  ],
  providers: [
    SignInRedirectResolve,
    SignInActionService,
    SignInStateService
  ]
})
export class SignInModule { }
