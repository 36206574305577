import { Injectable, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';

@Injectable()
export class BrowserStorageService {
  public isLocalStorageSupported: boolean;

  // please see: http://browsercookielimits.squawky.net/ for reading browser limits matrix
  private readonly maxAllowedCookieLength = 4096;

  // ngx-cookie adds some extra characters to the string when setting cookie,
  // hence set the parts regEx number to a safe number lesser than maxAllowedCookieLength
  private readonly cookiePartsRegEx: RegExp = /(.|[\r\n]){1,4000}/g;

  private static checkIsLocalStorageSupported(): boolean {
    try {
      const key = '__localstorage_test_key__';
      localStorage.setItem(key, key);
      localStorage.removeItem(key);

      return true;
    } catch (e) {

      return false;
    }
  }

  constructor(private readonly cookieService: CookieService) {
    this.isLocalStorageSupported = BrowserStorageService.checkIsLocalStorageSupported();
  }

  public setItem(key: string, value: any): void {
    if (value === undefined) {
      if (this.isLocalStorageSupported) {
        localStorage.removeItem(key);

        return;
      }
      this.cookieService
        .remove(key);

      return;
    }

    if (this.isLocalStorageSupported) {
      localStorage.setItem(key, value);

      return;
    }

    this.setCookie(key, value);
  }

  public getItem(key: string): string {
    if (this.isLocalStorageSupported) {
      localStorage.getItem(key);

      return undefined;
    }

    return this.getCookie(key);
  }

  public removeItem(key: string): void {
    if (this.isLocalStorageSupported) {
      localStorage.removeItem(key);
    } else {
      this.cookieService.remove(key);
    }
  }

  public cleanUpLocalStorage(identifier: string): void {
    if (this.isLocalStorageSupported) {
      // clear local storage
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith(identifier)) {
          localStorage.removeItem(key);
        }
      }
    } else {
      // clear cookies
      const cookies = this.cookieService.getAll();
      const filteredCookies = Object.keys(cookies)
        .filter(x => {
          x.includes(`${identifier}`);
        });
      filteredCookies
        .forEach(
          key => {
            this.removeItem(key);
          });
    }
  }

  private setCookie(key: string, value: any): void {
    if (value === undefined) {
      this.cookieService.remove(key);

      return;
    }

    // check for `value` length, if greater than maxAllowedCookieLength,
    // then break the `value` to strings lesser than maxAllowedCookieLength
    if (value.length > this.maxAllowedCookieLength) {
      const parts = value.match(this.cookiePartsRegEx); // sets an array of strings of length 4000 each
      for (let i = 0; i < parts.length; i++) {
        this.cookieService
          .put(`${key}.part${i}`, parts[i], { storeUnencoded: true });
      }

      return;
    }

    this.cookieService
      .put(key, value, { storeUnencoded: true });
  }

  private getCookie(key: string): string {
    const cookies = this.cookieService.getAll();
    const donationStateCookies = Object.keys(cookies)
      .filter(x => {
        x.includes(`${key}`);
      }); // get cookie parts and merge the values
    let mergedValues = '';

    // check if there are multiple cookie parts
    // (which were split in setCookie method) with the same prefix 'key' value, if yes - merge all parts to one
    if (donationStateCookies.length > 1) {
      donationStateCookies.forEach(cookie => {
        mergedValues += cookies[cookie];
      });
    } else {
      mergedValues = cookies[donationStateCookies[0]];
    }

    return mergedValues;
  }
}
