import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, LastViewedSupportersService, SupporterService } from '@shared';

@Component({
  selector: 'wv-supporters',
  templateUrl: './supporters.component.html'
})
export class SupportersComponent extends AppBaseComponent {
  public model: any = {};

  constructor(
    private readonly baseServices: AppBaseComponentService,
    private readonly supporterService: SupporterService,
    private readonly lastViewedSupportersService: LastViewedSupportersService,
    private readonly route: ActivatedRoute) {
    super(baseServices);
  }

  public onSubmit(form: NgForm): boolean {
    if (form.invalid) {

      return false;
    }

    this.getSupporterDetails();

    return true;
  }

  public getSupporterDetails(): void {
    const supporterId = this.model.supporterId;
    this.loadingState.setLoading();
    this.supporterService.getSupporter(supporterId)
      .pipe(finalize(
        () => {
          this.loadingState.setLoaded();
        }))
      .subscribe(async resp => {
        this.lastViewedSupportersService.addLastViewedSupporters(
          {
            id: supporterId,
            firstName: resp.firstName,
            lastName: resp.lastName,
            emailAddress: resp.emailAddress
          });

        return this.base.router.navigate(['/supporters', supporterId]);
      }, resp => {
        this.apiErrorHandlerService.handleError(resp,
          `No result found. Your search <i> ${supporterId} </i> did not match any records.`);
      });
  }
}
