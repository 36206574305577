import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from '@auth';

import { FundraisingCreateEchoTeamProfileComponent } from './create-echo-team-profile.component';
import { FundraisingNewTeamTabsComponent } from './new-team-tabs.component';
import { FundraisingProfileDetailsComponent } from './profile-details.component';
import { FundraisingSearchProfilesComponent } from './search-profiles.component';
import { FundraisingSearchTeamsComponent } from './search-teams.component';
import { FundraisingTeamDetailsComponent } from './team-details.component';

const routes: Routes = [
  { path: 'fundraising/teams', component: FundraisingSearchTeamsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'fundraising/teams/create', component: FundraisingNewTeamTabsComponent, canActivate: [CanActivateAuthGuard] },
  {
    path: 'fundraising/teams/:id/profiles/create',
    component: FundraisingCreateEchoTeamProfileComponent, canActivate: [CanActivateAuthGuard]
  },
  { path: 'fundraising/teams/:id', component: FundraisingTeamDetailsComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'fundraising/profiles', component: FundraisingSearchProfilesComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'fundraising/profiles/:id', component: FundraisingProfileDetailsComponent, canActivate: [CanActivateAuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class FundraisingRoutingModule { }
