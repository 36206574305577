import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PagingWrapper } from '../models/paging-wrapper';
import { ISmilesGift } from '../models/smiles-gift';
import { ISmilesOrder } from '../models/smiles-order';
import { BaseService } from './base.service';

@Injectable()
export class SmilesService extends BaseService {
  public static readonly smilesServiceUrl = 'admin/smiles';

  public getSmilesGift(id: string): Observable<ISmilesGift> {
    return this.get<ISmilesGift>(`${SmilesService.smilesServiceUrl}/gifts/${id}`);
  }

  public getSmilesOrdersBySearchTerm(searchTerm: number, take: number, skip = 0): Observable<PagingWrapper<ISmilesOrder>> {
    return this.get<PagingWrapper<ISmilesOrder>>(
      `${SmilesService.smilesServiceUrl}/orders?searchTerm=${searchTerm}&Take=${take}&Skip=${skip}`);
  }

  public getSmilesOrderByOrderId(orderId: number): Observable<ISmilesOrder> {
    return this.get<ISmilesOrder>(`${SmilesService.smilesServiceUrl}/orders/${orderId}`);
  }

  public updateSmilesGift(id: string, emailAddress: string, sendDate: string): Observable<any> {
    const model: ISmilesGift = {
      recipientEmailAddress: emailAddress,
      sendDate
    };

    return this.put(`${SmilesService.smilesServiceUrl}/gifts/${id}`, model);
  }

  public resendSmilesGiftEmail(id: string): Observable<any> {
    const operation = {
      operation: 'resendSmilesGiftEmail',
      data: {}
    };

    return this.patch(`${SmilesService.smilesServiceUrl}/gifts/${id}`, operation);
  }
}
