<form class="container">
  <h1>Search Fundraising Profiles</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <div *ngIf="profileSearching">
        <div class="form-row">
          <div class="col-md-8 mb-3">
            <label class="control-label" for="profileSearchTerm">Enter owner's supporter ID or start typing profile name</label>
            <input class="form-control"
                   id="profileSearchTerm"
                   name="profileSearchTerm"
                   autofocus
                   autocomplete="off"
                   (keyup)="changeProfileSearchTerm($event.target.value)"
                   [(ngModel)]="model.dummy">
          </div>
        </div>
        <div class="form-row">
          <p>{{ searchStatus }}</p>
        </div>
        <div class="form-row">
            <table class="search-results col-md-8 mb-3" *ngIf="profiles && profiles.length">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Campaign Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr class="clickable" *ngFor="let profile of profiles" (click)="selectMatchedProfile(profile)">
                  <td>{{ profile.name }}</td>
                  <td>{{ profile.campaignName }}</td>
                  <td>{{ profile.searchProfileType }}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>
  </div>
</form>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
