import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';

import { AppBaseComponent, AppBaseComponentService, IFundraisingTeam, ISupporterStub, TeamsService } from '@shared';
import { FundraisingTeamOwnerModalComponent } from './team-owner-modal.component';

@Component({
  selector: 'wv-team-details',
  templateUrl: './team-details.component.html'
})
export class FundraisingTeamDetailsComponent extends AppBaseComponent implements OnInit {
  public team: IFundraisingTeam;
  public supporters: Array<ISupporterStub> = [];
  public isPerformingAction = false;
  public teamId: string;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly teamsService: TeamsService,
    private readonly route: ActivatedRoute) {
    super(baseServices);
  }

  ngOnInit(): void {
    this.teamId = this.route.snapshot.params.id;
    this.getTeam();
  }

  public removeOwner(): void {
    const ownerName = `${this.team.owner.firstName} ${this.team.owner.lastName}`;
    this.base.dialogService.showConfirmation(
      'Remove Team Owner',
      `Are you sure you want to remove <i>${ownerName}</i> as team owner?`, 'Remove', () => {
        this.isPerformingAction = true;
        this.teamsService.removeOwner(this.teamId)
          .pipe(finalize(() =>
            this.isPerformingAction = false))
          .subscribe(
            () => {
              this.base.dialogService.showInformation('Success', 'Team owner removed successfully');
              this.team.owner = undefined;
            }, resp => {
              this.apiErrorHandlerService.handleError(resp);
            });
      });
  }

  public showAddOwnerModal(): void {
    this.modalService.create(FundraisingTeamOwnerModalComponent, { teamId: this.teamId })
      .subscribe(() => {
        this.getTeam();
      });
  }

  private getTeam(): void {
    this.loadingState.setLoading();
    this.teamsService.getTeamDetails(this.teamId)
      .pipe(finalize(() => {
        this.loadingState.setLoaded();
      }))
      .subscribe(
        resp => {
          this.team = resp;
        },
        resp => {
          this.apiErrorHandlerService.handleError(resp);
        });
  }
}
