import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FundraisingCampaignStub } from '../../models/fundraising-campaign-stub';
import { BaseService } from '../base.service';

@Injectable()
export class CampaignsService extends BaseService {

  public getAll(): Observable<Array<FundraisingCampaignStub>> {
    return this.get<Array<FundraisingCampaignStub>>('admin/fundraising/campaigns/');
  }
}
