import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppBaseComponent, AppBaseComponentService } from '@shared';
import { EmailPreviewService } from './services/email-preview-service';

@Component({
  selector: 'wv-email-preview-sidenav',
  templateUrl: './email-preview-sidenav.component.html'
})
export class EmailPreviewSideNavComponent extends AppBaseComponent implements OnInit {
  public routes: any;
  public variants: any;
  public secondarySection: string;
  public activeItemId = 1;
  public expanded: any;
  public expandedString = 'expanded';
  public previewEmailPath = '/previews/emails';

  constructor(
    base: AppBaseComponentService,
    private readonly activateRoute: ActivatedRoute,
    private readonly emailPreviewService: EmailPreviewService) {
    super(base);
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(params => {
      // fetch all donationThankYou sub-routes
      this.routes = this.emailPreviewService.loadAllRoutes();

      this.initNav();
    });
  }

  public toggleExpanded(i: string): void {
    // kludge: to make the aria expanded (+/- on accordion) work as expected
    const expandedNone = `${this.expandedString}x`;
    this.expanded = (this.expanded !== this.expandedString + i)
      ? this.expandedString + i
      : expandedNone;
  }

  public getPanelId(route: any, index: string): string {
    return (route.child ? 'ngbPanel' : 'prevent-ngbPanel') + index;
  }

  private initNav(): void {
    this.activeItemId = this.routes.findIndex(r => this.base.router.url.includes(`/${(r.path as string)}`));
    this.expanded = this.expandedString + this.activeItemId.toString();
  }
}
