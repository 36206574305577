import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PagingWrapper } from '@shared';
import { ITransaction } from '../models/transaction';
import { ITransactionCommitment } from '../models/transaction-commitment';
import { ITransactionQuery } from '../models/transaction-query';
import { BaseService } from './base.service';

@Injectable()
export class TransactionService extends BaseService {

  public find(query: ITransactionQuery): Observable<PagingWrapper<ITransaction>> {
    return this.get<PagingWrapper<ITransaction>>(`admin/transactions?${BaseService.objectToQueryString(query)}`);
  }

  public getById(id: string): Observable<ITransaction> {
    return this.get<ITransaction>(`admin/transactions/${id}`);
  }

  public getCommitmentsByTransactionId(id: string): Observable<Array<ITransactionCommitment>> {
    return this.get<Array<ITransactionCommitment>>(`admin/transactions/${id}/commitments`);
  }

  public getTransactionStatuses(): Observable<Array<string>> {
    return this.get<Array<string>>('admin/transaction-statuses');
  }

  public getTransactionTypes(): Observable<Array<string>> {
    return this.get<Array<string>>('admin/transaction-types');
  }

  public getProductTypes(): Observable<Array<string>> {
    return this.get<Array<string>>('admin/product-types');
  }
}
