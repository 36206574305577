import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  public transform(value: string, limit = 25, completeWords = false, ellipsis = '...'): string {
    if (value === undefined) {
      return '';
    }

    const effectiveLimit = completeWords
      ? value
        .substr(0, limit)
        .lastIndexOf(' ')
      : limit;

    if (effectiveLimit <= 0) {
      return '';
    }

    return value.length > effectiveLimit
      ? value.substr(0, effectiveLimit) + ellipsis
      : value;
  }
}
