/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./supporter-users-list.component";
import * as i4 from "../shared/app-base-component.service";
var styles_SupporterUserListComponent = [];
var RenderType_SupporterUserListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SupporterUserListComponent, data: {} });
export { RenderType_SupporterUserListComponent as RenderType_SupporterUserListComponent };
function View_SupporterUserListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, "tr", [["class", "clickable"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 16384, null, 0, i1.RouterLink, [i1.Router, i1.ActivatedRoute, [8, null], i0.Renderer2, i0.ElementRef], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(2, 2), (_l()(), i0.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i0.ɵeld(8, 0, null, null, 1, "span", [["class", "badge"]], [[2, "badge-success", null], [2, "badge-secondary", null]], null, null, null, null)), (_l()(), i0.ɵted(9, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 2, 0, "/users", _v.context.$implicit.userId); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit.userId; _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.context.$implicit.username; _ck(_v, 6, 0, currVal_2); var currVal_3 = _v.context.$implicit.enabled; var currVal_4 = !_v.context.$implicit.enabled; _ck(_v, 8, 0, currVal_3, currVal_4); var currVal_5 = (_v.context.$implicit.enabled ? "Enabled" : "Disabled"); _ck(_v, 9, 0, currVal_5); }); }
function View_SupporterUserListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["User ID"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Username"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Status"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SupporterUserListComponent_2)), i0.ɵdid(12, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.users; _ck(_v, 12, 0, currVal_0); }, null); }
function View_SupporterUserListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["No users found"]))], null, null); }
export function View_SupporterUserListComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_SupporterUserListComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SupporterUserListComponent_3)), i0.ɵdid(3, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.users && _co.users.length); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.users && !_co.users.length); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SupporterUserListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-supporter-users-list", [], null, null, null, View_SupporterUserListComponent_0, RenderType_SupporterUserListComponent)), i0.ɵdid(1, 180224, null, 0, i3.SupporterUserListComponent, [i4.AppBaseComponentService], null, null)], null, null); }
var SupporterUserListComponentNgFactory = i0.ɵccf("wv-supporter-users-list", i3.SupporterUserListComponent, View_SupporterUserListComponent_Host_0, { users: "users" }, {}, []);
export { SupporterUserListComponentNgFactory as SupporterUserListComponentNgFactory };
