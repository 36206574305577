<form *ngIf="chosenPhotos" class="container">
  <h1>Chosen Photo Details</h1>
  <hr align="left" />

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Transaction ID</label>
      <input type="text" class="form-control" [value]="chosenPhotos.id" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>GUID</label>
      <input type="text" class="form-control" [value]="chosenPhotos.guid" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Trigger</label>
      <input type="text" class="form-control" [value]="chosenPhotos.trigger" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Supporter ID</label>
      <input type="text" class="form-control" [value]="chosenPhotos.supporterId" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Event Code</label>
      <input type="text" class="form-control" [value]="chosenPhotos.eventCode" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>File Name</label>
      <input type="text" class="form-control" [value]="chosenPhotosService.cleanPhotoFileName(chosenPhotos.fileName)" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>File Type</label>
      <input type="text" class="form-control" [value]="chosenPhotos.fileType" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Created Date</label>
      <input type="text" class="form-control" [value]="chosenPhotos.createdDate | date: 'dd MMM yyyy'" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Photo</label>
      <img [src]="appConfig.getConfig('uploadPath').authority + chosenPhotos.fileName" class="img-thumbnail" *ngIf="chosenPhotos.fileName" />
    </div>
  </div>
</form>

<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
