var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, TeamsService } from '@shared';
import { FundraisingTeamOwnerModalComponent } from './team-owner-modal.component';
var FundraisingTeamDetailsComponent = /** @class */ (function (_super) {
    __extends(FundraisingTeamDetailsComponent, _super);
    function FundraisingTeamDetailsComponent(baseServices, teamsService, route) {
        var _this = _super.call(this, baseServices) || this;
        _this.teamsService = teamsService;
        _this.route = route;
        _this.supporters = [];
        _this.isPerformingAction = false;
        return _this;
    }
    FundraisingTeamDetailsComponent.prototype.ngOnInit = function () {
        this.teamId = this.route.snapshot.params.id;
        this.getTeam();
    };
    FundraisingTeamDetailsComponent.prototype.removeOwner = function () {
        var _this = this;
        var ownerName = this.team.owner.firstName + " " + this.team.owner.lastName;
        this.base.dialogService.showConfirmation('Remove Team Owner', "Are you sure you want to remove <i>" + ownerName + "</i> as team owner?", 'Remove', function () {
            _this.isPerformingAction = true;
            _this.teamsService.removeOwner(_this.teamId)
                .pipe(finalize(function () {
                return _this.isPerformingAction = false;
            }))
                .subscribe(function () {
                _this.base.dialogService.showInformation('Success', 'Team owner removed successfully');
                _this.team.owner = undefined;
            }, function (resp) {
                _this.apiErrorHandlerService.handleError(resp);
            });
        });
    };
    FundraisingTeamDetailsComponent.prototype.showAddOwnerModal = function () {
        var _this = this;
        this.modalService.create(FundraisingTeamOwnerModalComponent, { teamId: this.teamId })
            .subscribe(function () {
            _this.getTeam();
        });
    };
    FundraisingTeamDetailsComponent.prototype.getTeam = function () {
        var _this = this;
        this.loadingState.setLoading();
        this.teamsService.getTeamDetails(this.teamId)
            .pipe(finalize(function () {
            _this.loadingState.setLoaded();
        }))
            .subscribe(function (resp) {
            _this.team = resp;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    return FundraisingTeamDetailsComponent;
}(AppBaseComponent));
export { FundraisingTeamDetailsComponent };
