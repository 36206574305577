/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./loader.component";
var styles_LoaderComponent = [];
var RenderType_LoaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LoaderComponent, data: {} });
export { RenderType_LoaderComponent as RenderType_LoaderComponent };
export function View_LoaderComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "loader"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "cube1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "div", [["class", "cube2"]], null, null, null, null, null))], null, null); }
export function View_LoaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wv-loader", [], null, null, null, View_LoaderComponent_0, RenderType_LoaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.LoaderComponent, [], null, null)], null, null); }
var LoaderComponentNgFactory = i0.ɵccf("wv-loader", i1.LoaderComponent, View_LoaderComponent_Host_0, {}, {}, []);
export { LoaderComponentNgFactory as LoaderComponentNgFactory };
