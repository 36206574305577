<div class="container" *ngIf="smilesGifts && smilesGifts.length">
  <h1>Smiles Gifts</h1>
  <hr class="heading-hr" align="left" />
  <table class="table table-striped" *ngIf="smilesGifts && smilesGifts.length">
    <thead>
      <tr>
        <th scope="col">Gift Type</th>
        <th scope="col">Send Date</th>
        <th scope="col">Sent Date</th>
        <th scope="col">Recipient Email Address</th>
        <th scope="col">Send Error</th>
      </tr>
    </thead>
    <tbody>
    <tr class="clickable" *ngFor="let gift of smilesGifts" [routerLink]="['/smiles-gifts', gift.id]"
        [ngClass]="[gift.sendError !== null ? 'table-danger' : '']">
        <td>{{ gift.giftType }}</td>
        <td [title]="gift.sendDate | date: 'dd MMM yyyy hh:mm:ss a'">{{ gift.sendDate | date: 'dd MMM yyyy' }}</td>
        <td [title]="gift.sentDate | date: 'dd MMM yyyy hh:mm:ss a'">{{ gift.sentDate | date: 'dd MMM yyyy' }}</td>
        <td>{{ gift.recipientEmailAddress }}</td>
        <td>{{ gift.sendError }}</td>
      </tr>
    </tbody>
  </table>
</div>
<h3 *ngIf="smilesGifts && !smilesGifts.length">No Smiles Gift found</h3>
<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
