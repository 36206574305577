<form class="supporter-search container" (ngSubmit)="onSubmit(reportForm)" #reportForm="ngForm" novalidate>
  <h1>Reports - Children</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <label for="poolCode">Pool Code</label>
      <input type="text" class="form-control"
             id="poolCode"
             name="poolCode"
             required
             placeholder="Pool Code"
             [(ngModel)]="model.poolCode"
             #poolCode="ngModel" />
    </div>
    <wv-field-helper id="poolCodeHelper" [control]="poolCode"
                     [errorMessages]="{'required': 'Please enter a Pool Code'}">
    </wv-field-helper>
    <div class="col-md-8 mb-3">
      <div class="pull-right action-group">
        <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isLoading()">Generate</button>
      </div>
    </div>
  </div>

</form>
