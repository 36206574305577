<div class="modal-dialog modal-lg">
  <div class="modal-content">
    <form (ngSubmit)="onSubmit(changeSupporterForm)"
          #changeSupporterForm="ngForm"
          novalidate>
      <div class="modal-header">
        <h4 class="modal-title">Change Supporter</h4>
      </div>
      <div class="modal-body">
        <div class="form-row">
          <div class="col-md-8 mb-3">
            <div>
              <label class="control-label" for="newSupporterId">New Supporter ID</label>
              <input class="form-control"
                     id="newSupporterId"
                     name="newSupporterId"
                     required
                     [(ngModel)]="model.newSupporterId"
                     #newSupporterId="ngModel">
            </div>
            <wv-field-helper class="inline-block" id="newSupporterIdHelper" [control]="newSupporterId"
                             [errorMessages]="{'required': 'Please enter a supporter ID'}">
            </wv-field-helper>
          </div>          
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-s" (click)="close()" type="button">Cancel</button>
        <button class="btn btn-p" [ladda]="isLoading" type="submit">Confirm</button>
      </div>
    </form>
  </div>
</div>
