var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, PreviewService } from '@shared';
import { EmailPreviewService } from './services/email-preview-service';
var EmailPreviewComponent = /** @class */ (function (_super) {
    __extends(EmailPreviewComponent, _super);
    function EmailPreviewComponent(base, previewService, route, emailPreviewService) {
        var _this = _super.call(this, base) || this;
        _this.previewService = previewService;
        _this.route = route;
        _this.emailPreviewService = emailPreviewService;
        _this.isPreviewLoading = false;
        _this.preview = {};
        return _this;
    }
    EmailPreviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addSubscription(this.route.params.subscribe(function (params) {
            _this.initData(params)
                .subscribe(function (resp) { return _this.data = resp; }, function (err) { return _this.apiErrorHandlerService.handleError(err); });
        }));
    };
    EmailPreviewComponent.prototype.initData = function (params) {
        var type = params.type;
        var subType = params.subType;
        var variant = params.variant;
        this.preview = this.emailPreviewService.loadSelectedTypePreview(type, subType, variant);
        var previewType = this.preview.typeName;
        var model = this.preview ? this.preview.data : undefined;
        var location = "/previews/emails/" + type;
        if (!subType && this.preview.subType) {
            location += "/" + this.preview.subType.path;
            return from(this.base.router.navigate([location]));
        }
        if (!variant && this.preview.subType && this.preview.subType.child) {
            location += "/" + this.preview.subType.path + "/" + this.preview.variant.path;
            return from(this.base.router.navigate([location]));
        }
        return this.loadPreview(previewType, model);
    };
    EmailPreviewComponent.prototype.loadPreview = function (type, model) {
        var _this = this;
        this.isPreviewLoading = true;
        return this.previewService.getEmailPreview(type, model)
            .pipe(finalize(function () {
            _this.isPreviewLoading = false;
        }));
    };
    return EmailPreviewComponent;
}(AppBaseComponent));
export { EmailPreviewComponent };
