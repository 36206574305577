<form *ngIf="user" class="container">
  <h1>User Details</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="userId">ID</label>
      <input type="text" class="form-control" id="userId" name="userId"
             [(ngModel)]="user.userId" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label for="username">Username</label>
      <input type="text" class="form-control" id="username" name="username"
             [(ngModel)]="user.username" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="primarySupporterId">Supporter ID</label>
      <input type="text" class="form-control" id="primarySupporterId" name="primarySupporterId"
             [(ngModel)]="user.primarySupporterId"
             disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>&nbsp;</label>
      <input type="text" class="form-control-plaintext"
             [value]="user.primarySupporterType ? ('(' + user.primarySupporterType + ') ' + user.firstName + ' ' + user.lastName) : 'Unknown'"
             disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="createdDate">Created Date</label>
      <input type="text" class="form-control" id="createdDate" name="createdDate"
             [value]="user.createdDate | date: 'dd MMM yyyy'"
             [title]="user.createdDate | date: 'dd MMM yyyy hh:mm:ss a'"
             disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Status</label>
      <h2>
        <span class="badge"
              [class.badge-success]="user.enabled"
              [class.badge-secondary]="!user.enabled">
          {{ user.enabled ? 'Enabled' : 'Disabled' }}
        </span>
      </h2>
    </div>
  </div>
  <hr />
  <div class="form-row">
    <div class="pull-right action-group">
      <button class="btn btn-p pull-right"
              [ladda]="isPerformingAction"
              (click)="impersonateUser()"
              title="Login to My World Vision as {{user.firstName}}">
        Impersonate User
      </button>

      <button class="btn btn-s pull-right"
              style="margin-right: 10px"
              [ladda]="isPerformingAction"
              (click)="changeSupporter()"
              title="Change Supporter">
        Change Supporter
      </button>
    </div>
  </div>
</form>

<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
