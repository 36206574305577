import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AppBaseComponent, AppBaseComponentService, ChosenPhotosService } from '@shared';
import { forkJoin } from 'rxjs';
import { IChosenPhotos } from '../../shared/models/chosen-photos';

@Component({
  selector: 'wv-transaction-details',
  templateUrl: './chosen-photos-details.component.html'
})
export class ChosenPhotosDetailsComponent extends AppBaseComponent implements OnInit {
  public chosenPhotos: IChosenPhotos;

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    public readonly chosenPhotosService: ChosenPhotosService
  ) {
    super(base);
   }

  public ngOnInit(): void {
    this.loadingState.setLoading();

    forkJoin(
      this.chosenPhotosService.getById(this.route.snapshot.params.id)
    )
    .subscribe(([chosenPhotos, commitments]) => {
      this.loadingState.setLoaded();
      this.chosenPhotos = chosenPhotos;
    });
  }
}
