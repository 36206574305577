import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { catchError, flatMap, map, tap } from 'rxjs/operators';
import { AppConfig } from '../../core/app.config';
import { OAuthTokenResponse } from '../models/oauth-token-reponse';
import { AuthStateService } from './auth-state.service';

const clientId = 'web';
const scope = 'api1';

@Injectable()
export class AuthService {
  private readonly apiUrlBase: string;
  private readonly headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
  private readonly options: any = { headers: this.headers, withCredentials: false, responseType: 'json' };

  private static objectToQueryString(params: any): string {
    // use our own query string builder instead of URLSearchParams because of issues encoding the '+' character.
    let queryString = '';
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        if (queryString.length > 0) {
          queryString += '&';
        }

        queryString += `${String(key)}=${encodeURIComponent(params[key])}`;
      }
    }

    return queryString;
  }

  constructor(
    private readonly config: AppConfig,
    private readonly httpClient: HttpClient,
    private readonly authStateService: AuthStateService,
    private readonly router: Router) {
    this.apiUrlBase = config.getConfig('auth').authority as string;
  }

  public authenticate(username: string, password: string, remember?: boolean, recaptcha?: string): Observable<OAuthTokenResponse> {

    const scopes: Array<string> = [scope];
    if (remember) {
      scopes.push('offline_access');
    }

    const params = {
      grant_type: 'password',
      client_id: clientId,
      username,
      password,
      captcha: recaptcha,
      scope: scopes.join(' '),
      is_admin_login: true
    };

    return this.authenticateInternal(params);
  }

  public useRefreshToken(refreshToken: string): Observable<OAuthTokenResponse> {
    const params = {
      grant_type: 'refresh_token',
      client_id: clientId,
      refresh_token: refreshToken
    };

    return this.authenticateInternal(params);
  }

  public ensureAuthentication(): Observable<any> {
    const authState = this.authStateService.current;
    const accessToken = authState.accessToken;

    if (accessToken) {

      if (authState.isAuthenticated) {
        return of(authState);
      } else {
        return throwError(false);
      }
    }

    // Use refresh token if necessary

    const refreshToken = authState.refreshToken;

    if (!refreshToken) {

      return throwError(false);
    }

    return this.useRefreshToken(refreshToken)
      .pipe(
        flatMap((a: any) => {
          this.authStateService.setTokens(a.access_token, a.refresh_token, a.expires_in);

          return of(this.authStateService.current);
        }),
        catchError(err => {
          this.authStateService.clearState();

          return throwError(false);
        }));
  }

  public logout(): void {
    this.authStateService.clearState();

    window.location.href = this.config.getConfig('auth0').logoutUrl;
  }

  private authenticateInternal(params: any): Observable<OAuthTokenResponse> {
    return this.httpClient
      .post<OAuthTokenResponse>(`${this.apiUrlBase}/connect/token`, AuthService.objectToQueryString(params), this.options)
      .pipe(tap(oauthToken => oauthToken as any)) as Observable<OAuthTokenResponse>;
  }
}
