<div *ngIf="users && users.length">
  <table class="table table-striped">
    <thead>
    <tr>
      <th>User ID</th>
      <th>Username</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>
    <tr class="clickable"
        *ngFor="let user of users"
        [routerLink]="['/users',user.userId]">
      <td>{{ user.userId }}</td>
      <td>{{ user.username }}</td>
      <td>
        <span class="badge"
              [class.badge-success]="user.enabled"
              [class.badge-secondary]="!user.enabled">
          {{ user.enabled ? 'Enabled' : 'Disabled' }}
        </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<h3 *ngIf="users && !users.length">No users found</h3>
