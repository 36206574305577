var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AppBaseComponent, AppBaseComponentService } from '@shared';
var StyleGuideComponent = /** @class */ (function (_super) {
    __extends(StyleGuideComponent, _super);
    function StyleGuideComponent(baseServices) {
        return _super.call(this, baseServices) || this;
    }
    StyleGuideComponent.prototype.testModal = function () {
        var _this = this;
        this.base.dialogService.showConfirmation('Confirm', 'This a tall modal...<br/><br/><br/><br/><br/>...to test that chained modals aren\'t just obscuring each other', 'OK', function () {
            _this.dialogService.showInformation('Confirmed!', 'Thanks');
        });
    };
    return StyleGuideComponent;
}(AppBaseComponent));
export { StyleGuideComponent };
