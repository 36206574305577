import { Component, Input } from '@angular/core';

import { AppBaseComponent, AppBaseComponentService, IUser } from '@shared';

@Component({
  selector: 'wv-supporter-users-list',
  templateUrl: './supporter-users-list.component.html'
})
export class SupporterUserListComponent extends AppBaseComponent {
  @Input()
  public users: Array<IUser>;

  constructor(baseServices: AppBaseComponentService) {
    super(baseServices);
  }
}
