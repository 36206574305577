var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AppBaseComponent, AppBaseComponentService, SmilesService, SupporterService } from '../shared';
var SupporterDetailsComponent = /** @class */ (function (_super) {
    __extends(SupporterDetailsComponent, _super);
    function SupporterDetailsComponent(baseServices, smilesService, route, supporterService) {
        var _this = _super.call(this, baseServices) || this;
        _this.baseServices = baseServices;
        _this.smilesService = smilesService;
        _this.route = route;
        _this.supporterService = supporterService;
        _this.isLoadingOrders = false;
        _this.isLoadingUsers = false;
        var tablePageSizeItems = _this.base.appConfig.getConfig('tablePageSizeItems');
        _this.take = tablePageSizeItems[0];
        return _this;
    }
    SupporterDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.addSubscription(this.route.params.subscribe(function (params) {
            _this.supporterId = params.id;
            _this.getSupporterDetails();
        }));
        this.getSupporterDetails();
    };
    SupporterDetailsComponent.prototype.onPagingChange = function (paging) {
        if (paging) {
            this.take = paging.take;
            this.getOrders(paging.skip, paging.take);
        }
    };
    SupporterDetailsComponent.prototype.beforeChange = function ($event) {
        if ($event.panelId === 'panel-orders' && !this.pagingWrapper) {
            this.getOrders(0, this.take);
            return;
        }
        if ($event.panelId === 'panel-users' && !this.users) {
            this.getUsers();
        }
    };
    SupporterDetailsComponent.prototype.getSupporterDetails = function () {
        var _this = this;
        this.loadingState.setLoading();
        this.supporterService.getSupporter(this.supporterId)
            .pipe(finalize(function () {
            _this.loadingState.setLoaded();
        }))
            .subscribe(function (resp) {
            _this.supporterProfile = resp;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    SupporterDetailsComponent.prototype.getOrders = function (skip, take) {
        var _this = this;
        if (this.supporterId) {
            this.isLoadingOrders = true;
            this.smilesService.getSmilesOrdersBySearchTerm(this.supporterId, take, skip)
                .pipe(finalize(function () { return _this.isLoadingOrders = false; }))
                .subscribe(function (resp) {
                _this.pagingWrapper = resp;
            }, function (resp) {
                _this.apiErrorHandlerService.handleError(resp);
            });
            return;
        }
        this.dialogService.showInformation(undefined, 'Please provide a supporter ID');
    };
    SupporterDetailsComponent.prototype.getUsers = function () {
        var _this = this;
        this.isLoadingUsers = true;
        this.supporterService.getUsersBySupporter(this.supporterId)
            .pipe(finalize(function () { return _this.isLoadingUsers = false; }))
            .subscribe(function (users) {
            _this.users = users;
        }, function (resp) {
            _this.apiErrorHandlerService.handleError(resp);
        });
    };
    SupporterDetailsComponent.prototype.getPrimaryPhoneNumberString = function () {
        var phone = this.supporterProfile.primaryPhoneNumber;
        if (phone) {
            return phone.countryCode + "-" + phone.areaCode + "-" + phone.nationalNumber;
        }
        return '';
    };
    return SupporterDetailsComponent;
}(AppBaseComponent));
export { SupporterDetailsComponent };
