import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CanActivateAuthGuard } from '@auth';

import { ChosenPhotosDetailsComponent } from './chosen-photos-details/chosen-photos-details.component';
import { ChosenPhotosListComponent } from './chosen-photos-list/chosen-photos-list.component';

const routes: Routes = [
  { path: 'chosen-photos', component: ChosenPhotosListComponent, canActivate: [CanActivateAuthGuard] },
  { path: 'chosen-photos/:id/details', component: ChosenPhotosDetailsComponent, canActivate: [CanActivateAuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild(routes)
  ]
})
export class ChosenPhotosRoutingModule { }
