import { OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { LoadingStateManager } from './loader/loading-state-manager';
import { BaseComponentService } from './services/base-component.service';

export abstract class BaseComponent implements OnDestroy {
  public loadingState: LoadingStateManager;
  private readonly subscription: Subscription = new Subscription();

  constructor(
    public base: BaseComponentService
  ) {
    this.loadingState = new LoadingStateManager();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public addSubscription(sub: any): void {
    this.subscription.add(sub);
  }
}
