<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>

<ng-container *ngIf="!loadingState.isLoading()">
  <form class="supporter-search container" (ngSubmit)="onSubmit(supporterForm)" #supporterForm="ngForm" novalidate>
    <h1>Transactions</h1>
    <hr class="heading-hr" align="left" />
    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="transactionId">Transaction ID</label>
        <input type="text" class="form-control" id="transactionId" name="transactionId"
          placeholder="Enter a transaction ID" [(ngModel)]="model.transactionId" #transactionId="ngModel" />
      </div>

      <div class="col-md-6 mb-3">
        <label for="supporterId">Supporter ID</label>
        <input type="text" class="form-control" id="supporterId" name="supporterId" placeholder="Enter a supporter ID"
          [(ngModel)]="model.supporterId" #supporterId="ngModel" />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="status">Status</label>
        <select class="form-control" name="status" [(ngModel)]="model.status">
          <option [ngValue]="undefined">Any</option>
          <option *ngFor="let status of statuses" [value]="status">{{ status }}</option>
        </select>
      </div>

      <div class="col-md-4 mb-3">
        <label for="transactionType">Transaction Type</label>
        <select class="form-control" name="transactionType" [(ngModel)]="model.transactionType">
          <option [ngValue]="undefined">Any</option>
          <option *ngFor="let transactionType of transactionTypes" [value]="transactionType">{{ transactionType }}
          </option>
        </select>
      </div>

      <div class="col-md-4 mb-3">
        <label for="productType">Product Type</label>
        <select class="form-control" name="productType" [(ngModel)]="model.productType">
          <option [ngValue]="undefined">Any</option>
          <option *ngFor="let productType of productTypes" [value]="productType">{{ productType }}</option>
        </select>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12 mb-3">
        <div class="pull-right action-group">
          <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isRefreshing()">Search</button>
        </div>
      </div>
    </div>

  </form>

  <table class="table table-condensed table-striped" [class.table-loading]="loadingState.isRefreshing()">
    <thead>
      <tr>
        <th>ID</th>
        <th>Type</th>
        <th>Product</th>
        <th>Donation Code</th>
        <th>Recurring</th>
        <th>Amount</th>
        <th>Supporter ID</th>
        <th>Status</th>
        <th>Date</th>
      </tr>
    </thead>
    <tbody>
      <tr class="clickable" *ngFor="let transaction of transactions"
        [routerLink]="['/transactions', transaction.id, 'details']">
        <td>{{ transaction.id }}</td>
        <td>{{ transaction.transactionType }}</td>
        <td>{{ transaction.productType }}</td>
        <td>{{ transaction.donationCode }}</td>
        <td>{{ transaction.recurring ? 'Yes' : '' }}</td>
        <td>{{ transaction.amount }}</td>
        <td>{{ transaction.supporterId }}</td>
        <td>
          <wv-transaction-status-badge [status]="transaction.status"></wv-transaction-status-badge>
        </td>
        <td [title]="transaction.dateTimeStamp | date: 'dd MMM yyyy hh:mm:ss a'">
          {{ transaction.dateTimeStamp | date: 'dd MMM yyyy' }}</td>
      </tr>
    </tbody>
  </table>

  <nav class="pull-right inline-block" *ngIf="transactions">
    <ngb-pagination [(page)]="page" [maxSize]="10" [pageSize]="pageSize" [collectionSize]="total" (pageChange)="pageChange($event)">
    </ngb-pagination>
  </nav>
</ng-container>
