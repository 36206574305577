import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from '../../base.component';
import { BaseComponentService } from '../../services/base-component.service';
import { IModalContent } from '../modal-content';
import { DialogType } from './dialog-type';

@Component({
  templateUrl: 'dialog.component.html'
})
export class DialogComponent extends BaseComponent implements IModalContent {

  public modalContainerOptions: any;
  public close: () => any;

  public ok: Function;
  public cancel: Function;
  public type: DialogType = DialogType.Information;
  public title: string = undefined;
  public message: string = undefined;
  public okCaption: string = undefined;
  public showOk = false;
  public cancelCaption: string = undefined;
  public showCancel = false;
  public dialogLoading = false;

  constructor(baseServices: BaseComponentService) {
    super(baseServices);

    this.modalContainerOptions = {
      overlayClass: '',
      modalClass: 'modal-dialog mybnc-modal'
    };
  }

  public okClick(event: any): void {
    event.stopPropagation();

    let close = true;
    if (this.ok) {
      const result = this.ok();

      const obs = result as Observable<boolean>;

      if (obs === undefined) {
        close = result;
      } else {
        this.dialogLoading = true;

        obs.subscribe(b => {
          this.dialogLoading = false;
          if (b) {
            this.close();
          }
        });

        return;
      }
    }
    if (close) {
      this.close();
    }
  }

  public cancelClick(): void {
    if (this.cancel) {
      this.cancel();
    }
    this.close();
  }
}
