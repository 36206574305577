import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { LaddaModule } from 'angular2-ladda';

import { FieldHelperComponent } from './field-helper.component';
import { SafeHtmlPipe } from './safe-html.pipe';
import { BaseComponentService } from './services/base-component.service';

import { LoaderComponent } from './loader/loader.component';
import { DialogComponent } from './modal/dialog/dialog.component';
import { DialogService } from './modal/dialog/dialog.service';
import { ModalContainerComponent } from './modal/modal-container.component';
import { ModalPlaceholderComponent } from './modal/modal-placeholder.component';
import { ModalService } from './modal/modal.service';

@NgModule({
  imports: [
    BrowserModule,
    HttpClientModule,
    LaddaModule
  ],
  exports: [
    SafeHtmlPipe,
    FieldHelperComponent,
    ModalContainerComponent,
    ModalPlaceholderComponent,
    LoaderComponent
  ],
  declarations: [
    DialogComponent,
    SafeHtmlPipe,
    FieldHelperComponent,
    ModalContainerComponent,
    ModalPlaceholderComponent,
    LoaderComponent
  ],
  entryComponents: [
    DialogComponent,
    ModalContainerComponent
  ],
  providers: [
    BaseComponentService,
    ModalService,
    DialogService
  ]
})
export class CoreModule { }
