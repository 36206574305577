import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieOptions, CookieService } from 'ngx-cookie';
import { Observable, Subject } from 'rxjs';
import { AppConfig } from '../../core/app.config';

import { AuthState } from '../models/auth-state';

@Injectable()
export class AuthStateService {
  stateChanged$: Observable<boolean>;
  adminPortalCookiePrefix = 'admin_portal';

  private readonly tokenPrefix: string = undefined;
  private readonly baseAccessTokenName = 'access_token';
  private readonly baseRefreshTokenName = 'refresh_token';
  private readonly subject: Subject<boolean>;
  private readonly jwtHelper: JwtHelperService = undefined;

  constructor(
    private readonly config: AppConfig,
    private readonly cookieService: CookieService,
    private readonly router: Router
  ) {
    const envName = config.getConfig('cookiePrefix') as string;

    if (envName && envName.toLowerCase() !== 'prod') {
      this.tokenPrefix = `${envName.toLowerCase()}_`;
    }

    this.subject = new Subject<boolean>();
    this.stateChanged$ = this.subject.asObservable();
    this.jwtHelper = new JwtHelperService();
  }

  public get accessTokenName(): string {
    return this.tokenPrefix ? this.tokenPrefix + this.baseAccessTokenName : this.baseAccessTokenName;
  }

  public get refreshTokenName(): string {
    return this.tokenPrefix ? this.tokenPrefix + this.baseRefreshTokenName : this.baseRefreshTokenName;
  }

  public get current(): AuthState {
    return new AuthState(this.cookieService, this, this.router);
  }

  public setTokens(accessToken: string, refreshToken: string, expiresIn: number): void {
    const now = new Date();
    const accessExpiry = new Date(now.getTime() + (expiresIn * 1000));
    const domain = this.getDomain();

    const accessTokenOptions: CookieOptions = {
      domain,
      path: '/',
      expires: accessExpiry,
      secure: false // TO DO review this
    };

    const refreshExpiry = new Date(now);
    refreshExpiry.setDate(refreshExpiry.getDate() + 30);

    const refreshTokenOptions: CookieOptions = {
      domain,
      path: '/',
      expires: refreshExpiry,
      secure: false // TO DO review this
    };

    this.cookieService.put(this.accessTokenName, accessToken, accessTokenOptions);
    this.cookieService.put(this.refreshTokenName, refreshToken, refreshTokenOptions);

    this.subject.next(this.current.isAuthenticated);
  }

  public clearState(): void {
    const domain = this.getDomain();

    this.cookieService.remove(this.accessTokenName, { domain });
    this.cookieService.remove(this.refreshTokenName, { domain });

    this.subject.next(false);
  }

  private getDomain(): string {
    return this.config.getConfig('domain');
  }
}
