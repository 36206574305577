var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseComponent } from '@core';
var AppBaseComponent = /** @class */ (function (_super) {
    __extends(AppBaseComponent, _super);
    function AppBaseComponent(base) {
        var _this = _super.call(this, base) || this;
        _this.base = base;
        _this.isAuthenticated = false;
        _this.appConfig = base.appConfig;
        _this.authStateService = base.authStateService;
        _this.validationRules = base.validationRules;
        _this.isAuthenticated = base.authStateService.current.isAuthenticated;
        _this.modalService = base.modalService;
        _this.dialogService = base.dialogService;
        _this.apiErrorHandlerService = base.apiErrorHandlerService;
        _super.prototype.addSubscription.call(_this, _this.authStateService.stateChanged$
            .subscribe(function (authenticated) {
            _this.isAuthenticated = authenticated;
        }));
        return _this;
    }
    return AppBaseComponent;
}(BaseComponent));
export { AppBaseComponent };
