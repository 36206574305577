import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IUser } from '../models/user';
import { BaseService } from './base.service';

@Injectable()
export class UserService extends BaseService {
  public getUser(id: number): Observable<IUser> {
    return this.get<IUser>(`admin/users/${id}`);
  }

  public setSupporterId(id: number, primarySupporterId: number): Observable<void> {
    return this.put<void>(`admin/users/${id}/primarySupporterId`, { primarySupporterId });
  }

  public impersonateUser(supporterId: number): Observable<IUser> {
    return this.get<IUser>(`admin/users/auth0/impersonate/${supporterId}/start`);
  }
}
