import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseService } from '../base.service';

@Injectable()
export class ReportsService extends BaseService {

  public static getFileNameFromHttpResponse(httpResponse: HttpResponse<any>): string {
    const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');
    const result = contentDispositionHeader
      .split(';')[1]
      .trim()
      .split('=')[1];

    return result
      .replace(/"/g, '');
  }

  public children(poolCode: string): Observable<any> {
    const operation = {
      poolCode
    };

    return this.generate('admin/reports/children', operation);
  }

  public projects(projectId: string): Observable<any> {
    const operation = {
      projectId
    };

    return this.generate('admin/reports/projects', operation);
  }

  private generate(url: string, operation: any): Observable<any> {

    const options = this.authRequestOptions();
    options.responseType = 'blob';
    options.observe = 'response';

    return this.post<any>(url, operation, options)
      .pipe(
        map(resp => {
          const filename = ReportsService.getFileNameFromHttpResponse(resp);

          return { filename, body: resp.body };
        }));
  }
}
