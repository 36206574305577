import { Component, OnInit } from '@angular/core';

import { ISupporterStub, LastViewedSupportersService } from '@shared';

@Component({
  selector: 'wv-last-viewed-supporters',
  templateUrl: './last-viewed-supporters.component.html'
})
export class LastViewedSupportersComponent implements OnInit {
  public lastViewedSupporters: Array<ISupporterStub>;

  constructor(private readonly lastViewedSupportersService: LastViewedSupportersService) { }

  ngOnInit(): void {
    this.lastViewedSupportersService.data.subscribe(supporters => {
      this.lastViewedSupporters = supporters;
    });
  }
}
