import { AfterContentInit, Component, ElementRef, Input, OnInit, Renderer } from '@angular/core';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'wv-field-helper',
  template: '<div class="invalid-feedback" *ngIf="showValidity && !isValid">{{ helpMessage }}</div>'
})
export class FieldHelperComponent implements OnInit, AfterContentInit {
  public isValid = false;
  public showValidity = false;
  public helpMessage: string = undefined;

  @Input()
  public control: NgModel;

  @Input()
  public errorMessages: any;

  @Input()
  public showOnSubmit = true;

  private hasFocus = false;
  private hasValue = false;

  private groupElem: any = undefined;
  private inputElem: any = undefined;
  private errors: Array<any> = undefined;

  constructor(
    private readonly elementRef: ElementRef,
    private readonly renderer: Renderer
  ) { }

  public ngOnInit(): void {
    const element = this.elementRef.nativeElement;

    this.groupElem = element.previousElementSibling;
    this.inputElem = this.groupElem.querySelector('input,select,textarea');

    this.errors = Object.keys(this.errorMessages)
      .map(e =>
        ({
          key: e,
          message: this.errorMessages[e]
        }));

    if (!this.control) {

      return;
    }

    if (this.control.formDirective && this.control.formDirective.ngSubmit) {
      this.control.formDirective.ngSubmit.subscribe(() => {
        this.change();
      });
    }

    if (this.control.valueChanges) {
      this.control.valueChanges.subscribe(() => {
        this.change();
      });
    }

    this.renderer.listen(this.inputElem, 'focus', () => {
      this.focus();
    });

    this.renderer.listen(this.inputElem, 'blur', () => {
      this.blur();
    });
  }

  public ngAfterContentInit(): void {
    this.checkValue();

    this.setCssClasses();
  }

  private checkValidity(): void {
    this.isValid = this.control.valid;

    this.showValidity = this.showOnSubmit
      ? (this.control.touched
        || (this.control.formDirective !== undefined
          && this.control.formDirective.submitted))
      : this.control.dirty;

  }

  private checkValue(): void {
    const modelValue = this.control.model;
    const inputValue = this.inputElem.value;

    this.hasValue = (modelValue || inputValue);
  }

  private focus(): void {
    this.hasFocus = true;
    this.setCssClasses();
  }

  private blur(): void {
    this.hasFocus = false;
    this.setCssClasses();
  }

  private change(): void {
    this.checkValidity();
    this.helpMessage = this.getErrorMessage();
    this.checkValue();

    this.setCssClasses();
  }

  private getErrorMessage(): string {
    let errorMessage: string;

    this.errors.forEach((error, index) => {
      if (this.control.hasError(error.key) && !errorMessage) {
        errorMessage = error.message;
      }
    });

    return errorMessage;
  }

  private setCssClasses(): void {
    // set "is-invalid" css class on parent form-group element
    this.renderer.setElementClass(this.groupElem, 'is-invalid', !this.isValid && this.showValidity);

    // set "is-valid" css class on parent form-group element
    this.renderer.setElementClass(this.groupElem, 'is-valid', this.isValid && this.showValidity);

    // set "has-focus" css class on parent form-group element
    this.renderer.setElementClass(this.groupElem, 'has-focus', this.hasFocus);

    // set "has-value" css class on parent form-group element
    this.renderer.setElementClass(this.groupElem, 'has-value', this.hasValue || this.hasFocus);
  }
}
