<form *ngIf="supporterProfile" class="container">
  <h1>Supporter Details</h1>
  <hr align="left" />
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="supporterId">Supporter ID</label>
      <input type="text" class="form-control" id="supporterId" name="supporterId"
             [(ngModel)]="supporterId" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label for="dateOfBirth">Date of Birth</label>
      <input type="text" class="form-control" id="dateOfBirth" name="dateOfBirth"
             [(ngModel)]="supporterProfile.dateOfBirth" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="firstName">First Name</label>
      <input type="text" class="form-control" id="firstName" name="firstName"
             [(ngModel)]="supporterProfile.firstName" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label for="lastName">Last Name</label>
      <input type="text" class="form-control" id="lastName" name="lastName"
             [(ngModel)]="supporterProfile.lastName" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="emailAddress">Email</label>
      <input type="text" class="form-control" id="emailAddress" name="emailAddress"
             [(ngModel)]="supporterProfile.emailAddress" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label for="primaryPhoneNumber">Primary Phone Number</label>
      <input type="text" class="form-control" id="primaryPhoneNumber" name="primaryPhoneNumber"
             [value]="getPrimaryPhoneNumberString()" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label for="supporterSince">Supporter Since</label>
      <input type="text" class="form-control" id="supporterSince" name="supporterSince"
             [value]="supporterProfile.supporterSince | date:'MMMM yyyy'" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label for="supporterType">Supporter Type</label>
      <input type="text" class="form-control" id="supporterType" name="supporterType"
             [(ngModel)]="supporterProfile.supporterType" disabled />
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <ngb-accordion #acc="ngbAccordion" (panelChange)="beforeChange($event)">
        <ngb-panel title="Orders" id="panel-orders">
          <ng-template ngbPanelContent>
            <wv-loader *ngIf="isLoadingOrders"></wv-loader>
            <wv-smiles-order-list *ngIf="pagingWrapper"
                                  [pagingWrapper]="pagingWrapper"
                                  (paging)="onPagingChange($event)">
            </wv-smiles-order-list>
          </ng-template>
        </ngb-panel>
        <ngb-panel title="Users" id="panel-users">
          <ng-template ngbPanelContent>
            <wv-loader *ngIf="isLoadingUsers"></wv-loader>
            <wv-supporter-users-list [users]="users"></wv-supporter-users-list>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
    </div>
  </div>
</form>

<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
