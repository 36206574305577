import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'wv-transaction-status-badge',
  templateUrl: './transaction-status-badge.component.html'
})
export class TransactionStatusBadgeComponent implements OnChanges {

  @Input()
  public status: string;

  public badgeClass: string;

  public ngOnChanges(): void {
    this.badgeClass = `badge-${this.getBadgeClass()}`;
  }

  private getBadgeClass(): string {
    switch (this.status) {
      case 'Complete':
        return 'success';

      case 'Pending':
      case 'PaymentTransactionCreated':
        return 'info';

      case 'PaymentFailed':
        return 'warning';

      default:
        return 'danger';
    }
  }

}
