var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var TransactionService = /** @class */ (function (_super) {
    __extends(TransactionService, _super);
    function TransactionService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TransactionService.prototype.find = function (query) {
        return this.get("admin/transactions?" + BaseService.objectToQueryString(query));
    };
    TransactionService.prototype.getById = function (id) {
        return this.get("admin/transactions/" + id);
    };
    TransactionService.prototype.getCommitmentsByTransactionId = function (id) {
        return this.get("admin/transactions/" + id + "/commitments");
    };
    TransactionService.prototype.getTransactionStatuses = function () {
        return this.get('admin/transaction-statuses');
    };
    TransactionService.prototype.getTransactionTypes = function () {
        return this.get('admin/transaction-types');
    };
    TransactionService.prototype.getProductTypes = function () {
        return this.get('admin/product-types');
    };
    return TransactionService;
}(BaseService));
export { TransactionService };
