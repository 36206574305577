// immutable sign in state
var SignInState = /** @class */ (function () {
    function SignInState(browserStorageService) {
        this.browserStorageService = browserStorageService;
    }
    Object.defineProperty(SignInState.prototype, "returnUrl", {
        get: function () {
            return this.getItem('returnUrl');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignInState.prototype, "emailAddress", {
        get: function () {
            return this.getItem('emailAddress');
        },
        enumerable: true,
        configurable: true
    });
    SignInState.prototype.getItem = function (key) {
        return this.browserStorageService.getItem("signInState." + key);
    };
    return SignInState;
}());
export { SignInState };
