import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthState } from './models/auth-state';
import { AuthStateService } from './services/auth-state.service';
import { AuthService } from './services/auth.service';

@Injectable()
export class CanActivateAuthGuard implements CanActivate {

  private readonly authState: AuthState = undefined;

  constructor(
    private readonly authService: AuthService,
    private readonly authStateService: AuthStateService,
    private readonly router: Router
  ) {
    this.authState = authStateService.current;
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

    return this.authService.ensureAuthentication()
      .pipe<boolean | UrlTree, boolean | UrlTree>(
        map(() => true),
        catchError(() => of(this.router.createUrlTree(['/sign-in'])))
      );
  }
}
