import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class SignInActionService {

    constructor(
        private readonly router: Router
    ) { }

    public async redirect(action: string): Promise<boolean> {
        switch (action) {
            case 'supporters':
                return this.router.navigate(['/supporters']);
            default:
                return this.router.navigate(['/']);
      }
    }
}
