import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize, switchMap } from 'rxjs/operators';

import { IModalContent } from '@core';
import { AppBaseComponent, AppBaseComponentService, ISupporterStub, SupporterService, TeamsService } from '@shared';

@Component({
  selector: 'wv-team-owner-modal',
  templateUrl: './team-owner-modal.component.html'
})
export class FundraisingTeamOwnerModalComponent extends AppBaseComponent implements OnInit, IModalContent {
  public supporters: Array<ISupporterStub> = [];
  public teamId: string;
  public supporterSearchTerm: string;
  public selectedSupporter: ISupporterStub;
  public supporterSearching = true;
  public searchTerm$ = new Subject<string>();
  public searchStatus: string;
  public model: any = {};
  public modalContainerOptions: any;
  public close: () => any;
  public isLoading = false;
  private readonly minimumSearchCharacters = 3;

  constructor(
    baseServices: AppBaseComponentService,
    private readonly teamsService: TeamsService,
    private readonly supporterService: SupporterService) {
    super(baseServices);

    this.modalContainerOptions = {
      overlayClass: '',
      modalClass: 'modal-dialog'
    };
  }

  ngOnInit(): void {
    this.searchTerm$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(term => {
        this.searchStatus = 'Searching...';
        if (term.length < this.minimumSearchCharacters) {
          this.searchStatus = `Please enter ${this.minimumSearchCharacters} or more characters to search`;

          return of(false) as any;
        }

        return this.supporterService.getSupportersBySearchTerm(term) as any;
      }))
      .subscribe(result => {
        if (result === false) {
          this.supporters = [];

          return;
        }
        const supporters = result as Array<ISupporterStub>;

        this.searchStatus = supporters.length === 0
          ? 'There is no matching supporter.'
          : 'Were you looking for:';

        this.supporters = supporters;
      });
  }

  public changeSupporterSearchTerm(searchTerm: string): void {
    this.supporterSearchTerm = searchTerm;
    this.searchTerm$.next(searchTerm);
  }

  public selectMatchedSupporter(supporterMatch: ISupporterStub): void {
    this.selectedSupporter = supporterMatch;
    this.supporterSearching = false;
  }

  public getSupporterFullName(supporter: ISupporterStub): string {
    return `${supporter.firstName} ${supporter.lastName}`;
  }

  public changeOwner(): void {
    this.isLoading = true;
    this.teamsService.changeOwner(this.teamId, this.selectedSupporter.id)
      .pipe(finalize(
        () => this.isLoading = false))
      .subscribe(
        async () => {
          this.close();

          return this.base.router.navigate(['fundraising/teams/', this.teamId]);
        }, resp => {
          this.apiErrorHandlerService.handleError(resp);
        });
  }

  public cancel(): void {
    this.supporterSearching = true;
  }
}
