var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var SupporterService = /** @class */ (function (_super) {
    __extends(SupporterService, _super);
    function SupporterService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SupporterService.prototype.getSupporter = function (id) {
        return this.get("admin/supporters/" + id);
    };
    SupporterService.prototype.getUsersBySupporter = function (supporterId) {
        return this.get("admin/supporters/" + supporterId + "/users");
    };
    SupporterService.prototype.getSupportersBySearchTerm = function (searchTerm) {
        return this.get("admin/supporters?searchTerm=" + searchTerm + "&limit=10");
    };
    return SupporterService;
}(BaseService));
export { SupporterService };
