<form class="supporter-search container" (ngSubmit)="onSubmit(reportForm)" #reportForm="ngForm" novalidate>
  <h1>Reports - Projects</h1>
  <hr class="heading-hr" align="left" />
  <div class="form-row">
    <div class="col-md-8 mb-3">
      <label for="projectId">Project ID (Optional)</label>
      <input type="text" class="form-control"
             id="projectId"
             name="projectId"
             placeholder="Project ID"
             [(ngModel)]="model.projectId"
             #poolCode="ngModel" />
    </div>
    <div class="col-md-8 mb-3">
      <div class="pull-right action-group">
        <button class="btn btn-p pull-right" type="submit" [ladda]="loadingState.isLoading()">Generate</button>
      </div>
    </div>
  </div>

</form>
