import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { saveAs } from 'file-saver';

import { AppBaseComponent, AppBaseComponentService, ISupporterChildMessage, SupporterChildMessageService } from '@shared';

@Component({
  selector: 'wv-supporter-child-messages-list',
  templateUrl: './supporter-child-messages-list.component.html'
})
export class SupporterChildMessagesListComponent extends AppBaseComponent implements OnInit {

  public model: any = {};
  public total: number;
  public readonly pageSize = 10;
  public page = 1;
  public messages: Array<ISupporterChildMessage>;

  constructor(
    base: AppBaseComponentService,
    private readonly route: ActivatedRoute,
    private readonly supporterChildMessageService: SupporterChildMessageService
  ) {
    super(base);
   }

  public ngOnInit(): void {
    this.loadingState.setLoading();

    const params = this.route.snapshot.queryParams;
    this.page = params.page ? Number.parseInt(params.page, 10) : 1;

    this.loadPage(this.page, this.pageSize);
  }

  public pageChange(pageNumber: number): void {
    this.updateFilters();
    this.loadPage(pageNumber, this.pageSize);
  }

  public onSubmit(_form: NgForm): void {
    this.page = 1;
    this.updateFilters();
    this.loadPage(this.page, this.pageSize);
  }

  public export(): void {
    if (this.loadingState.isLoaded()) {
      this.loadingState.setRefreshing();
    }

    this.supporterChildMessageService.export(
      this.model.childId,
      this.model.supporterId
    )
      .subscribe(resp => {
        this.loadingState.setLoaded();
        saveAs(resp.body, resp.filename);
      }, resp => {
        this.base.apiErrorHandlerService.handleError(resp);
      });
  }

  private updateFilters(): void {
    this.base.router.navigate([], {
      queryParams: {
        page: this.page
      }
    })
    .catch();
  }

  private loadPage(page: number, pageSize: number): void {

    if (this.loadingState.isLoaded()) {
      this.loadingState.setRefreshing();
    }

    this.supporterChildMessageService.find(
      (page - 1) * this.pageSize,
      pageSize,
      this.model.childId,
      this.model.supporterId
    )
      .subscribe(results => {
        this.loadingState.setLoaded();
        this.total = results.total;
        this.messages = results.items;
      });
  }

}
