import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { LaddaModule } from 'angular2-ladda';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';

import { TransactionsRoutingModule } from './transactions-routing.module';

import { TransactionDetailsComponent } from './transaction-details/transaction-details.component';
import { TransactionListComponent } from './transaction-list/transaction-list.component';
import { TransactionStatusBadgeComponent } from './transaction-status-badge/transaction-status-badge.component';

@NgModule({
  declarations: [TransactionListComponent, TransactionDetailsComponent, TransactionStatusBadgeComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CoreModule,
    SharedModule,
    LaddaModule,
    NgbPaginationModule,
    TransactionsRoutingModule
  ]
})
export class TransactionsModule { }
