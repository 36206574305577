<header class="dash-nav">
  <div class="container-fluid">
    <div class="brand-wrapper">
      <a class="wv-brand" href="https://worldvision.org.nz" id="wv-logo"></a>
    </div>
  </div>
  <div *ngIf="isAuthenticated" class="sign-out pull-right">
    <button (click)="signOut()" class="btn-sign-out pull-right">Sign Out</button>
  </div>
</header>
<div class="wrapper">
  <div class="sidenav" *ngIf="isAuthenticated">
    <label class="nav-header">Data</label>
    <a [routerLinkActive]="['active']" routerLink="/supporters">Supporters</a>
    <a [routerLinkActive]="['active']" routerLink="/supporter-child-messages">Supporter Child Messages</a>
    <a [routerLinkActive]="['active']" routerLink="/smiles-orders">Smiles Orders</a>
    <a [routerLinkActive]="['active']" routerLink="/transactions">Transactions</a>
    <a [routerLinkActive]="['active']" routerLink="/chosen-photos">Chosen Photos</a>
    <a [routerLinkActive]="['active']" routerLink="fundraising/teams">Fundraising Teams</a>
    <a [routerLinkActive]="['active']" routerLink="fundraising/profiles">Fundraising Profiles</a>
    <hr />
    <label class="nav-header">Reports</label>
    <a [routerLinkActive]="['active']" routerLink="reports/child-report">Children</a>
    <a [routerLinkActive]="['active']" routerLink="reports/project-report">Projects</a>
    <hr />
    <wv-last-viewed-supporters class="inline-block options-dropdown"></wv-last-viewed-supporters>
  </div>
  <div class="main" [ngClass]="{'side-nav-gutter': isAuthenticated}">
    <router-outlet></router-outlet>
    <wv-modal-placeholder></wv-modal-placeholder>
  </div>
</div>
