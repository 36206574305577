import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { NgbAccordionModule, NgbDropdownModule, NgbTabsetModule } from '@ng-bootstrap/ng-bootstrap';

import { CoreModule } from '@core';
import { SharedModule } from '@shared';
import { PreviewRoutingModule } from './preview-routing.module';

import { EmailPreviewSideNavComponent } from './email-preview-sidenav.component';
import { EmailPreviewComponent } from './email-preview.component';
import { EmailPreviewService } from './services/email-preview-service';

@NgModule({
  declarations: [
    EmailPreviewComponent,
    EmailPreviewSideNavComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbTabsetModule,

    CoreModule,
    SharedModule,
    PreviewRoutingModule
  ],
  entryComponents: [
    EmailPreviewSideNavComponent
  ],
  providers: [
    EmailPreviewService
  ]
})
export class PreviewModule { }
