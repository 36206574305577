var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingStateManager } from '@core';
import { AppBaseComponent, AppBaseComponentService, TransactionService } from '@shared';
import { forkJoin } from 'rxjs';
var TransactionListComponent = /** @class */ (function (_super) {
    __extends(TransactionListComponent, _super);
    function TransactionListComponent(base, route, transactionService) {
        var _this = _super.call(this, base) || this;
        _this.route = route;
        _this.transactionService = transactionService;
        _this.model = {};
        _this.pageSize = 20;
        _this.page = 1;
        _this.searchState = new LoadingStateManager();
        return _this;
    }
    TransactionListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.loadingState.setLoading();
        var params = this.route.snapshot.queryParams;
        this.page = params.page ? Number.parseInt(params.page, 10) : 1;
        this.model.status = params.status;
        this.model.transactionType = params.transactionType;
        this.model.productType = params.productType;
        this.model.transactionId = params.transactionId;
        this.model.supporterId = params.supporterId;
        forkJoin(this.transactionService.getTransactionStatuses(), this.transactionService.getTransactionTypes(), this.transactionService.getProductTypes())
            .subscribe(function (_a) {
            var statuses = _a[0], transactionTypes = _a[1], productTypes = _a[2];
            _this.statuses = statuses;
            _this.transactionTypes = transactionTypes;
            _this.productTypes = productTypes;
        });
        this.loadPage(this.page, this.pageSize);
    };
    TransactionListComponent.prototype.pageChange = function (pageNumber) {
        this.updateFilters();
        this.loadPage(pageNumber, this.pageSize);
    };
    TransactionListComponent.prototype.onSubmit = function (_form) {
        this.page = 1;
        this.updateFilters();
        this.loadPage(this.page, this.pageSize);
    };
    TransactionListComponent.prototype.updateFilters = function () {
        this.base.router.navigate([], {
            queryParams: {
                page: this.page,
                status: this.model.status,
                transactionType: this.model.transactionType,
                productType: this.model.productType,
                transactionId: this.model.transactionId,
                supporterId: this.model.supporterId
            }
        })
            .catch();
    };
    TransactionListComponent.prototype.loadPage = function (page, pageSize) {
        var _this = this;
        if (this.loadingState.isLoaded()) {
            this.loadingState.setRefreshing();
        }
        this.transactionService.find({
            skip: (page - 1) * this.pageSize,
            take: pageSize,
            status: this.model.status,
            transactionType: this.model.transactionType,
            productType: this.model.productType,
            transactionId: this.model.transactionId,
            supporterId: this.model.supporterId
        })
            .subscribe(function (transactions) {
            _this.loadingState.setLoaded();
            _this.total = transactions.total;
            _this.transactions = transactions.items;
        });
    };
    return TransactionListComponent;
}(AppBaseComponent));
export { TransactionListComponent };
