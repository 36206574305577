import { Component } from '@angular/core';

import { AppBaseComponent, AppBaseComponentService } from '@shared';

@Component({
  selector: 'wv-style-guide',
  templateUrl: './style-guide.component.html'
})
export class StyleGuideComponent extends AppBaseComponent {

  constructor(
    baseServices: AppBaseComponentService) {
    super(baseServices);
  }

  public testModal(): void {
    this.base.dialogService.showConfirmation('Confirm',
      'This a tall modal...<br/><br/><br/><br/><br/>...to test that chained modals aren\'t just obscuring each other',
      'OK',
      () => {
        this.dialogService.showInformation('Confirmed!', 'Thanks');
      });
  }
}
