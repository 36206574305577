<form *ngIf="message" class="container">
  <h1>Supporter Child Message Details</h1>
  <hr align="left" />

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>ID</label>
      <input type="text" class="form-control" [value]="message.id" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Supporter ID</label>
      <div class="input-group">
        <input type="text" class="form-control" [value]="message.supporterId" disabled />
        <div class="input-group-append">
          <button type="button" class="btn btn-link" [routerLink]="['/supporters', message.supporterId]">View</button>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Child ID</label>
      <input type="text" class="form-control" [value]="message.childId" disabled />
    </div>
    <div class="col-md-4 mb-3">
      <label>Date</label>
      <input type="text" class="form-control" [value]="message.createdDate | date:'dd MMMM yyyy HH:mm:ss'"
        disabled />
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-8 mb-3">
      <label>Message</label>
      <textarea class="form-control" [value]="message.message" disabled rows="10"></textarea>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-4 mb-3">
      <label>Image</label>
      <input type="text" class="form-control" [value]="message.imageFilename" disabled />
    </div>
  </div>
</form>

<wv-loader *ngIf="loadingState.isLoading()"></wv-loader>
