var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BaseService } from './base.service';
var UserService = /** @class */ (function (_super) {
    __extends(UserService, _super);
    function UserService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserService.prototype.getUser = function (id) {
        return this.get("admin/users/" + id);
    };
    UserService.prototype.setSupporterId = function (id, primarySupporterId) {
        return this.put("admin/users/" + id + "/primarySupporterId", { primarySupporterId: primarySupporterId });
    };
    UserService.prototype.impersonateUser = function (supporterId) {
        return this.get("admin/users/auth0/impersonate/" + supporterId + "/start");
    };
    return UserService;
}(BaseService));
export { UserService };
